import React, { useEffect } from 'react'
import { get } from 'lodash'

import { GenericForm, GenericStep } from '../../form'
import Intro from 'components/pages/intro'
import { Auth } from 'types/user'
import { getProgress, submit } from 'api/form'
import AbilityForm from './forms/ability'
import AGGRESSIVENESS_FORM from './forms/aggressiveness'
import SEXUALITY_BEHAVIOR_FORM from './forms/sexualBehavior'
import FOOD_FORM from './forms/food'
import RUNAWAY_FORM from './forms/runaway'
import ADDICTION_FORM from './forms/addiction'
import TEducative from 'types/educative'
import SituationForm from './forms/situation'
import Conclusion from '../conclusion'
import { useFormContext } from 'react-hook-form'

export default function Educative({ refreshProgress }: { refreshProgress: () => void }) {
  return (
    <GenericForm<TEducative>
      key="educative-form"
      title="Renseignements éducatifs"
      path="educative"
      resourceLoader={(auth: Partial<Auth>) =>
        getProgress(get(auth, 'selectedId', '0'), 'educative')
      }
      globalStep={3}
      afterSave={refreshProgress}
      steps={(auth: Partial<Auth>) => [
        {
          title: 'Introduction',
          Component: ({ onNext }) => (
            <Intro
              onNext={onNext}
              sections={[
                {
                  title: 'Situation actuelle',
                  text: 'Nous souhaitons en savoir plus sur la situation actuelle et passée, notamment les établissements fréquentés.',
                },
                {
                  title: 'Évaluation des capacités',
                  text: "L'évaluation des capacités se présente sous forme de questions à choix unique décomposées dans différentes catégories comme l'autonomie.",
                },
                {
                  title: 'Comportement',
                  text: "Dans cette section, vous allez renseigner les informations sur le comportement quotidien comme l'agressivité, la sexualité...",
                },
              ]}
            />
          ),
        },
        {
          title: 'Situation actuelle',
          Component: ({ resource }) => <SituationForm resource={resource} />,
          save: (data: { situation: TEducative['situation'] }) => {
            return submit<TEducative['situation'], { situation: TEducative['situation'] }>(
              get(auth, 'selectedId', '0'),
              'educative',
              'situation',
              data,
              true,
            )
          },
        },
        {
          title: 'Évaluation des capacités',
          Component: ({ resource }) => <AbilityForm resource={resource} />,
          save: (data: any) => {
            return submit<TEducative['ability'], { ability: TEducative['ability'] }>(
              get(auth, 'selectedId', '0'),
              'educative',
              'ability',
              data,
            )
          },
        },
        {
          title: 'Évaluation des capacités',
          Component: ({ resource }) => {
            const { setValue, watch } = useFormContext()
            const isAggressive = watch('aggressiveness.isAgressive')

            useEffect(() => {
              if (isAggressive === 'false') {
                setValue('aggressiveness.oneself', '0')
                setValue('aggressiveness.hetero', '0')
                setValue('aggressiveness.verbal', '0')
                setValue('aggressiveness.environmental', '0')
              }
            }, [isAggressive])

            return (
              <GenericStep<TEducative>
                subTitle="Aggressivité"
                resource={resource}
                subForm="aggressiveness"
                fields={AGGRESSIVENESS_FORM}
              />
            )
          },
          save: (data: any) => {
            return submit<
              TEducative['aggressiveness'],
              { aggressiveness: TEducative['aggressiveness'] }
            >(get(auth, 'selectedId', '0'), 'educative', 'aggressiveness', data)
          },
        },
        {
          title: 'Évaluation des capacités',
          Component: ({ resource }) => (
            <GenericStep<TEducative>
              resource={resource}
              subTitle="Sexualité"
              subForm="sexualityBehavior"
              fields={SEXUALITY_BEHAVIOR_FORM}
            />
          ),
          save: (data: any) => {
            return submit<
              TEducative['sexualityBehavior'],
              { sexualityBehavior: TEducative['sexualityBehavior'] }
            >(get(auth, 'selectedId', '0'), 'educative', 'sexualityBehavior', data)
          },
        },
        {
          title: 'Évaluation des capacités',
          Component: ({ resource }) => (
            <GenericStep<TEducative>
              subTitle="Conduites alimentaires"
              resource={resource}
              subForm="foodLine"
              fields={FOOD_FORM}
            />
          ),
          save: (data: any) => {
            return submit<TEducative['foodLine'], { sexualityBehavior: TEducative['foodLine'] }>(
              get(auth, 'selectedId', '0'),
              'educative',
              'foodLine',
              data,
            )
          },
        },
        {
          title: 'Évaluation des capacités',
          Component: ({ resource }) => (
            <GenericStep<TEducative>
              resource={resource}
              subForm="runaway"
              subTitle="Fugues"
              fields={RUNAWAY_FORM}
            />
          ),
          save: (data: any) => {
            return submit<TEducative['runaway'], { runaway: TEducative['runaway'] }>(
              get(auth, 'selectedId', '0'),
              'educative',
              'runaway',
              data,
            )
          },
        },
        {
          title: 'Évaluation des capacités',
          Component: ({ resource }) => (
            <GenericStep<TEducative>
              resource={resource}
              subForm="addiction"
              subTitle="Conduites addictives"
              fields={ADDICTION_FORM}
            />
          ),
          save: (data: any) => {
            return submit<TEducative['addiction'], { addiction: TEducative['addiction'] }>(
              get(auth, 'selectedId', '0'),
              'educative',
              'addiction',
              data,
            )
          },
        },
        {
          title: 'Complet',
          Component: ({ resource }) => (
            <Conclusion
              title="renseignements éducatifs"
              text="Avant de demander la validation du support, vous allez pouvoir vérifier les données
              que vous avez saisies."
              nextStepCta="Demandez la validation du support"
              nextStep="/support-validation/etape/1"
              firstStep="/educative/etape/2"
              isCompleted={['validating', 'completed'].includes(resource.statusProgress)}
            />
          ),
        },
      ]}
    />
  )
}
