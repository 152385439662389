import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'

const Loader = styled.div<{ size?: number }>`
  border: ${props => (props.size ? ~~((props.size * 8) / 30) : 8)}px solid
    ${props => props.theme.greyLight3};
  border-top: ${props => (props.size ? ~~((props.size * 8) / 30) : 8)}px solid
    ${props => props.theme.black};
  border-radius: 50%;
  width: ${props => props.size || 30}px;
  height: ${props => props.size || 30}px;
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

// eslint-disable-next-line react/display-name
export default function ({ size }: { size?: number }) {
  const [hidden, setHidden] = useState(true)
  const timer = useRef<NodeJS.Timeout>()

  useEffect(() => {
    timer.current = setTimeout(() => setHidden(false), 400)

    return () => {
      clearInterval(timer.current)
    }
  })

  return hidden ? null : <Loader size={size} />
}
