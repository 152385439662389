import React, { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import Modal from 'ui/modal'
import Box from 'ui/box'
import Button from 'ui/button'
import Icon from 'ui/icon'
import Text from 'ui/typography/text'
import Loader from 'ui/loader'
import { FormBody } from 'components/pages/form'

import { useToasts } from 'hooks/useToaster'
import useResource from 'hooks/useResource'
import { institutionEngage } from 'api/admission'
import { getKitLists } from 'api/kit'

export default function InstitutionChooseEngagement({
  data,
  opened,
  onClose,
  onSuccess,
  selectedInstitutionId,
}: {
  data: {
    candidacyId: string
    patientName: string
    kitListId: string
  }
  opened: boolean
  onClose: () => void
  onSuccess: () => void
  selectedInstitutionId: string | undefined
}) {
  const { addToast, addGenericErrorToast } = useToasts()
  const methods = useForm<{
    data: { engageDateProposition: string; institutionKitListId: string }
  }>()
  const [{ resource }] = useResource<{ label: string; value: string }[], {}>(
    undefined,
    () =>
      getKitLists(selectedInstitutionId).then(listOfKits =>
        listOfKits.map(kit => ({ label: kit.name, value: kit.id })),
      ),
    [],
  )
  const [state, setState] = useState<{
    loading: boolean
    showSuccess: boolean
    engageDateProposition?: string
  }>({ loading: false, showSuccess: false })

  const onSubmit = ({
    data: { institutionKitListId },
  }: {
    data: { institutionKitListId: string }
  }) => {
    setState({ ...state, loading: true })

    institutionEngage(data.candidacyId, state.engageDateProposition as string, institutionKitListId)
      .then(() => setState({ ...state, loading: false }))
      .then(() =>
        addToast({
          type: 'success',
          title: `Accueil programmé le ${new Date(
            state.engageDateProposition as string,
          ).toLocaleDateString()}`,
          message: 'L’accord de prise en charge vient d’être envoyé automatiquement à l’aidant.',
        }),
      )
      .then(onSuccess)
      .catch(() => {
        addGenericErrorToast()
        setState({ ...state, loading: false })
      })
  }

  return (
    <Modal
      onClose={onClose}
      opened={opened}
      title={`Programmation de l’accueil de ${data.patientName}`}
    >
      <FormProvider {...methods}>
        {state.showSuccess ? (
          <Box fd="c" as="form" autoComplete="off" onSubmit={methods.handleSubmit(onSubmit)}>
            <Box bg="green" padding="32px" ai="c" br={4} style={{ marginBottom: '16px' }}>
              <Icon
                name="check"
                size={22}
                color="white"
                bg="greenDark"
                br={50}
                cp="10px"
                containerStyle={{ marginRight: '32px' }}
              />
              <Box fd="c">
                <Text fs={18} color="white" bold spacingBottom="8px">
                  {`Date d’entrée prévue le ${new Date(
                    state.engageDateProposition as string,
                  ).toLocaleDateString()}`}
                </Text>
                <Text fs={16} color="white" opacity={0.6}>
                  {`Avec l’usager ${data.patientName}`}
                </Text>
              </Box>
            </Box>

            <Box fd="c">
              <FormBody<{ data: { institutionKitListId: string } }>
                fields={[
                  [
                    {
                      name: 'institutionKitListId',
                      type: 'select',
                      label: 'Trousseau défini précedemment',
                      placeholder: 'Choisissez un trouseau',
                      options: resource || [],
                      required: true,
                    },
                    null,
                  ],
                ]}
                subForm="data"
                hasId={false}
              />
              <Text fs={16} color="black" italic style={{ marginTop: '16px' }}>
                Vous pouvez sélectionner un autre trousseau que celui que vous aviez défini lorsque
                vous avez accepté le dossier.
              </Text>
            </Box>

            <Box jc="fe" padding="32px 0 0 0">
              <Button
                variant="outline"
                onClick={() => setState({ ...state, showSuccess: false })}
                style={{ marginRight: '16px' }}
              >
                Retour
              </Button>
              <Button type="submit">
                {state.loading ? (
                  <Loader size={12} />
                ) : (
                  <>
                    Continuer
                    <Icon
                      name="arrow"
                      color="black"
                      size={20}
                      containerStyle={{ marginLeft: '8px' }}
                    />
                  </>
                )}
              </Button>
            </Box>
          </Box>
        ) : (
          <Box
            fd="c"
            as="form"
            autoComplete="off"
            onSubmit={methods.handleSubmit(({ data: { engageDateProposition } }) => {
              setState({ ...state, engageDateProposition, showSuccess: true })
              methods.setValue('data.institutionKitListId', data.kitListId)
            })}
          >
            <Box br={4} bg="greyLightN" padding="24px" ai="c" style={{ marginBottom: '32px' }}>
              <Icon
                name="message"
                color="green"
                size={22}
                containerStyle={{ marginRight: '24px' }}
              />

              <Text fs={16} color="black">
                {`Précisez ci-dessous la date à laquelle vous pouvez accueillir l’usager ${data.patientName}. L’usager peut encore ne pas sélectionner votre établissement.`}
              </Text>
            </Box>

            <Box fd="c">
              <FormBody<{ data: { engageDateProposition: string } }>
                fields={[
                  [
                    {
                      name: 'engageDateProposition',
                      type: 'date',
                      label: 'Date proposée',
                      placeholder: 'JJ/MM/AAAA',
                      required: true,
                    },
                    null,
                  ],
                ]}
                subForm="data"
                hasId={false}
              />
              <Text fs={16} color="black" italic style={{ marginTop: '16px' }}>
                En fournissant cette date vous vous engagez à accepter l’usager dans votre
                établissement. Un non respect pourra entraîner une suppression de la plateforme.
              </Text>
            </Box>

            <Box jc="fe" padding="32px 0 0 0">
              <Button variant="outline" onClick={onClose} style={{ marginRight: '16px' }}>
                Retour
              </Button>
              <Button type="submit">
                {state.loading ? (
                  <Loader size={12} />
                ) : (
                  <>
                    Choisir cette date
                    <Icon
                      name="arrow"
                      color="black"
                      size={20}
                      containerStyle={{ marginLeft: '8px' }}
                    />
                  </>
                )}
              </Button>
            </Box>
          </Box>
        )}
      </FormProvider>
    </Modal>
  )
}
