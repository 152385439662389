import React from 'react'
import { get } from 'lodash'

import { GenericForm, GenericStep } from '../../form'
import Intro from 'components/pages/intro'

import { Auth } from 'types/user'
import TAdministrative, {
  Concerned as TConcerned,
  LegalGuardian as TLegalGuardian,
  MedicalTeam as TMedicalTeam,
  Family as TFamily,
} from 'types/administrative'

import { getProgress, submit } from 'api/form'

import CONCERNED_FORM from './forms/concerned'
import LEGAL_GUARDIAN_FORM from './forms/legalGuardian'
import MEDICAL_TEAM_FORM from './forms/medicalTeam'
import FormFamily from './forms/family'
import Conclusion from '../conclusion'

export default function Administrative({
  refreshUser,
  refreshProgress,
}: {
  refreshUser: () => void
  refreshProgress: () => void
}) {
  return (
    <GenericForm<TAdministrative>
      key="administrative-form"
      title="Administratifs et familiaux"
      path="administrative"
      resourceLoader={(auth: Partial<Auth>) =>
        getProgress(get(auth, 'selectedId', '0'), 'administrative')
      }
      globalStep={1}
      afterSave={refreshProgress}
      steps={(auth: Partial<Auth>) => [
        {
          title: 'Introduction',
          Component: ({ onNext }) => (
            <Intro
              onNext={onNext}
              sections={[
                {
                  title: 'Personne concernée par la demande',
                  text: 'Vous allez renseigner des informations comme le prénom, le nom, la date de naisssance...',
                },
                {
                  title: 'Responsable légal',
                  text: 'Vous allez renseigner des informations comme le prénom, le nom, la date de naisssance...',
                },
                {
                  title: 'Famille',
                  text: 'Vous allez renseigner les informations sur la famille de la personne concernée (mère, père, fraterie, enfant(s)).',
                },
                {
                  title: 'Équipe médicale',
                  text: 'Vous allez renseigner les informations sur le médecin de la personne concernée.',
                },
              ]}
            />
          ),
        },
        {
          title: 'Personne concernée',
          Component: ({ resource }) => (
            <GenericStep<TAdministrative>
              resource={resource}
              subForm="concerned"
              fields={CONCERNED_FORM}
            />
          ),
          save: (data: { concerned: TConcerned }) => {
            return submit<TConcerned, { concerned: TConcerned }>(
              get(auth, 'selectedId', '0'),
              'administrative',
              'concerned',
              data,
            ).then(refreshUser)
          },
        },
        {
          title: 'Responsable légal',
          Component: ({ resource }) => (
            <GenericStep<TAdministrative>
              resource={resource}
              subForm="legalGuardian"
              fields={LEGAL_GUARDIAN_FORM}
            />
          ),
          save: (data: { legalGuardian: TLegalGuardian }) => {
            return submit<TLegalGuardian, { legalGuardian: TLegalGuardian }>(
              get(auth, 'selectedId', '0'),
              'administrative',
              'legal_guardian',
              data,
            )
          },
        },
        {
          title: 'Famille',
          Component: ({ resource }) => (
            <FormFamily resource={resource} admissionRecordId={get(auth, 'selectedId', '0')} />
          ),
          save: (data: { family: TFamily }) => {
            return submit<TFamily, { family: TFamily }>(
              get(auth, 'selectedId', '0'),
              'administrative',
              'family',
              data,
              true,
            )
          },
        },
        {
          title: 'Équipe médicale',
          Component: ({ resource }) => (
            <GenericStep<TAdministrative>
              resource={resource}
              subForm="medicalTeam"
              fields={MEDICAL_TEAM_FORM}
            />
          ),
          save: (data: { medicalTeam: TMedicalTeam }) => {
            return submit<TMedicalTeam, { medicalTeam: TMedicalTeam }>(
              get(auth, 'selectedId', '0'),
              'administrative',
              'medical_team',
              data,
            )
          },
        },
        {
          title: 'Complet',
          Component: ({ resource }) => (
            <Conclusion
              title="renseignements administratifs et familiaux"
              text="Vous pouvez poursuivre le formulaire d'admission dès maintenant en complétant la
                section renseignements sociaux !"
              nextStepCta="Complétez les renseignements sociaux"
              nextStep="/social/etape/1"
              firstStep="/administrative/etape/2"
              isCompleted={['validating', 'completed'].includes(resource.statusProgress)}
            />
          ),
        },
      ]}
    />
  )
}
