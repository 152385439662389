import React from 'react'
import { Control, Controller, FieldValues } from 'react-hook-form'

import Text from 'ui/typography/text'
import Group from 'ui/form/group'
import Label from 'ui/form/label'

import Input from 'ui/form/input'
import Box from 'ui/box'
import Dropdown from 'ui/form/dropdown'
import { countries } from 'constants/country'

type Props = {
  errors: any
  control: Control<FieldValues>
  register: any
  citiesList: {
    value: string
    label: string
  }[]
  watch: any
}

const AddressFields = ({ control, register, errors, citiesList, watch }: Props) => (
  <>
    <Box ai="s" f="0 1 auto">
      <Group style={{ paddingBottom: '2%' }}>
        <Label htmlFor="country" required style={{ paddingBottom: '16px' }}>
          Pays
        </Label>
        <Controller
          as={Dropdown}
          id={'country'}
          name={`country`}
          placeholder="Saisissez le pays"
          options={countries}
          control={control}
          rules={{ required: true }}
        />
        <Text fs={16} color="pink" style={{ visibility: errors.country ? 'visible' : 'hidden' }}>
          Ce champ est requis
        </Text>
      </Group>
      <Group style={{ padding: '0 0 2% 5%' }}>
        <Label htmlFor="street" required style={{ paddingBottom: '16px' }}>
          Adresse postale
        </Label>
        <Input
          id="street"
          name="street"
          placeholder="Saissez l'adresse postale"
          ref={register({ required: true })}
        />
        <Text fs={16} color="pink" style={{ visibility: errors.street ? 'visible' : 'hidden' }}>
          Ce champ est requis
        </Text>
      </Group>
    </Box>
    <Box ai="s" f="0 1 auto">
      <Group style={{ paddingBottom: '2%' }}>
        <Label htmlFor="region" style={{ paddingBottom: '16px' }}>
          Région
        </Label>
        <Input id="region" name="region" placeholder="Saisissez la région" ref={register({})} />
      </Group>
      <Group style={{ padding: '0 0 2% 5%' }}>
        <Label htmlFor="zipcode" required style={{ paddingBottom: '16px' }}>
          Code postal
        </Label>
        <Input
          id="zipcode"
          name="zipcode"
          type="number"
          placeholder="Saisissez le code postal"
          ref={register({ required: true })}
        />
        <Text fs={16} color="pink" style={{ visibility: errors.zipcode ? 'visible' : 'hidden' }}>
          Ce champ est requis
        </Text>
      </Group>
      <Group style={{ padding: '0 0 2% 5%' }}>
        <Label htmlFor="city" required style={{ paddingBottom: '16px' }}>
          Ville
        </Label>
        {watch('country') === 'France' ? (
          <Controller
            as={Dropdown}
            id={'city'}
            name={`city`}
            placeholder="Sélectionnez la ville"
            options={citiesList}
            disabled={!citiesList.length}
            control={control}
            rules={{ required: true }}
          />
        ) : (
          <Input
            id="city"
            name="city"
            placeholder="Saisissez la ville"
            ref={register({ required: true })}
          />
        )}
        <Text fs={16} color="pink" style={{ visibility: errors.city ? 'visible' : 'hidden' }}>
          Ce champ est requis
        </Text>
      </Group>
    </Box>
  </>
)
export default AddressFields
