import React, { useEffect } from 'react'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import { map } from 'lodash'

import Box from 'ui/box'
import Button from 'ui/button'
import Icon from 'ui/icon'
import Text from 'ui/typography/text'
import Group from 'ui/form/group'
import Label from 'ui/form/label'
import Input from 'ui/form/input'

import Textarea from 'ui/form/textarea'
import SelectMultiple from 'ui/form/selectMultiple'
import StepLayout from './stepLayout'

import {
  ACCOMMODATION_HANDICAP_OPTIONS,
  ACCOMMODATION_PUBLIC_OPTIONS,
  ACCOMMODATION_MODE_OPTIONS,
  PRE_PROFESSIONAL_ACTIVITY_OPTIONS,
  EQUIPMENT_ON_SITE_OPTIONS,
  CARE_STAFF_OPTIONS,
  PROFESSIONAL_ACTIVITY_OPTIONS,
  SUPPORT_ACTIVITY_OPTIONS,
  EDUCATIF_SOCIAL_STAFF_OPTIONS,
  CARE_COVERAGE_OPTIONS,
  SPECIALIZED_CARE_OPTIONS,
  EQUIPMENT_SERVICE_OPTIONS,
} from 'constants/form'
import Loader from 'ui/loader'
import Picture from 'ui/form/picture'

const StepEstablishmentAccommodation: React.FC<{
  onNext: (data: any) => void
  onPrevious: () => void
  defaultValues?: any
  errorMessages?: string[]
  isLoading?: boolean
}> = ({ onNext, onPrevious, defaultValues, errorMessages, isLoading }) => {
  const { register, handleSubmit, errors, control, watch, setValue } = useForm({
    defaultValues,
  })

  const { fields } = useFieldArray({
    control,
    name: 'pictures',
  })

  const errorMessage = 'Ce champ est requis'

  return (
    <StepLayout
      title="Personnalisation de la fiche établissement"
      subText="Ces informations seront visibiles sur la fiche de votre établissement. Vous pouvez les compléter maintenant ou plus tard dans votre interface Sahanest."
      spacingBottom="0"
    >
      <Box ai="s" f="0 1 auto">
        <Box fd="c">
          <Label
            htmlFor="establishmentPictures"
            style={{ paddingBottom: '16px', paddingTop: '16px' }}
            required
          >
            Photos de l’établissement
          </Label>
          <Text color="grey" style={{ width: '70%' }}>
            Importez les photos de votre établissement afin de les afficher sur la page publique
            Sahanest. Minimum 1 photo. Maximum 4 photos. Formats acceptés :
            <span style={{ fontStyle: 'italic' }}>jpg, png</span>
          </Text>
          <Text fs={16} color="pink" style={{ visibility: errors.pictures ? 'visible' : 'hidden' }}>
            {errorMessage}
          </Text>
          <Box style={{ maxHeight: '155px', margin: '28px 0 50px 0' }}>
            {fields?.map((field, index) => (
              <Box width="155px" style={{ marginRight: '16px' }} key={field.id}>
                <Controller
                  key={field.id}
                  control={control}
                  name={`pictures[${index}].file`}
                  defaultValue={field.value}
                  rules={index === 0 ? { required: errorMessage } : {}}
                  as={Picture}
                  id={field.id}
                  resetFile={() => setValue(`pictures[${index}].file`, null)}
                  hasError={index === 0 && !!errors.pictures?.length}
                  required={index === 0}
                />
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      <Box fd="c" ai="s" style={{ paddingBottom: '2%' }}>
        <Label htmlFor="educationalProject" style={{ paddingBottom: '16px' }} required>
          Votre projet pédagogique
        </Label>
        <Textarea
          style={{ width: '100%', boxSizing: 'border-box' }}
          id="educationalProject"
          name="educationalProject"
          placeholder="Saisissez votre projet pédagogique"
          ref={register({ required: errorMessage })}
          required
        />
        <Text
          fs={16}
          color="pink"
          style={{ visibility: errors.educationalProject ? 'visible' : 'hidden' }}
        >
          {errorMessage}
        </Text>
      </Box>
      <Box ai="s">
        <Group style={{ paddingBottom: '2%' }}>
          <Label htmlFor="accommodationCapacity" style={{ paddingBottom: '16px' }} required>
            Nombre de places totales
          </Label>
          <Input
            id="accommodationCapacity"
            name="accommodationCapacity"
            type="number"
            placeholder="Saisissez le nombre de places"
            ref={register({ required: errorMessage })}
            required
          />
          <Text
            fs={16}
            color="pink"
            style={{ visibility: errors.accommodationCapacity ? 'visible' : 'hidden' }}
          >
            {errorMessage}
          </Text>
        </Group>
        <Group style={{ padding: '0 0 2% 5%' }}>
          <Label htmlFor="capacityAvailable" style={{ paddingBottom: '16px' }} required>
            Nombre de places disponibles
          </Label>
          <Input
            id="capacityAvailable"
            name="capacityAvailable"
            type="number"
            placeholder="Saisissez le nombre de places disponibles"
            ref={register({ required: errorMessage })}
            required
          />
          <Text
            fs={16}
            color="pink"
            style={{ visibility: errors.capacityAvailable ? 'visible' : 'hidden' }}
          >
            {errorMessage}
          </Text>
        </Group>
      </Box>
      <Box ai="s" style={{ marginTop: '20px' }}>
        <Controller
          as={SelectMultiple}
          id={'accommodationMode'}
          name={'accommodationMode'}
          options={ACCOMMODATION_MODE_OPTIONS}
          control={control}
          label="Mode d'accueil"
          rules={{ required: true }}
          onChange={(value: string[]) => value}
          errorMessage={errors.accommodationMode && errorMessage}
          required
        />
      </Box>
      <Box ai="s" style={{ marginTop: '20px' }}>
        <Controller
          as={SelectMultiple}
          id={'accommodationPublic'}
          name={'accommodationPublic'}
          options={ACCOMMODATION_PUBLIC_OPTIONS}
          control={control}
          label="Public accueilli"
          rules={{ required: true }}
          errorMessage={errors.accommodationPublic && errorMessage}
          onChange={(value: string[]) => value}
          required
        />
      </Box>
      <Box ai="s" style={{ marginTop: '20px' }}>
        <Controller
          as={SelectMultiple}
          id={'accommodationHandicap'}
          name={'accommodationHandicap'}
          options={ACCOMMODATION_HANDICAP_OPTIONS}
          control={control}
          label="Handicaps pris en charge"
          rules={{ required: true }}
          errorMessage={errors.accommodationHandicap && errorMessage}
          onChange={(value: string[]) => value}
          required
        />
      </Box>
      <Text fs={20} color="green" bold style={{ margin: '30px 0 20px 0' }}>
        Activités
      </Text>
      <Box ai="s">
        <Controller
          as={SelectMultiple}
          id={'supportActivity'}
          name={'supportActivity'}
          options={SUPPORT_ACTIVITY_OPTIONS}
          control={control}
          label="Activités d’accompagnements"
          rules={{ required: true }}
          errorMessage={errors.supportActivity && errorMessage}
          onChange={(value: string[]) => value}
          required
        />
      </Box>
      <Box ai="s" style={{ marginTop: '20px' }}>
        <Controller
          as={SelectMultiple}
          id={'professionalActivity'}
          name={'professionalActivity'}
          options={PROFESSIONAL_ACTIVITY_OPTIONS}
          control={control}
          label="Activités professionnelles"
          rules={{ required: true }}
          errorMessage={errors.professionalActivity && errorMessage}
          onChange={(value: string[]) => value}
          required
        />
      </Box>
      <Box ai="s" style={{ marginTop: '20px' }}>
        <Controller
          as={SelectMultiple}
          id={'preProfessionalActivity'}
          name={'preProfessionalActivity'}
          options={PRE_PROFESSIONAL_ACTIVITY_OPTIONS}
          control={control}
          label="Activités pré-professionnelles"
          rules={{ required: true }}
          errorMessage={errors.preProfessionalActivity && errorMessage}
          onChange={(value: string[]) => value}
          required
        />
      </Box>
      <Text fs={20} color="green" bold style={{ margin: '30px 0 20px 0' }}>
        Équipements Personnels de santé
      </Text>
      <Box ai="s">
        <Controller
          as={SelectMultiple}
          id={'equipmentOnSite'}
          name={'equipmentOnSite'}
          options={EQUIPMENT_ON_SITE_OPTIONS}
          control={control}
          label="Équipements proposés sur place"
          rules={{ required: true }}
          errorMessage={errors.equipmentOnSite && errorMessage}
          onChange={(value: string[]) => value}
          required
        />
      </Box>
      <Text fs={20} color="green" bold style={{ margin: '40px 0 20px 0' }}>
        Personnels de santé
      </Text>
      <Box ai="s">
        <Controller
          as={SelectMultiple}
          id={'careStaff'}
          name={'careStaff'}
          options={CARE_STAFF_OPTIONS}
          control={control}
          label="Personnels de soins"
          rules={{ required: true }}
          errorMessage={errors.careStaff && errorMessage}
          onChange={(value: string[]) => value}
          required
        />
      </Box>
      <Box ai="s" style={{ marginTop: '20px' }}>
        <Controller
          as={SelectMultiple}
          id={'educatifSocialStaff'}
          name={'educatifSocialStaff'}
          options={EDUCATIF_SOCIAL_STAFF_OPTIONS}
          control={control}
          label="Personnel éducatif et social"
          rules={{ required: true }}
          errorMessage={errors.educatifSocialStaff && errorMessage}
          onChange={(value: string[]) => value}
          required
        />
      </Box>
      <Text fs={20} color="green" bold style={{ margin: '40px 0 20px 0' }}>
        Prises en charge
      </Text>
      <Box ai="s">
        <Controller
          as={SelectMultiple}
          id={'careCoverage'}
          name={'careCoverage'}
          options={CARE_COVERAGE_OPTIONS}
          control={control}
          label="Prises en charge de soins"
          rules={{ required: true }}
          errorMessage={errors.careCoverage && errorMessage}
          onChange={(value: string[]) => value}
          required
        />
      </Box>
      <Box ai="s" style={{ marginTop: '20px' }}>
        <Controller
          as={SelectMultiple}
          id={'specializedCare'}
          name={'specializedCare'}
          options={SPECIALIZED_CARE_OPTIONS}
          control={control}
          label="Prises en charge spécialisées"
          rules={{ required: true }}
          onChange={(value: string[]) => value}
          errorMessage={errors.specializedCare && errorMessage}
          required
        />
      </Box>
      <Box ai="s" style={{ margin: '20px 0 40px 0' }}>
        <Controller
          as={SelectMultiple}
          id={'equipmentService'}
          name={'equipmentService'}
          options={EQUIPMENT_SERVICE_OPTIONS}
          control={control}
          label="Services"
          rules={{ required: true }}
          errorMessage={errors.equipmentService && errorMessage}
          onChange={(value: string[]) => value}
          required
        />
      </Box>

      <Box as="form" onSubmit={handleSubmit(onNext)} autoComplete="off" fd="c">
        {errorMessages &&
          map(errorMessages, (message, i) => {
            return (
              <Text
                key={`error-message-${i}`}
                fs={16}
                color="pink"
                style={{ paddingBottom: '16px' }}
              >
                {message}
              </Text>
            )
          })}
        <Box jc="sb" f="0 1 auto">
          <Button
            variant="outline"
            style={{ backgroundColor: 'white' }}
            onClick={onPrevious}
            disabled={isLoading}
          >
            <Icon name="arrow" size={20} rotate={180} containerStyle={{ marginRight: '8px' }} />
            Étape précédente
          </Button>
          <Button type="submit" disabled={isLoading}>
            Finaliser la création de ma fiche
            {isLoading ? (
              <div style={{ marginLeft: '8px' }}>
                <Loader size={12} />
              </div>
            ) : (
              <Icon name="arrow" size={20} containerStyle={{ marginLeft: '8px' }} />
            )}
          </Button>
        </Box>
      </Box>
    </StepLayout>
  )
}

export default StepEstablishmentAccommodation
