import React from 'react'

import Layout from './layout'
import { Form } from 'types/form'
import { ESTABLISHMENT_TYPES } from 'types/enums'
import { countries } from 'constants/country'
import {
  SUPPORT_ACTIVITY_OPTIONS,
  PROFESSIONAL_ACTIVITY_OPTIONS,
  PRE_PROFESSIONAL_ACTIVITY_OPTIONS,
  CARE_STAFF_OPTIONS,
  EDUCATIF_SOCIAL_STAFF_OPTIONS,
  CARE_COVERAGE_OPTIONS,
  SPECIALIZED_CARE_OPTIONS,
  EQUIPMENT_SERVICE_OPTIONS,
} from 'constants/form'

const INFOS_FORM: Form = [
  [
    {
      title: 'Informations générales sur l’établissement',
      name: 'institutionName',
      type: 'text',
      label: "Nom de l'établissement",
      placeholder: "Saisissez le nom de l'établissement",
      required: true,
    },

    {
      name: 'institutionType',
      type: 'select',
      label: "Type d'établissement",
      placeholder: "Saisissez le type d'établissement",
      required: true,
      multi: true,
      options: [
        {
          label: "Maison d'accueil spécialisée",
          value: ESTABLISHMENT_TYPES.MAS,
        },
        { label: "Foyer d'accueil médicalisé", value: ESTABLISHMENT_TYPES.FAM },
        { label: 'Foyer occupationnel ou de vie', value: ESTABLISHMENT_TYPES.FO_FV },
      ],
    },
  ],
  [
    {
      name: 'socialReason',
      type: 'text',
      label: 'Raison sociale',
      placeholder: 'Saisissez la raison sociale',
      required: true,
      disabled: true,
    },
    {
      name: 'siretNumber',
      type: 'text',
      label: 'Numéro de SIRET / BCE',
      placeholder: 'Saisissez votre numéro de SIRET / BCE',
      required: true,
      disabled: true,
    },
  ],
  [
    {
      name: 'street',
      type: 'text',
      label: 'Adresse postale',
      placeholder: "Saissez l'adresse postale",
      required: true,
      disabled: false,
    },

    {
      name: 'zipcode',
      type: 'text',
      label: 'Code postal',
      placeholder: 'Saisissez le code postal',
      required: true,
      disabled: false,
    },
    {
      name: 'city',
      type: 'text',
      label: 'Ville',
      placeholder: 'Saisissez la ville',
      required: true,
      disabled: false,
    },
  ],
  [
    {
      name: 'region',
      type: 'text',
      label: 'Region',
      placeholder: 'Saisissez la région',
      required: false,
      disabled: false,
    },
    {
      name: 'country',
      type: 'select',
      label: 'Pays',
      placeholder: 'Saisissez le pays',
      required: true,
      disabled: false,
      options: countries,
    },
  ],
  [
    {
      title: 'Contact & site internet',
      name: 'website',
      type: 'text',
      label: 'Site internet',
      placeholder: 'Saisissez le site internet',
      required: false,
    },
    {
      name: 'phoneNumber',
      type: 'text',
      label: 'Numéro de téléphone',
      placeholder: 'Numéro de téléphone',
      required: true,
    },
  ],
  [
    {
      title: 'Photos de l’établissement',
      name: 'pictures[0]',
      type: 'picture',
      label: '',
      index: 0,
      required: false,
    },
    {
      title: 'Photos de l’établissement',
      name: 'pictures[1]',
      type: 'picture',
      label: '',
      index: 1,
      required: false,
    },
    {
      title: 'Photos de l’établissement',
      name: 'pictures[2]',
      type: 'picture',
      label: '',
      index: 2,
      required: false,
    },
    {
      title: 'Photos de l’établissement',
      name: 'pictures[3]',
      type: 'picture',
      label: '',
      index: 3,
      required: false,
    },
  ],
  [
    {
      title: 'Votre projet pédagogique',
      name: 'educationalProject',
      type: 'textarea',
      label: '',
      placeholder: 'Saisissez votre projet pédagogique',
      required: false,
    },
  ],
  [
    {
      title: 'Activités',
      name: 'supportActivity',
      type: 'selectMultiple',
      label: 'Activités d’accompagnements',
      required: false,
      options: SUPPORT_ACTIVITY_OPTIONS,
    },
  ],
  [
    {
      name: 'professionalActivity',
      type: 'selectMultiple',
      label: 'Activités professionnelles',
      required: false,
      options: PROFESSIONAL_ACTIVITY_OPTIONS,
    },
  ],
  [
    {
      name: 'preProfessionalActivity',
      type: 'selectMultiple',
      label: 'Activités pré-professionnelles',
      required: false,
      options: PRE_PROFESSIONAL_ACTIVITY_OPTIONS,
    },
  ],
  [
    {
      title: 'Equipements',
      name: 'equipmentOnSite',
      type: 'selectMultiple',
      label: 'Équipements proposés sur place',
      required: false,
      options: PRE_PROFESSIONAL_ACTIVITY_OPTIONS,
    },
  ],
  [
    {
      title: 'Personnels de santé',
      name: 'careStaff',
      type: 'selectMultiple',
      label: 'Personnels de soins',
      required: false,
      options: CARE_STAFF_OPTIONS,
    },
  ],
  [
    {
      name: 'educatifSocialStaff',
      type: 'selectMultiple',
      label: 'Personnel éducatif et social',
      required: false,
      options: EDUCATIF_SOCIAL_STAFF_OPTIONS,
    },
  ],

  [
    {
      title: 'Prises en charge',
      name: 'careCoverage',
      type: 'selectMultiple',
      label: 'Prises en charge de soins',
      required: false,
      options: CARE_COVERAGE_OPTIONS,
    },
  ],
  [
    {
      name: 'specializedCare',
      type: 'selectMultiple',
      label: 'Prises en charge spécialisées',
      required: false,
      options: SPECIALIZED_CARE_OPTIONS,
    },
  ],
  [
    {
      name: 'equipmentService',
      type: 'selectMultiple',
      label: 'Services',
      required: false,
      options: EQUIPMENT_SERVICE_OPTIONS,
    },
  ],
]

export default function Infos() {
  return (
    <Layout
      title="Éditer la page établissement"
      subText=""
      help={{
        icon: 'building',
        title: 'Pour quelles raisons je ne peux pas modifier la raison sociale ?',
        text: 'Sahanest souhaite proposer aux usagers des établissements vérifiés. La raison sociale ne peut donc être modifié afin de proposé des établissements de qualité.',
      }}
      fields={INFOS_FORM}
      subForm="info"
    />
  )
}
