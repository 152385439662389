import React, { useReducer, useEffect, useState, useContext, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { concat, flatMap, get, merge } from 'lodash'

import Box from 'ui/box'
import Title from 'ui/typography/title'
import Text from 'ui/typography/text'
import Navbar from 'ui/navbar'
import Stepper, { Step } from 'ui/stepper'
import Button from 'ui/button'
import Icon from 'ui/icon'
import { Footer } from 'components/pages/layout'
import { InstitutionRegistration, InstitutionInfoFromWebflow } from 'types/institution'
import User from 'types/user'
import StepUserAndContactInfos from './blocks/stepUserAndContactInfos'
import StepEstablishment from './blocks/stepEstablishment'
import StepInclusiveEstablishment from './blocks/stepInclusiveEstablishment'
import StepEstablishmentAccommodation from './blocks/stepEstablishmentAccommodation'
import StepEstablishmentType from './blocks/stepEstablishmentType'

import Logo from 'components/logo'
import { ReactComponent as Success } from 'assets/illustrations/success.svg'
import {
  create,
  getInstitutionWebflowAvailability,
  createNewInstitution,
  searchInstitutions,
} from 'api/institution'
import { resendEmailConfirmation } from 'api/user'
import { useToasts } from 'hooks/useToaster'

import { AuthContext } from 'App'
import { useWebflowSlug } from 'hooks/useParamId'
import StepSuggestedEstablishments from './blocks/stepSuggestedEstablishments'
import { wait } from '@testing-library/react'

type SignUpErrorReply = {
  response: {
    data?: {
      user: { [key: string]: string[] }
      institution: { [key: string]: string[] }
    }
  }
}

export type State = {
  completedSteps: {
    [key in
      | 'userAndContactInfos'
      | 'establishmentType'
      | 'establishment'
      | 'establishmentAccommodation'
      | 'suggestedEstablishment']: boolean
  }
  currentStep: number
  success: boolean
  loading: boolean
  errorMessages?: string[]
  data: Partial<InstitutionRegistration>
  user?: User
  selectedInstitution?: InstitutionInfoFromWebflow
  suggestedEstablishments: InstitutionInfoFromWebflow[]
  habitatType: 'inclusive' | 'medical'
}

const initialState: State = {
  currentStep: 0,
  completedSteps: {} as State['completedSteps'],
  success: false,
  loading: false,
  data: {},
  selectedInstitution: {} as InstitutionInfoFromWebflow,
  suggestedEstablishments: [],
  habitatType: 'medical',
}

const ORDER: [keyof State['completedSteps'], string][] = [
  ['userAndContactInfos', 'Création du compte'],
  ['establishmentType', 'Type de lieu de vie'],
  ['establishment', 'Informations du lieu de vie'],
  ['establishmentAccommodation', 'Personnalisation de la fiche'],
]

const reducer = (state: State, action: { type: string; payload?: any }) => {
  const { type, payload } = action

  switch (type) {
    case 'MULTI_ETABLISHEMNT':
      return {
        ...state,
        data: { ...state.data, user: payload.user },
      }
    case 'NEXT_STEP':
      return {
        ...state,
        currentStep:
          payload.step ??
          (state.currentStep < ORDER.length - 1 ? state.currentStep + 1 : state.currentStep),
        completedSteps: { ...state.completedSteps, [payload.stepName]: true },
        data: merge(state.data, payload.data),
      }
    case 'SET_STATE':
      return {
        ...state,
        success: payload.success,
        errorMessages: payload.errorMessages,
        user: payload.user,
        loading: payload.loading,
      }
    case 'PREVIOUS_STEP':
      return {
        ...state,
        currentStep: payload?.step ? payload.step : state.currentStep - 1,
      }
    case 'UPDATE_SELECTED_ESTABLISHMENT':
      return {
        ...state,
        selectedInstitution: payload.selectedInstitution,
      }
    case 'SET_HABITAT_TYPE':
      return {
        ...state,
        habitatType: payload.habitatType,
      }
    case 'SET_SUGGESTED_ESTABLISHMENT':
      return {
        ...state,
        suggestedEstablishments: payload.data,
      }
    default:
      return state
  }
}

export default function SignUp() {
  const history = useHistory()
  const { auth } = useContext(AuthContext)
  const [state, dispatch] = useReducer(reducer, initialState)
  const [dataFromWebFlow, setDataFromWebFlow] = useState<InstitutionInfoFromWebflow | undefined>()
  const webflowSlug = useWebflowSlug()
  const { addToast, addGenericErrorToast } = useToasts()
  const { addEstablishment, updateAddEstablishment } = useContext(AuthContext)

  useEffect(() => {
    const getItem = async () => {
      const response = await getInstitutionWebflowAvailability(webflowSlug)
      response && setDataFromWebFlow(response)
    }

    webflowSlug && getItem()
  }, [webflowSlug])

  const hasSuggestedEstablishment = useMemo(
    () => state.suggestedEstablishments.length > 0,
    [state.suggestedEstablishments],
  )

  const createUser = (data: State['data']['info']) => {
    dispatch({
      type: 'SET_STATE',
      payload: { error: false, success: false, user: undefined, loading: true },
    })
    create(merge(state.data, { info: data, habitatType: state.habitatType }), webflowSlug)
      .then(reply =>
        dispatch({
          type: 'SET_STATE',
          payload: { error: false, success: true, user: reply, loading: false },
        }),
      )
      .catch(({ response: { data } }: SignUpErrorReply) => {
        if (data?.user.email) {
          dispatch({
            type: 'NEXT_STEP',
            payload: {
              stepName: 'userAndContactInfos',
              step: 0,
            },
          })
          addToast({
            type: 'error',
            title: 'Erreur !',
            message: `L'email de connexion que vous avez renseigné est déjà utilisé`,
          })
          window.scrollTo(0, 0)
        }
        dispatch({
          type: 'SET_STATE',
          payload: {
            errorMessages: data
              ? concat(flatMap(data.user), flatMap(data.institution))
              : ['Une erreur est survenue, veuillez réessayer.'],
            success: false,
            loading: false,
          },
        })
      })
  }

  const availableAccomadationFiels = useMemo(
    () => ({
      accommodationCapacity:
        state.selectedInstitution?.infoAccommodationCapacity ||
        dataFromWebFlow?.infoAccommodationCapacity,
      accommodationMode:
        state.selectedInstitution?.infoAccommodationMode || dataFromWebFlow?.infoAccommodationMode,
    }),
    [state.selectedInstitution, dataFromWebFlow],
  )

  const renderContent = () => {
    switch (state.currentStep) {
      case 0:
        return (
          <StepUserAndContactInfos
            dataFromWebFlow={dataFromWebFlow}
            defaultValues={state.data}
            onNext={(data: State['data']) =>
              dispatch({
                type: 'NEXT_STEP',
                payload: {
                  stepName: 'userAndContactInfos',
                  data: { ...state.data, ...data },
                },
              })
            }
          />
        )
      case 1:
        return (
          <StepEstablishmentType
            onPrevious={() => dispatch({ type: 'PREVIOUS_STEP' })}
            onNext={(data: State['habitatType']) => {
              dispatch({ type: 'SET_HABITAT_TYPE', payload: { habitatType: data } })
              dispatch({
                type: 'NEXT_STEP',
                payload: {
                  stepName: 'establishmentType',
                  data: { ...state.data },
                  step: 2,
                },
              })
            }}
          />
        )
      case 2: {
        const params = {
          defaultValues: merge(state.data.info, {
            country: dataFromWebFlow?.infoCountry,
            city: dataFromWebFlow?.infoCity,
            street: dataFromWebFlow?.infoStreet,
            zipcode: dataFromWebFlow?.infoZipcode,
            institutionName: dataFromWebFlow?.infoInstitutionName,
            website: dataFromWebFlow?.infoWebsite,
            region: dataFromWebFlow?.infoRegion,
            siretNumber: dataFromWebFlow?.infoSiretNumber,
            socialReason: dataFromWebFlow?.infoSocialReason,
            institutionType: dataFromWebFlow?.infoInstitutionType,
            phoneNumber: dataFromWebFlow?.infoPhoneNumber,
            managerName: dataFromWebFlow?.managerName,
          }),
          onPrevious: () => dispatch({ type: 'PREVIOUS_STEP' }),
          onNext: (data: State['data']['info']) => {
            searchInstitutions(data).then(response => {
              if (response.length > 0) {
                dispatch({ type: 'SET_SUGGESTED_ESTABLISHMENT', payload: { data: response } })
                dispatch({
                  type: 'NEXT_STEP',
                  payload: {
                    stepName: 'establishment',
                    data: { info: data },
                    step: 3,
                  },
                })
              } else {
                dispatch({
                  type: 'NEXT_STEP',
                  payload: {
                    stepName: 'establishment',
                    data: { info: data },
                    step: 4,
                  },
                })
              }
            })
          },
        }

        return state.habitatType === 'inclusive' ? (
          <StepInclusiveEstablishment
            {...params}
            onNext={(data: State['data']['info']) => {
              createUser(data)
            }}
            isLoading={state.loading}
          />
        ) : (
          <StepEstablishment {...params} />
        )
      }
      case 3:
        return (
          <StepSuggestedEstablishments
            suggestedEstablishments={state.suggestedEstablishments}
            onChange={data => {
              dispatch({
                type: 'UPDATE_SELECTED_ESTABLISHMENT',
                payload: {
                  selectedInstitution: data,
                },
              })
            }}
            selectedInstitution={state.selectedInstitution}
            onPrevious={() => dispatch({ type: 'PREVIOUS_STEP' })}
            onNext={() =>
              dispatch({
                type: 'NEXT_STEP',
                payload: {
                  stepName: 'suggestedEstablishment',
                  data: state.data,
                  step: 4,
                },
              })
            }
          />
        )
      case 4:
        return (
          <StepEstablishmentAccommodation
            defaultValues={merge(
              state.data.info,
              state.selectedInstitution && { ...availableAccomadationFiels },
              { pictures: ['', '', '', ''] },
            )}
            onPrevious={() =>
              dispatch({
                type: 'PREVIOUS_STEP',
                payload: { step: hasSuggestedEstablishment ? 2 : 1 },
              })
            }
            onNext={(data: State['data']['info']) => {
              if (!addEstablishment) {
                createUser(data)
                dispatch({
                  type: 'NEXT_STEP',
                  payload: { stepName: 'establishmentAccommodation', data: { info: data } },
                })
              } else {
                dispatch({
                  type: 'SET_STATE',
                  payload: { error: false, success: false, user: undefined, loading: true },
                })
                createNewInstitution(merge(state.data, { info: { ...data } }))
                  .then(() => {
                    addToast({
                      type: 'success',
                      title: 'Succès !',
                      message: `L'établissement a été ajouté !`,
                    })
                    updateAddEstablishment(false)
                    history.push('/candidatures')
                  })
                  .catch(() => addGenericErrorToast())
              }
            }}
            errorMessages={state.errorMessages}
            isLoading={state.loading}
          />
        )
      default:
    }
  }

  return (
    <Box fd="r" style={{ width: '100%', minHeight: '100vh' }}>
      <Box
        style={{
          flex: '2',
        }}
        bg="green"
      >
        <Box padding="12%" fd="c">
          <Logo paddingBottom="30%" url="https://www.sahanest.fr/" />
          {state.currentStep === 0 ? (
            <Box fd="c">
              <Title bold fs={28} color="white">
                Bienvenue sur Sahanest, inscrivez-vous en quelques clics.
              </Title>
              <Text fs={16} color="white" style={{ paddingTop: '24px' }}>
                Rejoignez des milliers de professionnels de l’accueil et gérez vos dossiers sur une
                seule plateforme.
              </Text>
            </Box>
          ) : (
            <Stepper>
              {ORDER.map(([step, name], index) => (
                <Step
                  key={name}
                  index={`${index + 1}`}
                  variant={
                    state.completedSteps[step]
                      ? 'completed'
                      : state.currentStep === index
                        ? 'pending'
                        : 'todo'
                  }
                >
                  {name}
                </Step>
              ))}
            </Stepper>
          )}
        </Box>
      </Box>
      <Box style={{ flex: '4' }} bg="greyLight3">
        <Box fd="c">
          {!auth?.isConfirmed ? (
            <Navbar
              yellowButtonLabel="Déjà un compte ?"
              onClick={() => {
                updateAddEstablishment(false)
                history.push('/connexion')
              }}
              href={'/connexion'}
            />
          ) : (
            <Navbar
              yellowButtonLabel="Mon Profil"
              title=""
              onClick={() => {
                updateAddEstablishment(false)
                history.push('/mon-compte')
              }}
            />
          )}
          {!state.success ? (
            renderContent()
          ) : (
            <Box padding="15% 10% 12% 10%" fd="c" ai="c">
              <Success style={{ marginBottom: '8%' }} />
              <Title fs={28} bold spacingBottom="8px">
                {get(state.data.userInfos, 'firstName')} Votre compte est presque prêt !
              </Title>
              <Text opacity={0.6} spacingBottom="4%">
                Confirmez votre adresse e-mail pour accéder à votre espace.
              </Text>
              <Button
                onClick={() => {
                  resendEmailConfirmation(state?.data.user?.email).then(() =>
                    addToast({
                      title: 'Succès !',
                      type: 'success',
                      message: `L'email vient d'être renvoyé !`,
                    }),
                  )
                }}
              >
                Envoyer à nouveau
                <Icon name="arrow" size={20} containerStyle={{ marginLeft: '8px' }} />
              </Button>
            </Box>
          )}
          <Footer />
        </Box>
      </Box>
    </Box>
  )
}
