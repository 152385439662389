import React, { useEffect, useState, useMemo } from 'react'
import { useForm, Controller } from 'react-hook-form'

import Box from 'ui/box'
import Button from 'ui/button'
import Icon from 'ui/icon'
import Text from 'ui/typography/text'
import Group from 'ui/form/group'
import Label from 'ui/form/label'
import Input from 'ui/form/input'
import Dropdown from 'ui/form/dropdown'
import StepLayout from './stepLayout'
import { countries } from 'constants/country'
import { getCitiesByZipCode } from 'api/laposte'
import { formatLaposteCityList } from '../helpers'
import { STRUCTURE_PLACE_OPTIONS } from 'constants/form'
import Loader from 'ui/loader'
import { InstitutionInfo } from 'types/institution'
import { AddressFields, ContactFields } from '../components'

interface Props {
  onNext: (data: InstitutionInfo) => void
  onPrevious: () => void
  defaultValues?: Partial<InstitutionInfo>
  isLoading: boolean
}

const SteInclusiveEstablishment = ({ onNext, onPrevious, defaultValues, isLoading }: Props) => {
  const { register, handleSubmit, errors, control, watch } = useForm<InstitutionInfo>({
    defaultValues: { ...defaultValues, country: 'France' },
  })
  const [cities, setCities] = useState<string[]>([])

  // Create options List for City Dropdown (when cities state is Changing)
  const citiesList = useMemo(() => formatLaposteCityList(cities), [cities])

  // Launch Fetch or Clear Cities State when zipcode Field is Changing
  useEffect(() => {
    const country = watch('country')
    const zipcode = watch('zipcode')

    if (country === 'France' && zipcode?.length === 5) {
      getCitiesByZipCode(zipcode).then(setCities)
    } else {
      setCities([])
    }
  }, [watch('zipcode')])

  return (
    <StepLayout
      spacingBottom="8px"
      title="Informations du lieu de vie"
      subText="Ces informations seront visibiles sur la fiche de votre lieu de vie."
    >
      <Box as="form" onSubmit={handleSubmit(onNext)} autoComplete="off" fd="c">
        <Box ai="s" f="0 1 auto">
          <Group style={{ padding: '0 0 2% 0' }}>
            <Label htmlFor="managerName" required style={{ paddingBottom: '16px' }}>
              Nom du gestionnaire de lieu de vie
            </Label>
            <Input
              id="managerName"
              name="managerName"
              placeholder="Saisissez le nom du gestionnaire"
              ref={register({ required: true })}
            />
            <Text
              fs={16}
              color="pink"
              style={{ visibility: errors.managerName ? 'visible' : 'hidden' }}
            >
              Ce champ est requis
            </Text>
          </Group>
        </Box>
        <Box ai="s" f="0 1 auto">
          <Group style={{ paddingBottom: '2%' }}>
            <Label htmlFor="structurePlace" required style={{ paddingBottom: '16px' }}>
              Type de lieu de vie
            </Label>
            <Controller
              as={Dropdown}
              id={'structurePlace'}
              name={`structurePlace`}
              placeholder="Saisissez le type de lieu de vie"
              options={STRUCTURE_PLACE_OPTIONS}
              control={control}
              isMulti={false}
              rules={{ required: true }}
            />
            <Text
              fs={16}
              color="pink"
              style={{ visibility: errors.institutionType ? 'visible' : 'hidden' }}
            >
              Ce champ est requis
            </Text>
          </Group>
          <Group style={{ padding: '0 0 2% 5%' }}>
            <Label htmlFor="institutionName" required style={{ paddingBottom: '16px' }}>
              Nom du lieu de vie
            </Label>
            <Input
              id="institutionName"
              name="institutionName"
              placeholder="Saisissez le nom du lieu de vie"
              ref={register({ required: true })}
            />
            <Text
              fs={16}
              color="pink"
              style={{ visibility: errors.institutionName ? 'visible' : 'hidden' }}
            >
              Ce champ est requis
            </Text>
          </Group>
        </Box>
        <AddressFields
          errors={errors}
          control={control}
          register={register}
          citiesList={citiesList}
          watch={watch}
        />
        <Box ai="s" f="0 1 auto">
          <ContactFields control={control} register={register} errors={errors} />
        </Box>
        <Box jc="sb" f="0 1 auto">
          <Button
            variant="outline"
            style={{ backgroundColor: 'white' }}
            onClick={onPrevious}
            disabled={isLoading}
          >
            <Icon name="arrow" size={20} rotate={180} containerStyle={{ marginRight: '8px' }} />
            Étape précédente
          </Button>
          <Button type="submit" disabled={isLoading}>
            Étape suivante
            {isLoading ? (
              <div style={{ marginLeft: '8px' }}>
                <Loader size={12} />
              </div>
            ) : (
              <Icon name="arrow" size={20} containerStyle={{ marginLeft: '8px' }} />
            )}
          </Button>
        </Box>
      </Box>
    </StepLayout>
  )
}

export default SteInclusiveEstablishment
