import React from 'react'
import styled from 'styled-components'

import Box from 'ui/box'
import { LinkButton as Button } from 'ui/button'
import Title from 'ui/typography/title'
import Text from 'ui/typography/text'
import Link from 'ui/typography/link'
import Icon from 'ui/icon'
import Separator from 'ui/separator'

const Overlay = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11;
`
const Content = styled(Box).attrs({ fd: 'c', ai: 'st', jc: 'se' })`
  width: 30vw;
  height: 100vh;
  background-color: white;
  border-radius: 4px 0 0 4px;
  position: absolute;
  top: 0;
  right: 0;
`

export default function HelpPanel({ onClose }: { onClose: () => void }) {
  return (
    <Overlay>
      <Content>
        <Box fd="r" ai="c" padding="24px 32px" f="initial">
          <Title fs={20} bold style={{ flex: 1, paddingRight: '16px' }}>
            Besoin d'aide
          </Title>
          <Icon name="close" color="black" size={16} onClick={onClose} />
        </Box>
        <Separator color="greyLightN3" spacingBottom={16} />
        <Box fd="c" style={{ overflow: 'scroll' }} padding="16px 32px">
          <Text fs={18} spacingBottom="16px">
            Comment puis-je contacter l’équipe Sahanest ?
          </Text>
          <Box fd="c" style={{ margin: '16px 0' }} padding="24px" bg="greyLightN" f="0 1 auto">
            <Box fd="r" bg="greyLightN">
              <Icon name="message" color="green" size={24} />
              <Text fs={16} style={{ paddingLeft: '24px' }} spacingBottom="16px">
                <b>
                  Par e-mail : <br />
                </b>
                Vous pouvez nous envoyer un mail à tout moment à l'adresse suivante :
                <Link
                  color="green"
                  href="mailto:contact@sahanest.fr"
                  target="_blank"
                  style={{ display: 'inline' }}
                >
                  {' '}
                  contact@sahanest.fr
                </Link>
                . L\'équipe fera ses meilleurs efforts pour répondre le plus vite possible à votre
                sollicitation en fonction de sa disponibilité.
              </Text>
            </Box>
            <Button className="primary" href="mailto:contact@sahanest.fr" target="_blank">
              Contacter par e-mail
            </Button>
          </Box>
          <Box fd="c" style={{ margin: '16px 0' }} padding="24px" bg="greyLightN" f="0 1 auto">
            <Box fd="r" bg="greyLightN">
              <Icon name="phone" color="green" size={24} />
              <Text fs={16} style={{ paddingLeft: '24px' }} spacingBottom="16px">
                <b>
                  Par téléphone : <br />
                </b>
                Vous pouvez nous contacter par téléphone de 9h à 18h en semaine pour toute question.
              </Text>
            </Box>
            <Button className="primary" href="tel:+33367840322">
              Appeler le 07 44 31 13 10
            </Button>
          </Box>
        </Box>
      </Content>
    </Overlay>
  )
}
