import React, { useContext, useMemo } from 'react'
import { capitalize, get, isEmpty, map } from 'lodash'
import { fr } from 'date-fns/locale'
import { format } from 'date-fns'

import Box from 'ui/box'
import Title from 'ui/typography/title'
import Text from 'ui/typography/text'
import Loader from 'ui/loader'
import Icon from 'ui/icon'

import useResource, { Fetched } from 'hooks/useResource'
import { SECTIONS } from 'components/pages/institution/myInstitution/kit/form/step2'

import { AuthContext } from 'App'
import Kit from 'types/kit'
import { InstitutionCandidacy } from 'types/candidacy'
import { getKitList } from 'api/kit'
import { getAdmission } from 'api/admission'

export default function Step1() {
  const { auth } = useContext(AuthContext)
  const [resourceState] = useResource<{ candidacy: InstitutionCandidacy; kit: Kit }, {}>(
    undefined,
    () =>
      getAdmission(get(auth, 'selectedId', '0'), { current: true }).then(admission => {
        const candidacy = get(admission.candidacies, 0) as InstitutionCandidacy
        const institutionId = candidacy?.establishment?.id || ''

        return getKitList(candidacy.kitListId, institutionId).then(kit => ({
          candidacy,
          kit,
        }))
      }),
    [],
  )

  if (resourceState.loading || !!resourceState.error) {
    return (
      <Box ai="c" jc="c">
        <Loader />
      </Box>
    )
  }

  const {
    resource: { candidacy, kit },
  } = resourceState as Fetched<{
    kit: Kit
    candidacy: InstitutionCandidacy
  }>

  return (
    <Box
      fd="c"
      style={{
        minHeight: '100%',
      }}
    >
      <Box fd="c" padding="32px" style={{ overflowY: 'scroll' }}>
        <Box fd="c" f="initial">
          <Box br={4} bg="green" padding="24px" style={{ marginBottom: '24px' }}>
            <Icon
              name="check"
              color="white"
              size={20}
              bg="greenDark"
              cp="10px"
              br={50}
              containerStyle={{ marginRight: '16px' }}
            />
            <Box fd="c">
              <Title fs={20} bold color="white">
                {`L’entrée de ${candidacy.patientName} dans l’établissement est confirmée`}
              </Title>
              <Box fd="c" style={{ marginTop: '16px' }}>
                <Text fs={14} color="white">
                  OÙ ?
                </Text>
                <Text fs={16} color="white" opacity={0.7}>
                  {`${candidacy.name} ${candidacy.address}`}
                </Text>
              </Box>
              <Box fd="c" style={{ marginTop: '16px' }}>
                <Text fs={14} color="white">
                  QUAND ?
                </Text>
                <Text fs={16} color="white" opacity={0.7}>
                  {`Le ${format(new Date(candidacy.engageDateProposition as string), 'PPP', {
                    locale: fr,
                  })}`}
                </Text>
              </Box>
              <Box fd="c" style={{ marginTop: '16px' }}>
                <Text fs={14} color="white">
                  QUI CONTACTER ?
                </Text>
                <Text fs={16} color="white" opacity={0.7}>
                  {`${candidacy.name} : ${candidacy.phone}`}
                </Text>
              </Box>
              <Box fd="c" style={{ marginTop: '16px' }}>
                <Text fs={14} color="white">
                  QUE DOIS-JE APPORTER ?
                </Text>
                <Text fs={16} color="white" opacity={0.7}>
                  Tout est inscrit sur le trousseau d’accueil ci-dessous.
                </Text>
              </Box>
            </Box>
          </Box>
          <Box fd="c" br={4} withBorder padding="24px">
            {SECTIONS.filter(({ name }) => !isEmpty(get(kit, name))).map(({ name, panelLabel }) => (
              <Box fd="c" key={name}>
                <Text color="black" bold style={{ margin: '24px 0 8px 0' }}>
                  {panelLabel}
                </Text>
                <Box br={4} bg="greyLightN" fd="c" padding="24px">
                  {map(get(kit, name), item => {
                    return (
                      <Box jc="sb" style={{ marginTop: '8px' }} key={`${name}-item-${item.name}`}>
                        <Text color="black">{capitalize(item.name)}</Text>
                        <Text color="black">{item.qte}</Text>
                      </Box>
                    )
                  })}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
