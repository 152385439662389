import React, { SyntheticEvent } from 'react'
import styled from 'styled-components'

import Box from '../box'
import Link from '../typography/link'
import Text from '../typography/text'
import Logo from 'components/logo'

interface Props {
  yellowButtonLabel?: string
  onClick?: (e: SyntheticEvent) => void
  onClickHelp?: (e: SyntheticEvent) => void
  title?: string
  hasLogo?: boolean
  href?: string
}

const Container = styled.div`
  box-shadow: 0px 7px 24px rgba(0, 0, 0, 0.05);
  height: 85px;
  width: 100%;
  background-color: ${props => props.theme.white};
  display: flex;
  align-items: center;
`

const YellowBox = styled(Link)`
  background-color: ${props => props.theme.yellow};
  height: 100%;
  padding: 0 32px;
`

export default function Navbar({
  yellowButtonLabel,
  onClick,
  onClickHelp,
  title,
  hasLogo,
  href,
}: Props) {
  return (
    <Container>
      {hasLogo && (
        <Box padding="0 0 0 2%" fd="c">
          <Logo darkLogo />
        </Box>
      )}
      <Box jc="sb" padding="0 32px">
        {title && <Text fs={28}>{title}</Text>}
        {onClickHelp && (
          <Link icon="help" bold onClick={onClickHelp} href={href}>
            Besoin d&apos;aide ?
          </Link>
        )}
      </Box>
      {onClick && (
        <YellowBox icon="account" bold onClick={onClick} href={href}>
          {yellowButtonLabel}
        </YellowBox>
      )}
    </Container>
  )
}
