import React, { useState, useEffect, forwardRef } from 'react'
import styled from 'styled-components'

import Icon from 'ui/icon'
import Text from 'ui/typography/text'
import Link from 'ui/typography/link'
import Box from 'ui/box'

const Label = styled.label<{ state: 'empty' | 'fill'; hasError?: boolean; disabled?: boolean }>`
  position: relative;
  font-family: Nunito;
  font-size: 16px;
  line-height: 26px;
  color: ${props => (props.hasError ? props.theme.pink : props.theme.black)};
  border: ${props =>
    props.state === 'empty'
      ? `1px dashed ${props.hasError ? props.theme.pink : props.theme.greyLightN2}`
      : `1px solid ${props.hasError && props.theme.pink}`};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: ${props => (props.state === 'empty' ? '2px' : '4px')};
  box-sizing: border-box;
  min-height: 120px;
  opacity: ${props => (props.disabled ? 0.6 : 1)};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  width: 155px;
  height: 155px;

  input {
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  }
`

const Input = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  display: block;

  :img {
    height: 155px;
    width: 155px;
    border-radius: 4px;
  }
`

const Image = styled.img`
  height: 155px;
  width: 155px;
  border-radius: 4px;
`

const IconContainer = styled(Box)`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: white;
  position: absolute;
  bottom: 10px;
  right: 10px;
`

type PictureProps = {
  id?: string
  name: string
  hasError?: boolean
  resetFile: (id?: string) => void
  onChange?: any
  uploadedFile?: any
} & Omit<React.ComponentProps<'input'>, 'ref'>

// eslint-disable-next-line react/display-name
const Picture = forwardRef(
  ({ name, resetFile, hasError, uploadedFile, disabled, ...props }: PictureProps, ref: any) => {
    const [image, setImage] = useState('')
    const reader = new FileReader()
    const [destroyFiles, setDestroyFiles] = useState<{ id?: any }[]>([])

    useEffect(() => {
      if (uploadedFile) {
        setImage(uploadedFile.pictureUrl)
      }
    }, [uploadedFile])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files
      if (files && files.length > 0) {
        props?.onChange?.(files)
        reader.readAsDataURL(files[0])
        reader.onloadend = () => {
          setImage(reader.result as string)
        }
      }
    }

    return (
      <Label state={image ? 'fill' : 'empty'} hasError={hasError} disabled={disabled}>
        <Input
          type="file"
          accept=".png, .jpeg, .jpg"
          name={name}
          ref={ref}
          onChange={handleChange}
        />
        {!image ? (
          <Box fd="c" ai="c" style={{ padding: '16px 8px' }}>
            <Icon name="upload" color={hasError ? 'pink' : 'green'} size={18} cp="0 0 11px 0" />
            <Text fs={16} color={hasError ? 'pink' : 'greyDark2'} as="div" align="center">
              Importez un fichier (Max. 2Mo) ou
            </Text>
            <Link
              fs={16}
              color={hasError ? 'pink' : 'green'}
              underline
              style={{ textAlign: 'center' }}
            >
              parcourir votre ordinateur
            </Link>
          </Box>
        ) : (
          <>
            <Image src={image} style={{ objectFit: 'cover' }} />
            <IconContainer
              ai="c"
              jc="c"
              onClick={event => {
                event.preventDefault()
                if (uploadedFile) {
                  resetFile(uploadedFile.id)
                  setDestroyFiles([...destroyFiles, { id: uploadedFile.id }])
                } else {
                  resetFile()
                }
                setImage('')
              }}
            >
              <Icon name="close" color="black" size={14} />
            </IconContainer>
          </>
        )}
      </Label>
    )
  },
)

export default Picture
