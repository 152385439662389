import React, { useEffect, useState, useContext } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { find, get } from 'lodash'

import Box, { BoxWithHeader } from 'ui/box'
import { BackButton } from 'ui/button'
import Text from 'ui/typography/text'
import Title from 'ui/typography/title'
import Link from 'ui/typography/link'
import Loader from 'ui/loader'
import Icon from 'ui/icon'
import Button from 'ui/button'

import RejectCandidacy from 'components/modals/candidacy/reject'
import RequestAdditionalInformation from 'components/modals/candidacy/requestAdditionalInformation'
import Accept from 'components/modals/candidacy/accept'

import { usePageName } from 'hooks/usePageName'
import useResource, { Fetched } from 'hooks/useResource'
import { getAdmissionRecord } from 'api/admissionRecord'
import { getCandidacy } from 'api/candidacy'
import AdmissionRecord from 'types/admissionRecord'
import { Candidacy } from 'types/candidacy'
import { OPTIONS_TUTOR_STATUS } from 'components/pages/patient/administrative/forms/legalGuardian'

import { AuthContext } from 'App'

import AdmissionRecordSummary from './summary'

export default function AdmissionRecordDetails({
  showCandidacyActions,
}: {
  showCandidacyActions?: boolean
}) {
  const history = useHistory()
  const { auth } = useContext(AuthContext)
  const { candidacyId } = useParams<{ candidacyId: string }>()
  const { setPageName } = usePageName()
  const [openModal, setOpenModal] = useState<string>()
  const [resourceState] = useResource<
    { candidacy: Candidacy; admissionRecord: AdmissionRecord },
    {}
  >(undefined, () => {
    return getCandidacy(candidacyId).then(candidacy =>
      getAdmissionRecord(candidacy.admissionRecordId).then(admissionRecord => ({
        candidacy,
        admissionRecord,
      })),
    )
  }, [])

  useEffect(() => {
    setPageName(showCandidacyActions ? 'Candidatures' : 'Suivi de l’admission')
  }, [])

  if (resourceState.loading || !!resourceState.error) {
    return (
      <Box ai="c" jc="c">
        <Loader />
      </Box>
    )
  }

  const {
    resource: { admissionRecord, candidacy },
    resource,
  } = resourceState as Fetched<{
    candidacy: Candidacy
    admissionRecord: AdmissionRecord
  }>

  return (
    <Box gutter="negative" style={{ maxHeight: '100%' }}>
      <BoxWithHeader
        br={4}
        style={{
          margin: '0 15px',
          alignSelf: 'flex-start',
        }}
      >
        <Box>
          <BackButton onClick={history.goBack} />
          <Title fs={24} bold>
            {`Dossier de ${admissionRecord?.administrative?.concerned.firstName} ${admissionRecord?.administrative?.concerned.lastName}`}
          </Title>
        </Box>

        <Box fd="c" padding="32px 32px 0 32px">
          <AdmissionRecordSummary {...resource} />
        </Box>
      </BoxWithHeader>
      <Box
        fd="c"
        width="380px"
        style={{
          margin: '0 15px',
          alignSelf: 'flex-start',
        }}
      >
        <BoxWithHeader br={4} style={{ marginBottom: '15px' }}>
          <Box>
            <Title fs={20} bold>
              Informations de contact
            </Title>
          </Box>

          <Box fd="c" padding="32px 32px 0 32px">
            <Box style={{ marginBottom: '30px' }}>
              <Box fd="c">
                <Text fs={14} opacity={0.7} style={{ textTransform: 'uppercase' }}>
                  Prénom
                </Text>
                <Text fs={16}>{admissionRecord?.administrative?.legalGuardian.firstName}</Text>
              </Box>
              <Box fd="c">
                <Text fs={14} opacity={0.7} style={{ textTransform: 'uppercase' }}>
                  Nom
                </Text>
                <Text fs={16}>{admissionRecord?.administrative?.legalGuardian.lastName}</Text>
              </Box>
            </Box>
            <Box fd="c" style={{ marginBottom: '30px' }}>
              <Text fs={14} opacity={0.7} style={{ textTransform: 'uppercase' }}>
                Agit en qualité de
              </Text>
              <Text fs={16}>
                {get(
                  find(OPTIONS_TUTOR_STATUS, [
                    'value',
                    admissionRecord?.administrative?.legalGuardian.status,
                  ]),
                  'label',
                  '-',
                )}
              </Text>
            </Box>
            <Box ai="c" style={{ marginBottom: '8px' }}>
              <Icon
                name="phone"
                size={14}
                color="yellowDark"
                containerStyle={{ marginRight: '8px' }}
              />
              <Link
                fs={16}
                color="yellowDark"
                target="_blank"
                href={`tel:${admissionRecord?.administrative?.legalGuardian.phoneNumber}`}
              >
                {admissionRecord?.administrative?.legalGuardian.phoneNumber}
              </Link>
            </Box>
            <Box ai="c">
              <Icon
                name="envelope"
                size={14}
                color="green"
                containerStyle={{ marginRight: '8px' }}
              />
              <Link
                fs={16}
                color="green"
                target="_blank"
                href={`mailto:${admissionRecord?.administrative?.legalGuardian.email}`}
              >
                {admissionRecord?.administrative?.legalGuardian.email}
              </Link>
            </Box>
          </Box>
        </BoxWithHeader>

        {showCandidacyActions && (
          <>
            <Button onClick={() => setOpenModal('accept')}>Accepter le dossier</Button>
            <Box jc="sb">
              <Button
                variant="outline-transparent"
                onClick={() => setOpenModal('additionalInformation')}
                style={{ marginTop: '15px' }}
              >
                Infos. complémentaires
              </Button>
              <Button
                variant="outline-danger"
                onClick={() => setOpenModal('reject')}
                style={{ marginTop: '15px' }}
              >
                Refuser le dossier
              </Button>
            </Box>
          </>
        )}
      </Box>
      {showCandidacyActions && (
        <>
          <RejectCandidacy
            candidacy={{ id: candidacy.id, patientName: candidacy.patientName }}
            onClose={() => setOpenModal(undefined)}
            onSuccess={() => history.goBack()}
            opened={openModal === 'reject'}
          />
          <RequestAdditionalInformation
            candidacy={{ id: candidacy.id, patientName: candidacy.patientName }}
            onClose={() => setOpenModal(undefined)}
            opened={openModal === 'additionalInformation'}
          />
          <Accept
            candidacy={{ id: candidacy.id, patientName: candidacy.patientName }}
            onClose={() => setOpenModal(undefined)}
            onSuccess={() => history.goBack()}
            opened={openModal === 'accept'}
            selectedInstitutionId={auth.selectedInstitutionId}
          />
        </>
      )}
    </Box>
  )
}
