import React, { useState } from 'react'
import styled from 'styled-components'
import { useFormContext } from 'react-hook-form'
import { get, map } from 'lodash'

import Box from 'ui/box'
import Button from 'ui/button'
import Text from 'ui/typography/text'
import Title from 'ui/typography/title'
import Input from 'ui/form/input'

import { FormBody } from 'components/pages/form'

import { Form } from 'types/form'
import Medical from 'types/medical'

export const VACCINATION_FORM: Form = [
  [
    {
      name: 'notebook',
      type: 'file',
      label: 'Carnet de vaccination',
      placeholder: '',
      required: false,
    },
    null,
  ],
]

const VACCINE_FORM: Form = [
  [
    {
      name: 'kind',
      type: 'text',
      label: 'Type de vaccin',
      placeholder: 'Saisissez le type de vaccin',
      required: true,
    },
    {
      name: 'recallDate',
      type: 'date',
      label: 'Date dernier rappel',
      placeholder: '',
      required: true,
    },
  ],
  [
    {
      name: '_destroy',
      type: 'hidden',
      label: '',
      placeholder: '',
      required: false,
    },
  ],
]

const Wrapper = styled(Box).attrs({ f: 'initial', withBorder: true, br: 4 })`
  border-color: ${props => props.theme.greyLightN2};
  padding: 24px;
  margin-bottom: 32px;
`

export default function VaccinationForm({ resource }: { resource: Medical }) {
  const defaultValues = resource['vaccination'] || {}
  const { register, setValue, getValues, trigger } = useFormContext<Medical['vaccination']>()
  const [state, setState] = useState<{
    openCreateForm: boolean
    openEditForms: { [key: number]: boolean }
    vaccines: Medical['vaccination']['vaccinesAttributes']
  }>({
    openCreateForm: false,
    openEditForms: {},
    vaccines: get(defaultValues, 'vaccinesAttributes', []),
  })

  return (
    <>
      <Input id="id" name="vaccination.id" type="hidden" ref={register()} />
      <Title bold fs={22} spacingBottom="24px">
        Informations relatives aux vaccinations
      </Title>

      <Box f="initial" fd="c" style={{ marginBottom: '32px' }}>
        <FormBody<Medical> fields={VACCINATION_FORM} subForm="vaccination" resource={resource} />
        <Box f="initial" jc="sb" style={{ marginTop: '15px' }}>
          <Text fs={16} bold>
            Vaccinations
          </Text>
          <Button
            variant="outline"
            size="small"
            onClick={() => setState({ ...state, openCreateForm: true })}
          >
            Ajoutez un vaccin
          </Button>
        </Box>
      </Box>

      {state.openCreateForm && (
        <Wrapper>
          <Box fd="c">
            <FormBody<Medical>
              fields={VACCINE_FORM}
              subForm="newVaccine"
              resource={resource}
              buttons={{
                onCancel: () => setState({ ...state, openCreateForm: false }),
                onValidating: async () => {
                  const newVaccines = get(getValues(), 'newVaccine')
                  if (
                    (await trigger(
                      VACCINE_FORM.flat()
                        .filter(f => !!f)
                        .map(f => `newVaccine.${f?.name}`),
                    )) &&
                    newVaccines !== undefined
                  ) {
                    setState({
                      ...state,
                      openCreateForm: false,
                      vaccines: [...state.vaccines],
                    })
                    setValue('vaccination.vaccinesAttributes', [...state.vaccines, newVaccines])

                    return
                  }
                },
              }}
            />
          </Box>
        </Wrapper>
      )}

      {map(state.vaccines, (vaccine, index: number) => {
        const editable = state.openEditForms[index] || false

        return (
          <Wrapper
            key={`vaccination-${vaccine.id || index}`}
            style={{ display: vaccine._destroy ? 'none' : 'flex' }}
          >
            <Box fd="c" style={{ display: editable ? 'flex' : 'none' }}>
              <FormBody<Medical>
                fields={VACCINE_FORM}
                subForm={`vaccination.vaccinesAttributes[${index}]`}
                resource={resource}
                buttons={{
                  onCancel: () =>
                    setState({
                      ...state,
                      openEditForms: { ...state.openEditForms, [index]: false },
                    }),
                  onValidating: async () => {
                    const newVaccines = get(getValues(), 'vaccination.vaccinesAttributes')
                    if (
                      (await trigger(
                        VACCINE_FORM.flat()
                          .filter(f => !!f)
                          .map(f => `vaccination.vaccinesAttributes[${index}].${f?.name}`),
                      )) &&
                      newVaccines !== undefined
                    ) {
                      setState({
                        ...state,
                        openEditForms: { ...state.openEditForms, [index]: false },
                        vaccines: newVaccines,
                      })

                      return
                    }
                  },
                }}
              />
            </Box>
            <Box style={{ display: !editable ? 'flex' : 'none' }}>
              <Box fd="c">
                <Text fs={18} bold>
                  {vaccine.kind}
                </Text>
                <Text fs={16} color="greyLightN2">
                  {new Date(vaccine.recallDate).toLocaleDateString()}
                </Text>
              </Box>
              <Box f="initial" ai="c">
                <Button
                  variant="danger"
                  size="small"
                  style={{ marginRight: '8px' }}
                  onClick={() => {
                    const newVaccines = get(getValues(), 'vaccination.vaccinesAttributes')
                    setValue(`vaccination.vaccinesAttributes[${index}]._destroy`, true)
                    if (newVaccines !== undefined) {
                      setState({
                        ...state,
                        vaccines: newVaccines,
                      })
                    }
                  }}
                >
                  Supprimer
                </Button>
                <Button
                  variant="outline"
                  size="small"
                  onClick={() =>
                    setState({
                      ...state,
                      openEditForms: { ...state.openEditForms, [index]: true },
                    })
                  }
                >
                  Éditer
                </Button>
              </Box>
            </Box>
          </Wrapper>
        )
      })}
    </>
  )
}
