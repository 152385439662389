import React, { useEffect } from 'react'

import Box from 'ui/box'
import Button from 'ui/button'
import Icon from 'ui/icon'
import Link from 'ui/typography/link'
import Title from 'ui/typography/title'

import { success } from 'assets/illustrations'
import { useHistory } from 'react-router-dom'
import Medical from 'types/medical'

export default function Conclusion({
  nextStep,
  firstStep,
  resource,
}: {
  nextStep: string
  firstStep: string
  resource: Medical
}) {
  const history = useHistory()

  useEffect(() => {
    if (resource.percentProgress !== 100) {
      return history.push(firstStep)
    }
  }, [])

  return (
    <Box fd="c">
      <Box jc="c">
        <img src={success} style={{ width: '300px' }} alt="succés" />
      </Box>
      <Box fd="c" ai="c" padding="0 32px 32px 32px">
        <Title bold spacingBottom="32px" align="center">
          Félicitations ! Le dossier médical est complet.
        </Title>
        <Button
          variant="primary"
          onClick={() => history.push(nextStep)}
          style={{ marginBottom: '12px' }}
        >
          Signer le dossier médical
          <Icon name="arrow" size={20} containerStyle={{ marginLeft: '8px' }} />
        </Button>
        <Link underline onClick={() => history.push(firstStep)}>
          Vérifier mes réponses
        </Link>
      </Box>
    </Box>
  )
}
