import request from './request'
import AdmissionRecord, { AdmissionRecordProgress } from 'types/admissionRecord'
import { AxiosResponse } from 'axios'
import { Auth } from 'types/user'

export function getAdmissionFormProgress(id: string | number) {
  return request
    .get<any, AxiosResponse<AdmissionRecordProgress>>(`/admission_records/${id}`)
    .then(res => res.data)
}

export function getAdmissionRecord(id: string) {
  return request
    .get<any, AxiosResponse<{ admissionRecord: AdmissionRecord }>>(`/admission_records/${id}/all`)
    .then(res => res.data.admissionRecord)
}

export function postAdmissionRecord(data: any) {
  return request
    .post<
      any,
      AxiosResponse<{ id: string; admissionRecords: Auth['admissionRecords'] }>
    >(`/admission_records`, data)
    .then(res => res.data)
}

export function transferAdmissionRecord(id: string, data: { email: string }) {
  return request.post(`/admission_records/${id}/transfert`, data)
}
