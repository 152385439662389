import React, { useContext } from 'react'
import { get, isEmpty } from 'lodash'
import { Controller, useFormContext } from 'react-hook-form'

import Box from 'ui/box'
import Button from 'ui/button'
import Icon from 'ui/icon'
import Text from 'ui/typography/text'
import Title from 'ui/typography/title'
import InputPhone from 'ui/form/inputPhone'

import { success } from 'assets/illustrations'

import { AuthContext } from 'App'
import { sendCode } from 'api/otp'
import { useToasts } from 'hooks/useToaster'

export default function Phone({ onNext }: { onNext: () => void }) {
  const { auth } = useContext(AuthContext)
  const { addToast, addGenericErrorToast } = useToasts()
  const { control, watch, handleSubmit, errors } = useFormContext<{ phone: string }>()

  const onSubmit = (data: { phone: string }) => {
    return sendCode(get(auth, 'selectedId', '0'), data.phone)
      .then(() => {
        addToast({
          title: 'Succès !',
          type: 'success',
          message: 'Le code de vérification a bien été envoyé',
        })

        return onNext()
      })
      .catch(e => {
        if (!isEmpty(e?.response?.data)) {
          addToast({
            title: 'Erreur !',
            type: 'error',
            message: e.response.data,
          })
        } else {
          addGenericErrorToast()
        }
      })
  }

  return (
    <Box fd="c" ai="c" bg={'greenLight2'} padding="32px">
      <Box jc="c" style={{ marginBottom: '32px' }}>
        <img src={success} alt="succés" />
      </Box>
      <Box fd="c">
        <Title bold spacingBottom="32px">
          Afin de valider définitivement le dossier, vous devez :
        </Title>
        <Box padding="0 0 24px 0" f="0 1 auto">
          <Box
            ai="c"
            jc="c"
            bg="white"
            br={50}
            width="42px"
            height="42px"
            withBorder
            style={{ marginRight: '16px' }}
          >
            <Text>1</Text>
          </Box>
          <Box fd="c">
            <Text bold>Renseignez votre numéro de téléphone portable</Text>
            <Text>
              Nous allons vous envoyer un code de vérification qui vous permet de signer le
              formulaire en toute sécurité.
            </Text>
            <Controller
              as={InputPhone}
              id="phone"
              name="phone"
              control={control}
              placeholder="Numéro de téléphone"
              style={{ maxWidth: '70%', marginTop: '12px' }}
              rules={{ required: true, minLength: 12, maxLength: 12 }}
            />
            {!isEmpty(errors.phone) && (
              <Text fs={16} color="pink">
                {errors.phone.type === 'required'
                  ? 'Ce champ est requis'
                  : 'Numéro de téléphone invalide'}
              </Text>
            )}
          </Box>
        </Box>
        <Box padding="0 0 24px 0" f="0 1 auto">
          <Box
            ai="c"
            jc="c"
            bg="white"
            br={50}
            width="42px"
            height="42px"
            withBorder
            style={{ marginRight: '16px' }}
          >
            <Text>2</Text>
          </Box>
          <Box fd="c">
            <Text bold>Insérez le code reçu afin de signer le formulaire</Text>
            <Text>
              Une fois cette étape passée, votre patient recevra un e-mail et il pourra continuer
              son processus d'admission.
            </Text>
          </Box>
        </Box>
      </Box>
      <Button
        variant="primary"
        disabled={!watch('phone')}
        onClick={handleSubmit(onSubmit)}
        style={{ marginTop: '20px', width: '100%' }}
      >
        Envoyer le code de vérification
        <Icon name="arrow" size={20} containerStyle={{ marginLeft: '8px' }} />
      </Button>
    </Box>
  )
}
