import React, { useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form'

import Modal from 'ui/modal'
import Box from 'ui/box'
import Button from 'ui/button'
import Loader from 'ui/loader'
import Text from 'ui/typography/text'
import { Auth } from 'types/user'
import { FormBody } from 'components/pages/form'
import { transferAdmissionRecord } from 'api/admissionRecord'
import { useToasts } from 'hooks/useToaster'

export default function TransferAdmissionRecord({
  opened,
  onClose,
  onSuccess,
  admissionRecord,
}: {
  admissionRecord: Auth['admissionRecords'][number]
  opened: boolean
  onSuccess: () => void
  onClose: () => void
}) {
  const { addToast, addGenericErrorToast } = useToasts()
  const methods = useForm<{ email: string }>()
  const [loading, setLoader] = useState(false)

  const onSubmit = (data: { email: string }) => {
    setLoader(true)

    transferAdmissionRecord(admissionRecord.id, data)
      .then(() => setLoader(false))
      .then(() =>
        addToast({
          title: 'Succès !',
          type: 'success',
          message: 'Demande envoyée !',
        }),
      )
      .then(onSuccess)
      .catch(() => {
        addGenericErrorToast()
        setLoader(false)
      })
  }

  return (
    <Modal
      onClose={onClose}
      opened={opened}
      title={`Transfert du dossier de ${
        admissionRecord?.firstName || ''
      } ${admissionRecord?.lastName || ''}`}
    >
      <FormProvider {...methods}>
        <Box fd="c" as="form" autoComplete="off" onSubmit={methods.handleSubmit(onSubmit)}>
          <Box ai="c" br={4} bg="greyLightN" padding="24px" style={{ marginBottom: '32px' }}>
            <Text fs={16}>
              {`Renseignez ici l’adresse e-mail de la personne à qui vous souhaitez transmettre le dossier de ${
                admissionRecord?.firstName || ''
              } ${admissionRecord?.lastName || ''}.`}
            </Text>
          </Box>
          <FormBody<{ email: string }>
            fields={[
              [
                {
                  name: 'email',
                  type: 'text',
                  label: 'Adresse e-mail du nouveau titulaire de dossier',
                  placeholder: 'Saisissez l’adresse e-mail',
                  required: true,
                },
              ],
            ]}
            subForm=""
            hasId={false}
          />
          <Box jc="fe" padding="16px 0 0 0">
            <Button type="submit">
              {loading ? <Loader size={12} /> : <>Transférer le dossier</>}
            </Button>
          </Box>
        </Box>
      </FormProvider>
    </Modal>
  )
}
