import { Form } from 'types/form'
import { SEXUALITY_BEHAVIOR_LIST } from 'types/educative'

const SEXUALITY_BEHAVIOR_FORM: Form = [
  [
    {
      name: "Essayez autant que possible d'être précis, nous souhaitons proposer une solution d'accueil qui correspond aux besoins de chaque usager.",
      type: 'info',
      description:
        "Il n'y a pas de mauvaise réponse; soyez transparent. Des réponses claires et honnêtes facilitent grandement le travail pour trouver une solution sur mesure.",
    },
  ],
  [
    {
      name: 'list',
      type: 'select',
      label: 'Comportements sexuels inadéquats',
      placeholder: 'Sélectionner les types de comportement sexuels inadéquats',
      options: [
        {
          label: 'Aucun',
          value: SEXUALITY_BEHAVIOR_LIST.NONE,
        },
        { label: 'Utilise un langage obscène', value: SEXUALITY_BEHAVIOR_LIST.LANGUAGE },
        { label: 'Se masturbe en public', value: SEXUALITY_BEHAVIOR_LIST.MASTURBATE },
        {
          label: 'Tente ou fait des attouchements à caractère sexuel à d’autres personnes',
          value: SEXUALITY_BEHAVIOR_LIST.ATTEMPT_TOUCHING,
        },
        {
          label: 'Accepte les avances sexuelles, sans égard au partenaire ou à la situation',
          value: SEXUALITY_BEHAVIOR_LIST.ACCEPT_ANY_SEX,
        },
        {
          label: 'Tente d’avoir ou à des relations sexuelles avec des partenaires non consentants',
          value: SEXUALITY_BEHAVIOR_LIST.RAPE_OR_TENTATIVE,
        },
      ],
      required: true,
      multi: true,
    },
  ],
  [
    {
      name: 'precision',
      type: 'textarea',
      label: 'Précisions',
      placeholder: '',
      required: false,
    },
  ],
]

export default SEXUALITY_BEHAVIOR_FORM
