import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useFormContext } from 'react-hook-form'
import { get, map } from 'lodash'

import Box from 'ui/box'
import Button from 'ui/button'
import Text from 'ui/typography/text'
import Title from 'ui/typography/title'
import Input from 'ui/form/input'

import { FormBody } from 'components/pages/form'

import { Form } from 'types/form'
import Medical from 'types/medical'

const TREATMENT_FORM: Form = [
  [
    {
      name: 'ald',
      type: 'radio',
      label: 'Médicaments ALD',
      options: [
        {
          label: 'Oui',
          value: 'true',
        },
        { label: 'Non', value: 'false' },
      ],
      required: true,
    },
    null,
  ],
  [
    {
      name: 'name',
      type: 'text',
      label: 'Nom du médicament',
      placeholder: 'Saisissez le nom du médicament',
      required: true,
    },
    {
      name: 'dose',
      type: 'text',
      label: 'Dosage du médicament',
      placeholder: 'Saisissez le dosage du médicament',
      required: true,
    },
  ],
  [
    {
      name: 'posology',
      type: 'textarea',
      label: 'Posologie',
      placeholder: 'Saisissez la posologie',
      required: true,
    },
  ],
  [
    {
      name: '_destroy',
      type: 'hidden',
      label: '',
      placeholder: '',
      required: false,
    },
  ],
]

const Wrapper = styled(Box).attrs({ f: 'initial', withBorder: true, br: 4 })`
  border-color: ${props => props.theme.greyLightN2};
  padding: 24px;
  margin-bottom: 32px;
`
type FormState = {
  openCreateForm: boolean
  openEditForms: { [key: number]: boolean }
  treatments: Medical['treatment']['medicinesAttributes']
}

export default function TreatmentForm({ resource }: { resource: Medical }) {
  const defaultValues = resource['treatment'] || {}
  const { register, setValue, getValues, trigger } = useFormContext<Medical['treatment']>()

  const [state, setState] = useState<FormState>({
    openCreateForm: false,
    openEditForms: {},
    treatments: get(defaultValues, 'medicinesAttributes', []),
  })

  useEffect(() => {
    setValue('treatment', { ...defaultValues })
  }, [defaultValues])

  return (
    <>
      <Input id="id" name="treatment.id" type="hidden" ref={register()} />
      <Title bold fs={22} spacingBottom="24px">
        Informations relatives au traitement actuel
      </Title>

      <Box f="initial" jc="sb" style={{ marginBottom: '32px' }}>
        <Text fs={16} bold>
          Traitement actuel
        </Text>
        <Button
          variant="outline"
          size="small"
          onClick={() => setState({ ...state, openCreateForm: true })}
        >
          Ajoutez un médicament
        </Button>
      </Box>

      {state.openCreateForm && (
        <Wrapper>
          <Box fd="c">
            <FormBody<Medical>
              fields={TREATMENT_FORM}
              subForm="newTreatment"
              resource={resource}
              buttons={{
                onCancel: () => setState({ ...state, openCreateForm: false }),
                onValidating: async () => {
                  const newTreatments = get(getValues(), 'newTreatment')
                  if (
                    (await trigger(
                      TREATMENT_FORM.flat()
                        .filter(f => !!f)
                        .map(f => `newTreatment.${f?.name}`),
                    )) &&
                    newTreatments !== undefined
                  ) {
                    setState({
                      ...state,
                      openCreateForm: false,
                      treatments: [...state.treatments, newTreatments],
                    })
                    setValue('treatment.medicinesAttributes', [
                      ...state.treatments,
                      get(getValues(), 'newTreatment'),
                    ])

                    return
                  }
                },
              }}
            />
          </Box>
        </Wrapper>
      )}

      {map(state.treatments, (treatment, index: number) => {
        const editable = state.openEditForms[index] || false

        return (
          <Wrapper
            key={`treatment-${treatment.id || index}`}
            style={{ display: treatment._destroy ? 'none' : 'flex' }}
          >
            <Box fd="c" style={{ display: editable ? 'flex' : 'none' }}>
              <FormBody<Medical>
                fields={TREATMENT_FORM}
                subForm={`treatment.medicinesAttributes[${index}]`}
                resource={resource}
                buttons={{
                  onCancel: () =>
                    setState({
                      ...state,
                      openEditForms: { ...state.openEditForms, [index]: false },
                    }),
                  onValidating: async () => {
                    const newTreatments = get(getValues(), 'treatment.medicinesAttributes')
                    if (
                      (await trigger(
                        TREATMENT_FORM.flat()
                          .filter(f => !!f)
                          .map(f => `treatment.medicinesAttributes[${index}].${f?.name}`),
                      )) &&
                      newTreatments !== undefined
                    ) {
                      setState({
                        ...state,
                        openEditForms: { ...state.openEditForms, [index]: false },
                        treatments: newTreatments,
                      })

                      return
                    }
                  },
                }}
              />
            </Box>
            <Box style={{ display: !editable ? 'flex' : 'none' }}>
              <Box fd="c">
                <Text fs={18} bold>
                  {treatment.name} - {treatment.dose} (
                  {treatment.ald === 'true' ? 'ALD' : 'Non ALD'})
                </Text>
                <Text fs={16} color="greyLightN2">
                  {treatment.posology}
                </Text>
              </Box>
              <Box f="initial" ai="c">
                <Button
                  variant="danger"
                  size="small"
                  style={{ marginRight: '8px' }}
                  onClick={() => {
                    const newTreatments = get(getValues(), 'treatment.medicinesAttributes')
                    if (newTreatments !== undefined) {
                      setValue(`treatment.medicinesAttributes[${index}]._destroy`, true)

                      setState({
                        ...state,
                        treatments: newTreatments,
                      })
                    }
                  }}
                >
                  Supprimer
                </Button>
                <Button
                  variant="outline"
                  size="small"
                  onClick={() =>
                    setState({
                      ...state,
                      openEditForms: { ...state.openEditForms, [index]: true },
                    })
                  }
                >
                  Éditer
                </Button>
              </Box>
            </Box>
          </Wrapper>
        )
      })}
    </>
  )
}
