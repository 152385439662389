import React, { useState } from 'react'
import styled from 'styled-components'
import { get } from 'lodash'

import Icon from 'ui/icon'
import Text from 'ui/typography/text'
import Link from 'ui/typography/link'
import Box from 'ui/box'

const Label = styled.label<{ state: 'empty' | 'fill'; hasError?: boolean; disabled?: boolean }>`
  position: relative;
  font-family: Nunito;
  font-size: 16px;
  line-height: 26px;
  color: ${props => (props.hasError ? props.theme.pink : props.theme.black)};
  border: ${props =>
    props.state === 'empty'
      ? `1px dashed ${props.hasError ? props.theme.pink : props.theme.greyLightN2}`
      : `1px solid ${props.hasError ? props.theme.pink : props.theme.black}`};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  border-radius: 2px;
  box-sizing: border-box;
  min-height: 120px;
  opacity: ${props => (props.disabled ? 0.6 : 1)};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};

  input {
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  }
`

const FileContainer = styled(Box).attrs({ width: '100%', ai: 'c', jc: 'sb' })`
  ${Text} {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 220px;
  }
`

const Input = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  display: block;
`

export default function File({
  id,
  name,
  uploadedFile,
  disabled,
  resetFile,
  hasError,
  onChange,
  onCustomChange,
  isFakeInput,
}: {
  id: string
  name: string
  disabled?: boolean
  uploadedFile?: string
  getValue?: () => FileList
  onChange?: any
  onCustomChange?: any
  resetFile?: () => void
  isFakeInput?: boolean
  hasError?: boolean
} & Omit<React.ComponentProps<'input'>, 'ref'>) {
  const [filename, setFilename] = useState(uploadedFile?.split(/(\\|\/)/g).pop())

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (files && files.length > 0) {
      if (!isFakeInput) {
        setFilename(files[0].name)
        onChange?.(files)
      } else {
        onCustomChange?.(event)
      }
    }
  }

  return (
    <Label state={filename ? 'fill' : 'empty'} hasError={hasError} disabled={disabled}>
      <Input
        id={id}
        name={name}
        accept=".png, .jpeg, .jpg, .pdf"
        type="file"
        style={{ zIndex: filename ? -1 : 1 }}
        onChange={handleChange}
      />
      {!filename ? (
        <>
          <Icon name="upload" color="green" size={18} cp="0 0 11px 0" />
          <Text fs={16} color="greyDark2" as="div">
            Importez un fichier ou
          </Text>
          <Link fs={16} color="green" underline style={{ textAlign: 'center' }}>
            parcourir votre ordinateur
          </Link>
        </>
      ) : (
        <FileContainer>
          <Box ai="c">
            <Icon
              name="transfer"
              size={24}
              color="white"
              bg="green"
              cp="12px"
              br={4}
              containerStyle={{ marginRight: '16px' }}
            />
            <Text fs={16} color="black">
              {filename}
            </Text>
          </Box>
          <Icon
            name="close"
            color="pink"
            size={14}
            onClick={
              !disabled
                ? (event: { preventDefault: () => void }) => {
                    event.preventDefault()

                    if (disabled) return

                    resetFile?.()
                    setFilename('')
                  }
                : undefined
            }
          />
        </FileContainer>
      )}
    </Label>
  )
}
