import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { xorBy } from 'lodash'

import Box from 'ui/box'
import Intro from 'components/pages/intro'

import Step1 from './step1'
import Layout from '../layout'
import Step2 from './step2'

import { InstitutionCandidacy } from 'types/candidacy'
import Conclusion from '../conclusion'

export const InstitutionStepContext = React.createContext<{
  selectedInstitutions: InstitutionCandidacy[]
  setSelectedInstitutions: (ic: InstitutionCandidacy) => void
}>({ selectedInstitutions: [], setSelectedInstitutions: () => null })

export default function InstitutionStep() {
  const history = useHistory()
  const [selectedInstitutions, setSelectedInstitutions] = useState<InstitutionCandidacy[]>([])

  return (
    <InstitutionStepContext.Provider
      value={{
        selectedInstitutions: selectedInstitutions || [],
        setSelectedInstitutions: ic =>
          setSelectedInstitutions(xorBy(selectedInstitutions, [ic], 'candidacyId')),
      }}
    >
      <Layout
        key="institution-step"
        title="Lieux de vie compatibles"
        globalStep={1}
        steps={() => [
          {
            title: 'Introduction',
            Component: () => (
              <Box padding="32px">
                <Intro
                  onNext={() => history.push('/admission/lieux-de-vie/etape/2')}
                  sections={[
                    {
                      title: 'Postuler dans les lieux de vies compatibles',
                      text: 'Découvrez les lieux de vies proposés par la plateforme et postulez en un clic dans les établissements de votre choix.',
                    },
                    {
                      title: 'Récapitulatif des candidatures envoyées',
                      text: 'Avant d’envoyer toutes vos candidatures vérifiez si vous êtes sûr de vos choix.',
                    },
                  ]}
                />
              </Box>
            ),
          },
          {
            title: 'Découverte',
            Component: () => <Step1 />,
          },
          {
            title: 'Récapitulatif des candidatures ',
            Component: () => <Step2 />,
          },
          {
            title: 'Complet',
            Component: () => {
              const { selectedInstitutions } = useContext(InstitutionStepContext)

              return (
                <Conclusion
                  title={`Votre dosssier a bien été envoyé à ces ${selectedInstitutions.length} établissements !`}
                  text="Dès que les établissements auront donné leur avis sur votre dossier, ils vous recontactrons afin d’organiser une visite obligatoire."
                  buttonTitle="Aller à l’accueil"
                  onNext={() => history.push('/home')}
                />
              )
            },
          },
        ]}
      />
    </InstitutionStepContext.Provider>
  )
}
