import React, { useState } from 'react'
import styled from 'styled-components'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import SyntaxHighlighter from 'react-syntax-highlighter'

import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs'

import Box from 'ui/box'
import Icon from 'ui/icon'
import Text from 'ui/typography/text'
import theme from 'ui/theme'

interface Props {
  establishmentId?: string
}

const CodeSnippet = ({ establishmentId }: Props) => {
  const [isEditorOver, setIsEditorOver] = useState(false)
  const [isCopyButtonOver, setIsCopyButtonOver] = useState(false)
  const [isCodeCopied, setIsCodeCopied] = useState(false)

  const codeSnippet = ` 
<a href="https://app.sahanest.fr">
  Envoyer mon dossier d'admission
</a>
`
  const codeSnippetToCopy = `
<style>
  .btn-sahanest{
    text-decoration: none;
    background-color: #2F7067;
    color: white;
    font-size: 14px;
    padding: 8px 12px;
    border-radius: 4px;
  }
</style>

<a href="https://app.sahanest.fr/inscription?institution_id=${establishmentId}" class="btn-sahanest" target="_blank">
   Envoyer mon dossier d'admission
</a>
`

  return (
    <CodeEditorContainer
      onMouseEnter={() => setIsEditorOver(true)}
      onMouseLeave={() => setIsEditorOver(false)}
      isOver={isEditorOver}
    >
      <Text fs={12} color="white">
        Code HTML - CSS
      </Text>
      <CopyToClipboardContainer ai="c" jc="c" isOver={isEditorOver}>
        <CopyToClipboard text={`${codeSnippetToCopy}`}>
          <CopyContainer
            isCopyOver={isCopyButtonOver}
            onMouseEnter={() => setIsCopyButtonOver(true)}
            onMouseLeave={() => {
              setIsCopyButtonOver(false)
              setIsCodeCopied(false)
            }}
            onClick={() => setIsCodeCopied(true)}
          >
            <Icon
              name={!isCodeCopied ? 'copy' : 'check'}
              size={12}
              containerStyle={{ marginRight: '8px' }}
              color={isCopyButtonOver ? 'black' : 'white'}
            />
            <Text color={isCopyButtonOver ? 'black' : 'white'} fs={12}>
              {!isCodeCopied ? 'Copier le code' : 'Code copié'}
            </Text>
          </CopyContainer>
        </CopyToClipboard>
      </CopyToClipboardContainer>

      <SyntaxHighlighter
        customStyle={{ background: theme.codeEditor, padding: 0, margin: 0 }}
        language="HTML"
        style={atomOneDark}
      >
        {codeSnippet}
      </SyntaxHighlighter>
    </CodeEditorContainer>
  )
}
export default CodeSnippet

const CodeEditorContainer = styled.div<{ isOver?: boolean }>`
  background: ${theme.codeEditor};
  position: relative;
  padding: 12px;
  border-radius: 4px;
`

const CopyContainer = styled.div<{ isCopyOver?: boolean }>`
  border: solid 1px white;
  padding: 8px 12px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  background: ${({ isCopyOver }) => isCopyOver && 'white'};
  transition: background 0.5s ease-in-out;
`

const CopyToClipboardContainer = styled(Box)<{ isOver?: boolean }>`
  color: white;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  cursor: pointer;
  z-index: 0;
  background: rgba(61, 63, 71, 0.8);
  opacity: ${({ isOver }) => (isOver === true ? 1 : 0)};
  transition: opacity 0.5s ease-in-out;
`
