import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { get } from 'lodash'
import styled from 'styled-components'

import Box from 'ui/box'
import Title from 'ui/typography/title'
import Text from 'ui/typography/text'
import Navbar from 'ui/navbar'
import Group from 'ui/form/group'
import Label from 'ui/form/label'
import Input from 'ui/form/input'
import Button from 'ui/button'
import Icon from 'ui/icon'
import Checkbox from 'ui/form/checkbox'
import Link from 'ui/typography/link'
import { Footer } from 'components/pages/layout'

import Logo from 'components/logo'

import { Auth } from 'types/user'
import { signUpDoctor } from 'api/user'

export interface PayloadSignUpDoctor {
  passwordConfirmation: string
  password: string
}

const ErrorMessage = styled(Text).attrs({ fs: 16, color: 'pink' })<{
  hasError: boolean
}>`
  visibility: ${props => (props.hasError ? 'visible' : 'hidden')};
`

export default function SignUpDoctor({ onConnected }: { onConnected: (auth: Auth) => void }) {
  const history = useHistory()
  const invitationToken = new URLSearchParams(useLocation().search).get('invitation_token')
  const [failed, setFailed] = useState(false)
  const { register, handleSubmit, errors, watch } = useForm<{
    passwordConfirmation: string
    password: string
  }>()

  const onSubmit = (data: { passwordConfirmation: string; password: string }) => {
    signUpDoctor(data, invitationToken || '')
      .then(reply => onConnected(reply as unknown as Auth))
      .catch(() => setFailed(true))
  }

  return (
    <Box fd="r" style={{ width: '100%', minHeight: '100vh' }}>
      <Box
        style={{
          flex: '2',
        }}
        bg="green"
      >
        <Box padding="12%" fd="c">
          <Logo paddingBottom="30%" url="https://www.sahanest.fr/" />
          <Box fd="c">
            <Title bold fs={28} color="white">
              Bienvenue sur Sahanest, inscrivez-vous en quelques clics.
            </Title>
            <Text fs={16} color="white" style={{ paddingTop: '24px' }}>
              Une fois inscrit, vous pourrez consulter et compléter le dossier d’admission de votre
              patient.
            </Text>
          </Box>
        </Box>
      </Box>
      <Box style={{ flex: '4' }} bg="greyLight3">
        <Box fd="c">
          <Navbar
            yellowButtonLabel="Déjà un compte ?"
            onClick={() => history.push('connexion')}
            href={'/connexion'}
          />
          <Box padding="8% 12% 64px 12%" fd="c">
            <Title fs={28} bold spacingBottom="8px">
              Création du compte
            </Title>
            <Text>Inscrivez-vous rapidement sur Sahanest.</Text>
            <Box fd="c" as="form" onSubmit={handleSubmit(onSubmit)}>
              <Box gutter="negative">
                <Group
                  style={{
                    padding: '0px 15px',
                    margin: '40px 0px',
                    height: '100px',
                  }}
                >
                  <Label htmlFor="password" required style={{ paddingBottom: '16px' }}>
                    Mot de passe
                  </Label>
                  <Input
                    id="password"
                    name="password"
                    type="password"
                    placeholder="Saisissez votre mot de passe"
                    ref={register({
                      required: 'Ce champ est requis',
                      minLength: {
                        value: 8,
                        message: 'Le mot de passe doit contenir au moins 8 caractères',
                      },
                    })}
                  />
                  <ErrorMessage hasError={!!get(errors, 'password.message')}>
                    {get(errors, 'password.message')}
                  </ErrorMessage>
                </Group>
                <Group style={{ padding: '0px 15px', margin: '40px 0px', height: '100px' }}>
                  <Label htmlFor="passwordConfirmation" required style={{ paddingBottom: '16px' }}>
                    Confirmez votre mot de passe
                  </Label>
                  <Input
                    id="passwordConfirmation"
                    name="passwordConfirmation"
                    type="password"
                    placeholder="Re-saisissez votre mot de passe"
                    ref={register({
                      required: 'Ce champ est requis',
                      validate: value =>
                        value === watch('password') || 'Les mots de passe ne correspondent pas',
                    })}
                  />
                  <ErrorMessage hasError={!!get(errors, 'passwordConfirmation.message')}>
                    {get(errors, 'passwordConfirmation.message')}
                  </ErrorMessage>
                </Group>
              </Box>
              <Box style={{ marginTop: 20 }} ai="s" jc="fs">
                <Checkbox
                  id="cgu"
                  name="cgu"
                  value="true"
                  containerStyle={{ marginBottom: '18px' }}
                  forwardRef={register({ required: 'Ce champ est requis' })}
                >
                  J’accepte les{' '}
                  <Link
                    color="green"
                    underline
                    href="https://sahanest.fr/cgu/"
                    target="_blank"
                    style={{ display: 'inline' }}
                  >
                    conditions générales d’utilisation
                  </Link>{' '}
                  et déclare avoir pris connaissance de la{' '}
                  <Link
                    color="green"
                    underline
                    href="https://sahanest.fr/politique-donnees"
                    target="_blank"
                    style={{ display: 'inline' }}
                  >
                    politique de protection des données
                  </Link>
                </Checkbox>
              </Box>
              <Box fd="c" f="initial" ai="e">
                <Text
                  fs={16}
                  color="pink"
                  style={{ paddingBottom: '16px', visibility: failed ? 'visible' : 'hidden' }}
                >
                  Une erreur est survenue, veuillez réessayer.
                </Text>
                <Button type="submit" style={{ width: '200px' }} disabled={!watch('cgu')}>
                  Créer mon compte
                  <Icon name="arrow" size={20} containerStyle={{ marginLeft: '8px' }} />
                </Button>
              </Box>
            </Box>
          </Box>
          <Footer />
        </Box>
      </Box>
    </Box>
  )
}
