import React from 'react'
import styled from 'styled-components'
import { xor } from 'lodash'

import Icon from 'ui/icon'
import Group from 'ui/form/group'
import Label from 'ui/form/label'
import Text from 'ui/typography/text'
import Box from 'ui/box'

const Container = styled.div`
  display: flex;
  flex: 1;

  span {
    font-size: 12px;
    color: ${({ theme }) => theme.grey};
    margin-left: 2px;
  }
`

const SelectContainer = styled(Box)`
  flex-wrap: wrap;
  flex: 1;
  border: 1px solid ${({ theme }) => theme.black};
  border-radius: 2px;
  cursor: pointer;
`

const Option = styled.div<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  padding: 4px 6px;
  color: ${({ theme }) => theme.black};
  border: 1px solid ${({ theme }) => theme.black};
  margin: 0 8px 8px 0;
  border-radius: 2px;
  border: ${({ theme, isSelected }) => `1px solid ${isSelected ? theme.green : theme.greyLightN3}`};
  background: ${({ theme, isSelected }) => (isSelected ? theme.greyLightN3 : theme.white)};
`

export default function SelectMultiple({
  onChange,
  value,
  options,
  label,
  id,
  errorMessage,
  required = false,
}: {
  id: string
  name: string
  options: { value: string; label: string }[]
  onChange?: any
  value?: string[]
  label?: string
  errorMessage?: string
  required?: boolean
}) {
  return (
    <Container>
      <Group style={{ paddingBottom: '2%' }}>
        {label && (
          <Box>
            <Label htmlFor={id} style={{ paddingBottom: '16px' }} required={required}>
              {label}
              {value && value.length > 0 && <span>({value.length})</span>}
            </Label>
          </Box>
        )}
        <SelectContainer padding="16px">
          {options.map((option, index) => {
            const isSelected = value?.find((item: string) => item === option.value)

            return (
              <Option
                key={index}
                isSelected={!!isSelected || false}
                onClick={() => {
                  onChange(
                    value?.length === 1 && isSelected ? undefined : xor(value, [option.value]),
                  )
                }}
              >
                <Text fs={14} style={{ marginRight: '5px' }}>
                  {option.label}
                </Text>
                {isSelected && <Icon name="close" size={18} color="black" />}
              </Option>
            )
          })}
        </SelectContainer>
        <Text fs={16} color="pink" style={{ visibility: errorMessage ? 'visible' : 'hidden' }}>
          {errorMessage}
        </Text>
      </Group>
    </Container>
  )
}
