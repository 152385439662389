import React from 'react'
import { Control, Controller, FieldValues } from 'react-hook-form'

import Text from 'ui/typography/text'
import Group from 'ui/form/group'
import Label from 'ui/form/label'
import InputPhone from 'ui/form/inputPhone'
import { isEmpty } from 'lodash'

type Props = {
  placeholder: string
  label: string
  name: string
  error: any
  control: Control<FieldValues>
}

const PhoneNumberField = ({ control, name, label, placeholder, error }: Props) => {
  return (
    <Group style={{ padding: '0 0 2% 5%' }}>
      <Label htmlFor={name} required style={{ paddingBottom: '16px' }}>
        {label}
      </Label>
      <Controller
        as={InputPhone}
        id={name}
        name={name}
        control={control}
        placeholder={placeholder}
        rules={{ required: true, minLength: 12, maxLength: 12 }}
      />
      {!isEmpty(error) && (
        <Text fs={16} color="pink">
          {error?.type === 'required' ? 'Ce champ est requis' : 'Numéro de téléphone invalide'}
        </Text>
      )}
    </Group>
  )
}
export default PhoneNumberField
