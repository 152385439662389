import React from 'react'
import styled from 'styled-components'
import { get, reduce, map, keys, times } from 'lodash'

import Box from 'ui/box'
import Text from 'ui/typography/text'
import Separator from 'ui/separator'
import Title from 'ui/typography/title'

import { renderSerialize } from 'components/pages/helper'
import { flattenFields, firstNotUndefined } from './utils'
import { Form, Inputs } from 'types/form'
import Educative from 'types/educative'

import { PARENT_FORM, PERSON_FORM } from 'components/pages/patient/administrative/forms/family'
import { VACCINATION_FORM } from 'components/pages/doctor/medical/forms/vaccination'
import AdmissionRecord from 'types/admissionRecord'
import Medical from 'types/medical'
import { EDUCATIVE_FORM, MEDICAL_FORM, OWNER_FORM } from '../patient/document'

export const SummaryBox = styled(Box).attrs({ fd: 'c', ai: 's', jc: 'fs' })`
  background-color: ${props => props.theme.greyLightN};
  margin-top: 24px;
  padding: 24px;
`

export function Summary({
  formState,
  fields,
  searchKeys,
}: {
  formState: Partial<AdmissionRecord>
  fields: Form
  searchKeys: string[]
}) {
  return (
    <SummaryBox>
      {flattenFields(fields).map(f =>
        renderSerialize(
          f,
          firstNotUndefined(
            searchKeys.map(sK => `${sK}.${f.name}`),
            formState,
            f.type === 'checkbox' ? [] : '-',
          ),
        ),
      )}
    </SummaryBox>
  )
}

export function SummaryMultiple({
  formState,
  listOfFields,
  searchKeys,
}: {
  formState: Partial<AdmissionRecord>
  listOfFields: Form[]
  searchKeys: string[]
}) {
  const flattenedFields = reduce(
    listOfFields,
    (acc, fields, i) => ({
      ...acc,
      [searchKeys[i]]: flattenFields(fields),
    }),
    {} as { [key: string]: Inputs[] },
  )

  return (
    <SummaryBox>
      {searchKeys.map(sK =>
        map(get(flattenedFields, sK, []), f =>
          renderSerialize(
            f,
            firstNotUndefined([`${sK}.${f.name}`], formState, f.type === 'checkbox' ? [] : '-'),
          ),
        ),
      )}
    </SummaryBox>
  )
}

function SummaryList<T>({
  title,
  listOfElements,
  renderElement,
}: {
  title: string
  listOfElements: T[]
  renderElement: (el: T) => JSX.Element
}) {
  return (
    <SummaryBox>
      <Title fs={18}>{title} :</Title>
      <br />
      <Box fd="c">
        {listOfElements.length > 0 ? (
          map(listOfElements, el => renderElement(el))
        ) : (
          <Text fs={18} bold>
            Aucun
          </Text>
        )}
      </Box>
    </SummaryBox>
  )
}

export function SituationSummary({
  formState,
  searchKey,
}: {
  formState: Partial<AdmissionRecord>
  searchKey: string
}) {
  const listOfEstablishments = get(
    formState,
    `${searchKey}.establishmentsAttributes`,
    [],
  ) as Educative['situation']['establishmentsAttributes']

  return (
    <SummaryList
      title="Établissements fréquentés"
      listOfElements={listOfEstablishments}
      renderElement={establishment => (
        <Box key={establishment.id}>
          <Text fs={16} bold style={{ paddingLeft: '12px' }}>
            - {establishment.name}
          </Text>
          <Text fs={16} color="greyDark2">
            &nbsp;- Du {new Date(establishment.entrance).toLocaleDateString()}{' '}
            {establishment.exit
              ? `au ${new Date(establishment.exit).toLocaleDateString()}`
              : "à aujourd'hui"}
          </Text>
        </Box>
      )}
    />
  )
}

export function TreatmentSummary({
  formState,
  searchKey,
}: {
  formState: Partial<AdmissionRecord>
  searchKey: string
}) {
  const listOfTreatments = get(
    formState,
    `${searchKey}.medicinesAttributes`,
    [],
  ) as Medical['treatment']['medicinesAttributes']

  return (
    <SummaryList
      title="Traitement actuel"
      listOfElements={listOfTreatments}
      renderElement={treatment => (
        <Box key={treatment.id}>
          <Box>
            <Text fs={16} bold style={{ paddingLeft: '12px' }}>
              - {treatment.name} {treatment.dose} ({treatment.ald === 'true' ? 'ALD' : 'Non ALD'})
            </Text>
            <Text fs={16} color="greyDark2">
              &nbsp;- {treatment.posology}
            </Text>
          </Box>
        </Box>
      )}
    />
  )
}

export function VaccinationSummary({
  formState,
  searchKey,
}: {
  formState: Partial<AdmissionRecord>
  searchKey: string
}) {
  const listOfVaccines = get(
    formState,
    `${searchKey}.vaccinesAttributes`,
    [],
  ) as Medical['vaccination']['vaccinesAttributes']

  return (
    <Box fd="c">
      <Summary fields={VACCINATION_FORM} formState={formState} searchKeys={[searchKey]} />
      <SummaryList
        title="Vaccinations"
        listOfElements={listOfVaccines}
        renderElement={vaccine => (
          <Box key={vaccine.id}>
            <Box>
              <Text fs={16} bold style={{ paddingLeft: '12px' }}>
                - {vaccine.kind}
              </Text>
              <Text fs={16} color="greyDark2">
                &nbsp;- {new Date(vaccine.recallDate).toLocaleDateString()}
              </Text>
            </Box>
          </Box>
        )}
      />
    </Box>
  )
}

export function FamilySummary({
  formState,
  searchKey,
}: {
  formState: Partial<AdmissionRecord>
  searchKey: string
}) {
  const flattenedFieldsParent = flattenFields(PARENT_FORM)
  const flattenedFieldsPerson = flattenFields(PERSON_FORM)

  const familyMembers = {
    father: { title: 'Père', fields: flattenedFieldsParent },
    mother: { title: 'Mère', fields: flattenedFieldsParent },
    sibling: { title: 'Fratrie', fields: flattenedFieldsPerson },
    child: { title: 'Enfant(s)', fields: flattenedFieldsPerson },
  }

  return (
    <SummaryBox>
      {keys(familyMembers).map((fM, i) => {
        const nbreOfMembers = get(formState, `${searchKey}.membersAttributes.${fM}`, []).length

        return (
          <Box key={`${fM}-${i}`} fd="c" width="100%">
            {i > 0 && <Separator spacingTop={24} spacingBottom={24} opacity={0.2} />}
            <Title fs={18}>{get(familyMembers, `${fM}.title`)}</Title>
            {nbreOfMembers > 0 ? (
              times(nbreOfMembers, index => (
                <Box fd="c" key={`${fM}-${index}`}>
                  <br />
                  {map(get(familyMembers, `${fM}.fields`), f =>
                    renderSerialize(
                      // @ts-ignore
                      f,
                      firstNotUndefined(
                        // @ts-ignore
                        [`${searchKey}.membersAttributes.${fM}.[${index}].${f.name}`],
                        formState,
                        // @ts-ignore
                        f.type === 'checkbox' ? [] : '-',
                      ),
                    ),
                  )}
                </Box>
              ))
            ) : (
              <Text fs={18} bold>
                Aucun
              </Text>
            )}
          </Box>
        )
      })}
    </SummaryBox>
  )
}

export function DocumentSummary({
  formState,
  searchKey,
}: {
  formState: Partial<AdmissionRecord>
  searchKey: string
}) {
  const formsFields = {
    'document.owner': OWNER_FORM,
    'document.medical': MEDICAL_FORM,
    'document.educative': EDUCATIVE_FORM,
  }

  return (
    <SummaryBox>
      {map(get(formsFields, searchKey), f =>
        renderSerialize(
          {
            name: f.name,
            type: f.type || 'file',
            options: f.options,
            label: f.label,
            required: false,
          },
          firstNotUndefined(
            [`${searchKey}.${f.name}`],
            formState,
            f.type === 'checkbox' ? [] : '-',
          ),
        ),
      )}
    </SummaryBox>
  )
}
