import React, { useContext, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useForm, FormProvider, Controller } from 'react-hook-form'
import { AuthContext } from 'App'
import { useToasts } from 'hooks/useToaster'

import Box, { BoxWithHeader } from 'ui/box'
import Button, { BackButton } from 'ui/button'
import Title from 'ui/typography/title'
import Text from 'ui/typography/text'
import Icon, { IconNames } from 'ui/icon'
import InputTag from 'ui/form/inputTag'

import CodeSnippetBlock from './rightBottomBlocks/codeSnippetBlock'
import { FormBody } from 'components/pages/form'

import { Form } from 'types/form'
import { Institution } from 'types/user'

import {
  update,
  updateAnimation,
  updateCandidate,
  updateCaracteristics,
  updateNotification,
  updateSocialProject,
} from 'api/institution'

const Layout: React.FC<{
  title: string
  subTitle?: string
  subText: string
  ctaText?: string
  hideCTAIcon?: boolean
  help: {
    icon: keyof typeof IconNames
    title: string
    text: string
  }
  fields: Form
  subForm:
    | 'info'
    | 'contact'
    | 'notification'
    | 'inclusive_info'
    | 'inclusiveCandidateProcess'
    | 'inclusiveOrganization'
    | 'inclusiveCaracteristic'
    | 'inclusiveSocialProject'
}> = ({
  title,
  subText,
  help,
  fields,
  subForm,
  subTitle,
  ctaText = 'Publier les modifications',
  hideCTAIcon = false,
}) => {
  const history = useHistory()
  const methods = useForm()
  const { auth, updateInstitution, updateInstitutionNotification } = useContext(AuthContext)
  const { addToast, addGenericErrorToast } = useToasts()

  const selectedInstitution = useMemo(
    () => auth?.institution?.filter(({ id }) => id === auth.selectedInstitutionId)[0],
    [auth],
  )

  const institutionId = useMemo(() => auth.selectedInstitutionId, [auth.selectedInstitutionId])

  useEffect(() => {
    selectedInstitution?.notification &&
      methods.control.setValue(`notification.emails`, selectedInstitution?.notification.emails)
  }, [selectedInstitution])

  const onSubmit = async (data: any) => {
    try {
      let res: any
      switch (subForm) {
        case 'notification':
          res = await updateNotification(data, institutionId)
          updateInstitutionNotification(res, institutionId)
          break
        case 'info':
        case 'contact':
          res = await update(subForm, data, institutionId)
          updateInstitution({ [subForm]: res }, institutionId)
          break
        case 'inclusiveOrganization':
          res = await updateAnimation(
            data,
            selectedInstitution?.inclusiveOrganization?.id,
            institutionId,
          )
          updateInstitution({ [subForm]: res }, institutionId)
          break
        case 'inclusiveCandidateProcess':
          res = await updateCandidate(
            data,
            selectedInstitution?.inclusiveCandidateProcess?.id,
            institutionId,
          )
          updateInstitution({ [subForm]: res }, institutionId)
          break
        case 'inclusiveCaracteristic':
          res = await updateCaracteristics(
            data,
            selectedInstitution?.inclusiveCaracteristic?.id,
            institutionId,
          )
          updateInstitution({ [subForm]: res }, institutionId)
          break
        case 'inclusiveSocialProject':
          res = await updateSocialProject(
            data,
            selectedInstitution?.inclusiveSocialProject?.id,
            institutionId,
          )
          updateInstitution({ [subForm]: res }, institutionId)
          break
        default:
          break
      }
      addToast({
        title: 'Succès !',
        type: 'success',
        message: 'Les informations ont été enregistrées',
      })
      history.goBack()
    } catch (error) {
      addGenericErrorToast()
    }
  }

  return (
    <Box gutter="negative" style={{ maxHeight: '100%' }}>
      <FormProvider {...methods}>
        <BoxWithHeader
          as="form"
          autoComplete="off"
          onSubmit={methods.handleSubmit(onSubmit)}
          br={4}
          style={{
            margin: '0 15px',
            alignSelf: 'flex-start',
          }}
        >
          <Box
            jc="sb"
            style={{ minHeight: subTitle ? '112px' : '88px', height: 'auto', padding: '32px' }}
          >
            <Box fd="c" jc="c">
              <Box fd="r" ai="c">
                <BackButton onClick={history.goBack} />
                <Title fs={24} bold>
                  {title}
                </Title>
              </Box>
              {!!subTitle && (
                <Box style={{ marginLeft: '30px' }}>
                  <Text fs={16} color="black" opacity={0.5}>
                    {subTitle}
                  </Text>
                </Box>
              )}
            </Box>
            <Button variant="outline" type="submit" style={{ marginLeft: '12px' }}>
              {!hideCTAIcon && (
                <Box style={{ marginRight: '10px' }}>
                  <Icon name="save" size={20} />
                </Box>
              )}
              {ctaText}
            </Button>
          </Box>

          <Box fd="c" padding="32px 32px 0 32px">
            {!!subText && (
              <Text fs={16} color="black" opacity={0.5} spacingBottom="32px">
                {subText}
              </Text>
            )}
            <Box fd="c">
              <FormBody<Institution>
                fields={fields}
                subForm={subForm}
                resource={selectedInstitution || ({} as Institution)}
              />
            </Box>
          </Box>
        </BoxWithHeader>
      </FormProvider>
      <Box
        fd="c"
        bg="white"
        width="25%"
        br={4}
        withBorder
        padding="32px"
        style={{ margin: '0 15px', alignSelf: 'flex-start' }}
      >
        <Icon name={help.icon} color="green" size={32} containerStyle={{ marginBottom: '16px' }} />
        <Box ai="c">
          <Title fs={18} bold spacingBottom="16px">
            {help.title}
          </Title>
        </Box>
        <Text>{help.text}</Text>
        {subForm === 'notification' && (
          <>
            <Box style={{ marginTop: '24px' }}>
              <Controller
                as={InputTag}
                id={'emails'}
                name={`${subForm}.emails`}
                control={methods.control}
                placeholder="Ajoutez un ou plusieurs emails"
                onChange={(value: string[]) => value}
              />
            </Box>
          </>
        )}
        {subForm === 'info' && selectedInstitution?.info?.website && (
          <CodeSnippetBlock establishmentId={selectedInstitution?.id} />
        )}
      </Box>
    </Box>
  )
}

export default Layout
