import React from 'react'
import { useForm, Controller } from 'react-hook-form'
import { get } from 'lodash'

import Box from 'ui/box'
import Button from 'ui/button'
import Icon from 'ui/icon'
import Group from 'ui/form/group'
import Input from 'ui/form/input'
import Label from 'ui/form/label'
import Dropdown from 'ui/form/dropdown'
import Radio from 'ui/form/radio'
import Text from 'ui/typography/text'

import { URGENCY_LEVELS } from 'types/enums'

import StepLayout from './stepLayout'
import { ConcernedInfos } from '..'

const StepConcernedInfos: React.FC<{
  onNext: (data: ConcernedInfos) => void
  onPrevious: () => void
  defaultValues?: ConcernedInfos
}> = ({ onNext, onPrevious, defaultValues }) => {
  const { control, register, handleSubmit, errors } = useForm<ConcernedInfos>({
    defaultValues: defaultValues,
  })

  return (
    <StepLayout
      title="Informations concernant le protégé"
      subText="Nous avons besoin de ces informations pour vous accompagner."
    >
      <Box as="form" onSubmit={handleSubmit(onNext)} autoComplete="off" fd="c">
        <Box ai="s" f="0 1 auto">
          <Group style={{ paddingBottom: '2%' }}>
            <Label htmlFor="concernedAge" required style={{ paddingBottom: '16px' }}>
              Âge
            </Label>
            <Input
              id="concernedAge"
              name="concernedAge"
              type="number"
              placeholder="Saisissez l’âge"
              ref={register({
                required: 'Ce champ est requis',
                min: {
                  value: 18,
                  message: 'Le protégé doit avoir au moins 18 ans',
                },
              })}
            />
            <Text
              fs={16}
              color="pink"
              style={{ visibility: errors.concernedAge ? 'visible' : 'hidden' }}
            >
              {get(errors, 'concernedAge.message')}
            </Text>
          </Group>
          <Group style={{ padding: '0 0 2% 5%' }}>
            <Label htmlFor="urgencyLevel" required style={{ paddingBottom: '16px' }}>
              Niveau d’urgence de la requête
            </Label>
            <Controller
              as={Dropdown}
              id="urgencyLevel"
              name="urgencyLevel"
              placeholder="Sélectionnez le niveau d’urgence"
              options={[
                { value: URGENCY_LEVELS.HIGH, label: 'Fort' },
                { value: URGENCY_LEVELS.MEDIUM, label: 'Moyen' },
                { value: URGENCY_LEVELS.LOW, label: 'Faible' },
              ]}
              control={control}
              rules={{ required: 'Ce champ est requis' }}
            />
            <Text
              fs={16}
              color="pink"
              style={{ visibility: errors.urgencyLevel ? 'visible' : 'hidden' }}
            >
              {get(errors, 'urgencyLevel.message')}
            </Text>
          </Group>
        </Box>
        <Group>
          <Label htmlFor="alreadyInStructure" required style={{ paddingBottom: '16px' }}>
            Actuellement pris en charge dans une structure avec internat ?
          </Label>
          <Box f="0 1 auto">
            <Radio
              id="alreadyInStructure-true"
              name="alreadyInStructure"
              value="true"
              forwardRef={register({ required: 'Ce champ est requis' })}
            >
              Oui
            </Radio>
            <Radio
              id="alreadyInStructure-false"
              name="alreadyInStructure"
              value="false"
              forwardRef={register({ required: 'Ce champ est requis' })}
            >
              Non
            </Radio>
          </Box>
          <Text
            fs={16}
            color="pink"
            style={{ visibility: errors.alreadyInStructure ? 'visible' : 'hidden' }}
          >
            {get(errors, 'alreadyInStructure.message')}
          </Text>
        </Group>
        <Box jc="sb" f="0 1 auto" padding="24px 0 0 0">
          <Button variant="outline" style={{ backgroundColor: 'white' }} onClick={onPrevious}>
            <Icon name="arrow" size={20} rotate={180} containerStyle={{ marginRight: '8px' }} />
            Étape précédente
          </Button>
          <Button type="submit">
            Étape suivante
            <Icon name="arrow" size={20} containerStyle={{ marginLeft: '8px' }} />
          </Button>
        </Box>
      </Box>
    </StepLayout>
  )
}

export default StepConcernedInfos
