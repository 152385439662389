import { countries } from 'constants/country'

const phoneNumberRules = {
  minLength: { value: 12, message: 'Veuillez entrer un numéro de téléphone valide' },
  maxLength: { value: 12, message: 'Veuillez entrer un numéro de téléphone valide' },
}

export const sharedFields = {
  nameFields: [
    {
      name: 'firstName',
      type: 'text' as const,
      label: 'Prénom',
      placeholder: 'Saisissez le prénom',
      required: true,
    },
    {
      name: 'lastName',
      type: 'text' as const,
      label: 'Nom',
      placeholder: 'Saisissez le nom',
      required: true,
    },
  ],
  addressFields: [
    {
      name: 'street',
      type: 'text' as const,
      label: 'Adresse',
      placeholder: 'Saisissez l’adresse',
      required: true,
    },
    {
      name: 'streetComplement',
      type: 'text' as const,
      label: "Complément d'adresse",
      placeholder: "Saisissez le complément d'adresse",
      required: false,
    },
  ],
  locationFields: [
    {
      name: 'zipcode',
      type: 'text' as const,
      label: 'Code postal',
      placeholder: 'Saisissez le code postal',
      required: true,
    },
    {
      name: 'city',
      type: 'text' as const,
      label: 'Ville',
      placeholder: 'Saisissez la ville',
      required: true,
    },
  ],
  contactFields: [
    {
      name: 'phoneNumber',
      type: 'phone' as const,
      label: 'Téléphone',
      placeholder: 'Saisissez le numéro de téléphone',
      required: true,
      rules: {
        ...phoneNumberRules,
      },
    },
    {
      name: 'email',
      type: 'text' as const,
      label: 'E-mail',
      placeholder: "Saisissez l'e-mail",
      required: true,
    },
  ],
  countryField: [
    {
      name: 'country',
      type: 'select' as const,
      label: 'Pays',
      placeholder: 'Choisissez le pays',
      options: countries,
      required: true,
    },
    null,
  ],
}
