import React from 'react'

import Layout from '../../edit/blocks/layout'
import { Form } from 'types/form'
import { SOCIAL_PROJECT_GOAL_OPTIONS, SOCIAL_PROJECT_TYPE_OPTIONS } from 'constants/form'

const PROJECT_FORM: Form = [
  [
    {
      title: 'Fonctionnement du lieu de vie',
      name: 'document',
      type: 'file',
      label: 'Charte du lieu de vie - Formats acceptés .jpg, .png, .pdf',
      index: 0,
      required: false,
    },
  ],
  [
    {
      name: 'houseRules',
      type: 'textarea',
      label: 'Comment fonctionne la vie dans la maison ?',
      placeholder:
        'Décrivez quelles sont les grandes règles de la maison et les valeurs fondatrices du projet de vie sociale et partagée...',
      required: true,
    },
  ],
  [
    {
      name: 'houseEnvironment',
      type: 'textarea',
      label: 'Comment le lieu de vie s’intègre dans son environnement ?',
      placeholder:
        "Des bénévoles viennent ils de l’extérieur ? Quelles sont les actions mises en œuvre pour faire vivre la maison ? Quelles démarches entreprenez vous pour faire le lien avec la cité ? Vous pouvez citer les noms d’associations partenaires ou autres acteurs clefs de l'inclusion de votre maison....",
      required: true,
    },
  ],
  [
    {
      title: 'Projet et Objectif',
      name: 'project',
      type: 'checkbox',
      label: 'Comment qualifieriez vous le projet de vie de votre habitat ?',
      placeholder: 'Sélectionnez l’état d’avancement',
      required: true,
      vertical: true,
      options: SOCIAL_PROJECT_TYPE_OPTIONS,
    },
  ],
  [
    {
      name: 'objective',
      type: 'checkbox',
      label: 'Quelles sont les étapes clefs qui structurent votre projet de vie ?',
      placeholder: 'Sélectionnez l’état d’avancement',
      required: true,
      vertical: true,
      options: SOCIAL_PROJECT_GOAL_OPTIONS,
    },
  ],
]

export default function InclusiveSocialProject() {
  return (
    <Layout
      title="Projet de vie sociale et partagé"
      subTitle="Détaillez le projet de vie et les objectifs de votre lieu de vie"
      ctaText="Enregistrer et quitter"
      hideCTAIcon
      subText=""
      help={{
        icon: 'target',
        title: " Valorisez les valeurs et l'ADN de votre lieu de vie.",
        text: "Mettez en avant tout ce qui caractérise votre lieu de vie et sa raison d'être. Ces informations seront visibles des candidats afin qu'ils puissent savoir si votre projet correspond à ce qu'ils recherchent. Cela vous permettra aussi de qualifier les candidatures entrantes.",
      }}
      fields={PROJECT_FORM}
      subForm="inclusiveSocialProject"
    />
  )
}
