import React, { useState } from 'react'
import styled from 'styled-components'
import { get } from 'lodash'

import CardDoc from 'ui/card/doc'

const Label = styled.label`
  position: relative;
  font-family: Nunito;
  font-size: 16px;
  line-height: 26px;
  color: ${props => props.theme.black};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  box-sizing: border-box;
  flex: 1;
`

const Input = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 3;
`

export default function InlineFile({
  id,
  label,
  name,
  uploadedFile,
  hasError,
  required,
  missing,
  onChange,
}: {
  id: string
  name: string
  label: string
  uploadedFile?: string
  getValue?: () => FileList
  resetFile?: () => void
  hasError?: boolean
  missing?: boolean
  onChange?: any
} & Omit<React.ComponentProps<'input'>, 'ref'>) {
  const [filename, setFilename] = useState(uploadedFile?.split(/(\\|\/)/g).pop())

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (files && files.length > 0) {
      setFilename(files[0].name)
      onChange?.(files)
    }
  }

  return (
    <Label>
      <Input id={id} name={name} type="file" onChange={handleChange} />
      <CardDoc
        docName={label}
        filename={filename}
        hasError={hasError}
        missing={missing}
        required={required}
      />
    </Label>
  )
}
