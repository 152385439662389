import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { get, camelCase, find, findIndex } from 'lodash'
import { useHistory } from 'react-router-dom'

import Box, { BoxWithHeader } from 'ui/box'
import Title from 'ui/typography/title'
import Text from 'ui/typography/text'
import Link from 'ui/typography/link'
import Icon from 'ui/icon'
import Button from 'ui/button'
import ProgressBar, { variants } from 'ui/progressBar/horizontal'
import TransferAdmissionRecord from 'components/modals/transferAdmissionRecord'
import useResource from 'hooks/useResource'
import { usePageName } from 'hooks/usePageName'
import { getAdmissionFormProgress } from 'api/admissionRecord'
import { getAdmissionStatus } from 'api/admission'
import { AdmissionRecordProgress } from 'types/admissionRecord'
import { AdmissionStatus } from 'types/admission'
import { Auth } from 'types/user'
import { AuthContext } from 'App'
import { computeAdmissionProgressVariant } from 'constants/progressBar'

import desk from 'assets/illustrations/desk.svg'

const HeadBlock = styled(Box)`
  background-color: ${props => props.theme.greenLight2};
  border: 1px solid ${props => props.theme.purpleDarkTransparent};
  margin-bottom: 32px;
  padding: 32px;
  flex: 0 1 auto;

  ${Title} {
    padding-bottom: 16px;
  }

  ${Title} + ${Text} {
    padding-bottom: 42px;
  }
`

const CheckList = styled(Text).attrs({ fs: 16, color: 'black', as: 'div' })`
  display: flex;
  align-items: center;
  width: 50%;
  height: 26px;
  padding-bottom: 18px;
`

const Checkmark = styled(Icon).attrs<{
  name?: string
  size?: number
}>(() => ({
  name: 'check',
  size: 20,
  color: 'white',
  bg: 'green',
  br: 20,
  cp: '2px',
  containerStyle: { marginRight: '16px' },
}))``

const ADMISSION_RECORD_FORM_ORDER = [
  ['administrative.statusProgress', 'Renseignements administratifs & familiaux '],
  ['social.statusProgress', 'Renseignements sociaux'],
  ['educative.statusProgress', 'Renseignements éducatifs'],
  ['supportValidation.statusProgress', 'Validation du support'],
  ['financial.statusProgress', 'Renseignements financiers'],
  ['document.statusProgress', 'Documents à joindre'],
  ['medical.statusProgress', 'Renseignements médicaux'],
  ['form.statusProgress', 'Envoi du formulaire'],
]

const ADMISSION_FORM_ORDER = [
  { label: 'Lieux de vie compatibles', path: 'lieux-de-vie' },
  { label: 'Choix des lieux de vie', path: 'choix' },
  { label: 'Dossier MDPH et notification', path: 'mdph' },
  { label: 'Accord de prise en charge', path: 'accord' },
  { label: 'Demande financement', path: 'financement' },
  { label: 'Trousseau & Accueil', path: 'trousseau' },
]

export default function Home({ refreshUser }: { refreshUser: () => void }) {
  const { auth } = useContext(AuthContext)
  const history = useHistory()
  const { setPageName } = usePageName()
  const [{ resource, loading }] = useResource<
    {
      admissionRecordProgress: AdmissionRecordProgress
      admissionProgress: { admissionStatus: AdmissionStatus }
    },
    {}
  >(
    undefined,
    () =>
      auth.selectedId
        ? Promise.all([
            getAdmissionFormProgress(auth.selectedId),
            getAdmissionStatus(auth.selectedId),
          ]).then(([admissionRecordProgress, admissionProgress]) => ({
            admissionRecordProgress,
            admissionProgress,
          }))
        : Promise.resolve({
            admissionRecordProgress: {} as AdmissionRecordProgress,
            admissionProgress: {} as { admissionStatus: AdmissionStatus },
          }),

    [get(auth, 'selectedId', '0')],
  )

  const [showTransferModal, setShowTransferModal] = useState(false)
  const admissionRecord = find(auth.admissionRecords, ar => ar.id === auth.selectedId)

  const supportValidationProgress = get(
    resource,
    'admissionRecordProgress.supportValidation.statusProgress',
  )
  const formProgress = get(resource, 'admissionRecordProgress.form.statusProgress')
  const formStarted =
    get(resource, 'admissionRecordProgress.administrative.statusProgress', 'todo') !== 'todo' ||
    get(resource, 'admissionRecordProgress.social.statusProgress', 'todo') !== 'todo' ||
    get(resource, 'admissionRecordProgress.educative.statusProgress', 'todo') !== 'todo'
  const admissionRecordLockedFrom =
    supportValidationProgress === 'completed' ? (formProgress === 'disabled' ? 7 : undefined) : 3
  const indexOfDisabled = findIndex(
    computeAdmissionProgressVariant(get(resource, `admissionProgress.admissionStatus`)),
    v => v === 'disabled',
  )
  const admissionLockedFrom = indexOfDisabled !== -1 ? indexOfDisabled : undefined

  function mapResourceAdmissionRecord() {
    return ADMISSION_RECORD_FORM_ORDER.map(([path, label]) => ({
      label,
      variant: camelCase(
        get(resource, `admissionRecordProgress.${path}`, 'todo'),
      ) as keyof typeof variants,
    }))
  }
  function mapResourceAdmission() {
    const variants = computeAdmissionProgressVariant(
      get(resource, `admissionProgress.admissionStatus`),
    )

    return ADMISSION_FORM_ORDER.map(({ label }, i) => ({ label, variant: variants[i] }))
  }

  useEffect(() => {
    setPageName('Accueil')
  }, [])

  return (
    <Box fd="c">
      <HeadBlock br={4} style={{ display: 'block' }}>
        <Box ai="c">
          <Box fd="c">
            <Title fs={28} bold>
              Bienvenue sur votre espace personnel, <u>{get(auth, 'user.firstName')}</u> !
            </Title>
            <Text fs={16}>
              L’époque où vous complétiez de nombreux dossiers papiers est révolue; grâce à
              Sahanest, vous n’avez plus qu’un seul dossier à compléter pour lancer toutes vos
              demandes d’admission.
            </Text>

            <Box fd="r" style={{ flexWrap: 'wrap', flex: 'initial' }}>
              <CheckList>
                <Checkmark />
                Une aide personnalisée par téléphone ou en présentiel
              </CheckList>
              <CheckList>
                <Checkmark />
                Une vision claire sur l’avancement du dossier
              </CheckList>
              <CheckList>
                <Checkmark />
                Un processus transparent, rapide et efficace
              </CheckList>
              <CheckList>
                <Checkmark />
                Un seul dossier pour postuler
              </CheckList>
            </Box>
          </Box>
          <img
            alt="Illustration"
            src={desk}
            style={{ paddingLeft: '40px', width: '240px', height: '216px' }}
          />
        </Box>
      </HeadBlock>

      {auth.selectedId && [
        <BoxWithHeader
          key="box-progress-bar"
          br={4}
          f="0 1 auto"
          style={{ display: 'block', marginBottom: '32px' }}
        >
          <Box>
            <Title fs={24} bold>
              Formulaire d’admission
            </Title>
          </Box>

          <Box fd="c" padding="32px 32px 0 32px">
            <Title fs={18} bold as={'h2'} spacingBottom="32px">
              Les étapes du formulaire d’admission
            </Title>
            {loading ? (
              <></>
            ) : (
              <ProgressBar
                lockedFrom={admissionRecordLockedFrom}
                items={mapResourceAdmissionRecord()}
                style={{ marginBottom: '32px' }}
              />
            )}
            {!loading && formProgress !== 'completed' && (
              <Box fd="r" ai="c" jc="sb" bg="greenLight2" padding="24px" br={4}>
                <Text fs={16} style={{ paddingRight: '24px' }}>
                  Le formulaire d’admission est décomposé en 7 sections distinctes. Vous pouvez les
                  consulter dans l’entrée “Mon formulaire”.
                  <br />
                  Laissez-vous guider. Complétez le formulaire pas à pas.
                </Text>
                <Button onClick={() => history.push('/administrative/etape/1')}>
                  {formStarted
                    ? 'Continuer à compléter mon formulaire'
                    : 'Commencer mon formulaire'}
                </Button>
              </Box>
            )}
          </Box>
        </BoxWithHeader>,
        <BoxWithHeader
          key="box-progress-bar-admission"
          br={4}
          f="0 1 auto"
          style={{ display: 'block', marginBottom: '32px' }}
        >
          <Box>
            <Title fs={24} bold>
              Suivi de l’admission
            </Title>
          </Box>

          <Box fd="c" padding="32px 32px 0 32px">
            <Title fs={18} bold as={'h2'} spacingBottom="32px">
              Les étapes de l’admission
            </Title>
            {resource?.admissionProgress.admissionStatus === 'disabled' ? (
              <Box fd="r" ai="c" bg="greyLight" padding="24px" br={4}>
                <Icon
                  name="lock"
                  color="white"
                  size={14}
                  cp="12px"
                  bg="greyDark2"
                  br={50}
                  containerStyle={{ marginRight: '16px' }}
                />
                <Text fs={16} style={{ paddingRight: '24px' }}>
                  Pour dévérouiller la section admission vous devez avoir obtenu la validation du
                  support concernant le formulaire d’admission.
                </Text>
              </Box>
            ) : (
              <>
                {loading ? (
                  <></>
                ) : (
                  <ProgressBar
                    lockedFrom={admissionLockedFrom}
                    items={mapResourceAdmission()}
                    style={{ marginBottom: '32px' }}
                  />
                )}
                <Box fd="r" ai="c" jc="sb" bg="greenLight2" padding="24px" br={4}>
                  <Text fs={16} style={{ paddingRight: '24px' }}>
                    Le support à validé, vous pouvez débuter la section admission et découvrir les
                    lieux de vie compatibles avec votre dossier.
                  </Text>
                  <Button
                    onClick={() =>
                      history.push(
                        `/admission/${
                          ADMISSION_FORM_ORDER[
                            admissionLockedFrom
                              ? admissionLockedFrom - 1
                              : ADMISSION_FORM_ORDER.length - 1
                          ].path
                        }/etape/1`,
                      )
                    }
                  >
                    Se préparer avant l’accueil
                  </Button>
                </Box>
              </>
            )}
          </Box>
        </BoxWithHeader>,

        <BoxWithHeader key="box-transfert" br={4} f="0 1 auto" style={{ display: 'block' }}>
          <Box>
            <Title fs={24} bold>
              Transfert du dossier vers un autre tuteur
            </Title>
          </Box>

          <Box fd="c" padding="32px 32px 0 32px">
            <Box jc="sb" bg="greyLightN" padding="24px" br={4}>
              <Box>
                <Icon
                  name="transfer"
                  size={24}
                  cp="12px"
                  bg="white"
                  color="green"
                  br={4}
                  containerStyle={{ marginRight: '16px' }}
                />
                <Box fd="c">
                  <Text fs={16}>
                    {`Dossier de ${admissionRecord?.firstName || ''} ${
                      admissionRecord?.lastName || ''
                    }`}
                  </Text>
                  <Text fs={12}>Dossier transférable vers un autre tuteur</Text>
                </Box>
              </Box>
              <Link fs={16} color="green" underline bold onClick={() => setShowTransferModal(true)}>
                {`Transférer le dossier de ${
                  admissionRecord?.firstName || ''
                } ${admissionRecord?.lastName || ''}`}
              </Link>
            </Box>
          </Box>
        </BoxWithHeader>,
        <TransferAdmissionRecord
          key="modal-transfert"
          admissionRecord={admissionRecord as Auth['admissionRecords'][number]}
          onClose={() => setShowTransferModal(false)}
          onSuccess={() => {
            setShowTransferModal(false)
            refreshUser()
          }}
          opened={showTransferModal}
        />,
      ]}
    </Box>
  )
}
