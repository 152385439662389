import { Form } from 'types/form'

const AGGRESSIVENESS_FORM: Form = [
  [
    {
      name: "Essayez autant que possible d'être précis, nous souhaitons proposer une solution d'accueil qui correspond aux besoins de chaque usager.",
      type: 'info',
      description: `Il n'y a pas de mauvaise réponse; soyez transparent. Des réponses claires et honnêtes facilitent grandement le travail pour trouver une solution sur mesure. 
      0 correspondant à l’absence total de violence et 5 à un niveau très élevé.`,
    },
  ],
  [
    {
      name: 'isAgressive',
      type: 'radio',
      label: 'La personne présente-t-elle des comportements aggressifs ?',
      placeholder: '',
      options: [
        {
          label: 'Oui',
          value: 'true',
        },
        { label: 'Non', value: 'false' },
      ],
      required: true,
    },
  ],
  [
    {
      name: 'oneself',
      type: 'ranking',
      label: 'Auto-agressivité',
      placeholder: '',
      required: true,
      from: 0,
      to: 5,
    },
  ],
  [
    {
      name: 'hetero',
      type: 'ranking',
      label: 'Hétéro-agressivité',
      placeholder: '',
      required: true,
      from: 0,
      to: 5,
    },
  ],
  [
    {
      name: 'verbal',
      type: 'ranking',
      label: 'Agressivité verbale',
      placeholder: '',
      required: true,
      from: 0,
      to: 5,
    },
  ],
  [
    {
      name: 'environmental',
      type: 'ranking',
      label: 'Agressivité sur l’environnement',
      placeholder: '',
      required: true,
      from: 0,
      to: 5,
    },
  ],
  [
    {
      name: 'precision',
      type: 'textarea',
      label: 'Précisions',
      placeholder: '',
      required: false,
    },
  ],
]

export default AGGRESSIVENESS_FORM
