import React, { useState } from 'react'
import styled from 'styled-components'
import { map, get } from 'lodash'
import { useFormContext, useFieldArray, Controller } from 'react-hook-form'

import Box from 'ui/box'
import Text from 'ui/typography/text'
import Group from 'ui/form/group'
import Input from 'ui/form/input'
import Label from 'ui/form/label'
import Icon from 'ui/icon'
import Button from 'ui/button'
import Title from 'ui/typography/title'

const Panel = styled(Box).attrs({ fd: 'c', jc: 'fs' })<{ hasError?: boolean }>`
  background-color: white;
  padding: 26px 32px;
  border-radius: 4px;
  border: 1px solid ${props => (props.hasError ? props.theme.pink : props.theme.greyLightN2)};
  margin-bottom: 24px;
`

export const SECTIONS = [
  {
    name: 'shoesAndClothes',
    panelLabel: 'Vêtements & chaussures',
    inputInfos: {
      label: 'Type de vêtements',
      placeholder: 'ex : Chaussures de ville',
    },
  },
  {
    name: 'administrativeDocuments',
    panelLabel: 'Documents administratifs',
    inputInfos: {
      label: 'Type de documents',
      placeholder: "ex : Carte vitale, photo d'identité",
    },
  },
  {
    name: 'toiletKit',
    panelLabel: 'Trousse de toilette',
    inputInfos: {
      label: 'Accessoire de toilette',
      placeholder: 'ex : Brosse à dent',
    },
  },
  {
    name: 'medical',
    panelLabel: 'Médical',
    inputInfos: {
      label: 'Accessoire médical',
      placeholder: 'ex : Pansements',
    },
  },
] as {
  name: 'shoesAndClothes' | 'administrativeDocuments' | 'toiletKit' | 'medical'
  panelLabel: string
  inputInfos: { label: string; placeholder: string }
}[]

function RenderForm({
  displayed,
  inputInfos,
  subForm,
}: {
  displayed: boolean
  inputInfos: { label: string; placeholder: string }
  subForm: string
}) {
  const { control, errors } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    control,
    name: subForm,
  })

  return (
    <Box fd="c" padding="32px 0 0 0" style={{ display: displayed ? 'flex' : 'none' }}>
      {fields.map((item, index) => (
        <Box key={item.id} gutter="negative">
          <Group style={{ flex: '2', margin: '0 15px' }}>
            {index === 0 && (
              <Label
                htmlFor={`${subForm}[${index}].name`}
                required
                style={{ paddingBottom: '16px' }}
              >
                {inputInfos.label}
              </Label>
            )}
            <Controller
              as={Input}
              id={`${subForm}[${index}].name`}
              name={`${subForm}[${index}].name`}
              placeholder={inputInfos.placeholder}
              control={control}
              rules={{ required: true }}
              defaultValue={item.name}
            />
            <Text
              fs={16}
              color="pink"
              style={{
                visibility: get(errors, `${subForm}[${index}].name`) ? 'visible' : 'hidden',
              }}
            >
              Ce champ est requis
            </Text>
          </Group>
          <Group style={{ flex: '1', margin: '0 15px' }}>
            {index === 0 && (
              <Label
                htmlFor={`${subForm}[${index}].qte`}
                required
                style={{ paddingBottom: '16px' }}
              >
                Quantité
              </Label>
            )}
            <Box>
              <Controller
                as={Input}
                id={`${subForm}[${index}].qte`}
                name={`${subForm}[${index}].qte`}
                type="number"
                placeholder="1"
                control={control}
                rules={{ required: true }}
                defaultValue={item.qte}
              />
              <Button
                variant="transparent"
                onClick={() => remove(index)}
                style={{ marginLeft: '8px' }}
              >
                <Icon name="delete" size={20} color="pink" />
              </Button>
            </Box>
            <Text
              fs={16}
              color="pink"
              style={{
                visibility: get(errors, `${subForm}[${index}].qte`) ? 'visible' : 'hidden',
              }}
            >
              Ce champ est requis
            </Text>
          </Group>
        </Box>
      ))}
      <Button
        variant="transparent"
        onClick={() => append({ name: '', qte: '' })}
        style={{ alignSelf: 'start' }}
      >
        <Icon name="add" size={20} color="green" containerStyle={{ marginRight: '8px' }} />
        <Text color="green">Ajoutez une ligne</Text>
      </Button>
    </Box>
  )
}

const Step2: React.FC = () => {
  const [panels, setPanelState] = useState<{ [key: string]: boolean }>({})
  const { errors } = useFormContext()

  return (
    <Box fd="c" padding="32px" style={{ overflowY: 'scroll' }}>
      <Title fs={22} spacingBottom="32px">
        Dépliez une catégorie et ajoutez les élements nécessaires..
      </Title>
      {map(SECTIONS, ({ name, panelLabel, inputInfos }) => {
        const displayed = panels[name]

        return (
          <Panel key={name} hasError={errors[name]}>
            <Box jc="sb" ai="c">
              <Text fs={19}>{panelLabel}</Text>
              <Button
                variant="outline"
                onClick={() => setPanelState({ ...panels, [name]: !displayed })}
                size="small"
              >
                {displayed ? 'Masquer' : 'Déplier'}
              </Button>
            </Box>
            {RenderForm({
              subForm: name,
              inputInfos,
              displayed,
            })}
          </Panel>
        )
      })}
    </Box>
  )
}

export default Step2
