import React from 'react'
import { Control, FieldValues } from 'react-hook-form'

import Group from 'ui/form/group'
import Label from 'ui/form/label'

import PhoneNumberField from './phoneNumberField'
import Input from 'ui/form/input'

type Props = {
  errors: any
  control: Control<FieldValues>
  register: any
}

const ContactFields = ({ control, register, errors }: Props) => (
  <>
    <Group style={{ paddingBottom: '2%' }}>
      <Label htmlFor="website" style={{ paddingBottom: '16px' }}>
        Site internet
      </Label>
      <Input
        id="website"
        name="website"
        placeholder="Saisissez le site internet"
        ref={register({})}
      />
    </Group>
    <PhoneNumberField
      control={control}
      name="phoneNumber"
      label="Numéro de téléphone"
      placeholder="Numéro de téléphone de l'établissement"
      error={errors?.phoneNumber}
    />
  </>
)
export default ContactFields
