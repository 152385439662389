import React, { useReducer, Reducer, useEffect, useCallback, useContext, useMemo } from 'react'
import { useIntercom } from 'react-use-intercom'

import { ThemeProvider } from 'styled-components'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import { get, find, now } from 'lodash'
import LogRocket from 'logrocket'

import theme from 'ui/theme'
import Box from 'ui/box'
import Loader from 'ui/loader'

import Layout from 'components/pages/layout'
import Home from 'components/pages/patient/home'
import Profile from 'components/pages/patient/profile'
import PresInscription from 'components/pages/institution/preinscription'
import SignUp from 'components/pages/patient/signup'
import SignUpDoctor from 'components/pages/doctor/signup'
import SignUpInstitution from 'components/pages/institution/signup'
import SignIn from 'components/pages/signin'
import Form from 'components/pages/patient/form'
import Admission from 'components/pages/patient/admission'
import DoctorForm from 'components/pages/doctor/form'

import Candidacy from 'components/pages/institution/candidacy'
import AdmissionRecordDetails from 'components/pages/institution/admissionRecord/details'
import Institution from 'components/pages/institution/myInstitution'
import InstitutionEdit from 'components/pages/institution/myInstitution/edit'
import KitList from 'components/pages/institution/myInstitution/kit'
import KitForm from 'components/pages/institution/myInstitution/kit/form'
import AdmissionRecordForm from 'components/pages/institution/admissionRecord/form'
import ProfileInstitution from 'components/pages/institution/profile'
import AdmissionInstitution from 'components/pages/institution/admission'
import AdmissionDetails from 'components/pages/institution/admission/details'

import WelcomeTour from 'components/welcomeTour'
import CreateAdmissionRecord from 'components/modals/createAdmissionRecord'
import HelpPanel from 'components/helpPanel'

import { checkIfConnected } from 'api/user'
import { ToastsProvider } from 'hooks/useToaster'
import { PageNameProvider } from 'hooks/usePageName'
import User, {
  Auth,
  Institution as InstitutionType,
  Notification as NotificationType,
} from 'types/user'

import 'App.css'
import InclusiveInstitutionEdit from 'components/pages/institution/myInstitution/inclusiveEdit'

if (process.env.REACT_APP_ENABLE_LOG_ROCKET) {
  LogRocket.init('wisgop/sahanest')
}

export const AuthContext = React.createContext<{
  auth: Partial<Auth & { selectedId: string; selectedInstitutionId: string }>
  updateInstitution: (
    data: Partial<InstitutionType>,
    selectedInstitutionId: string | undefined,
  ) => void
  updateUser: (data: Partial<Auth['user']>) => void
  updateInstitutionNotification: (
    data: Partial<NotificationType>,
    selectedInstitutionId: string | undefined,
  ) => void
  addEstablishment: boolean | undefined
  updateAddEstablishment: (addEstablishment: boolean) => void
  updateInstitutionId: (id: string) => void
}>({
  auth: {},
  updateInstitution: () => null,
  updateUser: () => null,
  updateInstitutionNotification: () => null,
  updateInstitutionId: () => null,
  addEstablishment: false,
  updateAddEstablishment: () => null,
})

type State = {
  auth?: Auth & { selectedId: string }
  loading: boolean
  welcomeTour?: boolean
  showCreateAdmission: boolean
  showHelp: boolean
  addEstablishment?: boolean
  selectedInstitutionId?: string
}

type Actions =
  | { name: 'SIGN_IN'; auth: Auth }
  | { name: 'SIGN_UP'; auth: Auth }
  | { name: 'CHANGE_PROFILE'; id: string; admissionRecords?: Auth['admissionRecords'] }
  | { name: 'DISCONNECT' }
  | { name: 'NOT_CONNECTED' }
  | { name: 'FINISH_TOUR' }
  | { name: 'TOGGLE_HELP'; open: boolean }
  | { name: 'TOGGLE_CREATE_ADMISSION'; open: boolean }
  | { name: 'UPDATE_INSTITUTION'; data: Partial<InstitutionType>; selectedInstitutionId?: string }
  | { name: 'UPDATE_USER'; data: Partial<Auth['user']> }
  | {
      name: 'UPDATE_INSTITUTION_NOTIFICATION'
      data: Partial<InstitutionType>
      selectedInstitutionId: string | undefined
    }
  | { name: 'SET_SELECTED_INSTITUTION_ID'; selectedInstitutionId: string }
  | { name: 'ADD_ESTABLISHMENT'; isAddingEstablishment: boolean }
  | { name: 'SELECTED_INSTITUTION'; data: InstitutionType }

function reducer(state: State, action: Actions): State {
  switch (action.name) {
    case 'NOT_CONNECTED':
      return { loading: false, showCreateAdmission: false, showHelp: false }
    case 'FINISH_TOUR':
      return { ...state, welcomeTour: false }
    case 'TOGGLE_CREATE_ADMISSION':
      return { ...state, showCreateAdmission: action.open }
    case 'TOGGLE_HELP':
      return { ...state, showHelp: action.open }
    case 'CHANGE_PROFILE':
      return {
        ...state,
        auth: {
          ...(state.auth as Auth),
          selectedId: action.id,
          admissionRecords: action.admissionRecords || (state.auth as Auth).admissionRecords,
        },
      }
    case 'SIGN_UP':
      // todo check if localStoa
      return {
        ...{
          auth: {
            ...action.auth,
            selectedId:
              window.localStorage['profileId'] &&
              find(get(action.auth, 'admissionRecords', []), [
                'id',
                window.localStorage['profileId'],
              ])
                ? window.localStorage['profileId']
                : (get(action.auth, 'admissionRecords[0].id') as string),
          },
        },
        loading: false,
        welcomeTour: !(get(action.auth.user, 'role') === 'doctor'),
        showCreateAdmission: false,
        showHelp: false,
      }
    case 'SIGN_IN':
      return {
        ...state,
        ...{
          auth: {
            ...action.auth,
            selectedId:
              window.localStorage['profileId'] &&
              find(get(action.auth, 'admissionRecords', []), [
                'id',
                window.localStorage['profileId'],
              ])
                ? window.localStorage['profileId']
                : get(action.auth, 'admissionRecords[0].id'),
          },
        },
        loading: false,
        showCreateAdmission: false,
        showHelp: false,
      }
    case 'DISCONNECT':
      return { loading: false, showCreateAdmission: false, showHelp: false }
    case 'UPDATE_INSTITUTION': {
      const updatedInfos = state?.auth?.institution?.map(institution =>
        institution.id === action.selectedInstitutionId
          ? { ...institution, ...action.data }
          : institution,
      )

      return {
        ...state,
        auth: {
          ...(state.auth as Auth & { selectedId: string }),
          institution: updatedInfos as InstitutionType[],
        },
      }
    }
    case 'UPDATE_INSTITUTION_NOTIFICATION': {
      const updatedNotification = state.auth?.institution?.map(institution =>
        institution.id === action.selectedInstitutionId
          ? { ...institution, notification: action.data }
          : institution,
      )

      return {
        ...state,
        auth: {
          ...(state.auth as Auth & { selectedId: string }),
          institution: updatedNotification as InstitutionType[],
        },
      }
    }
    case 'UPDATE_USER':
      return {
        ...state,
        auth: {
          ...(state.auth as Auth & { selectedId: string }),
          user: {
            ...(state.auth?.user as Auth['user']),
            ...action.data,
          },
        },
      }

    case 'SET_SELECTED_INSTITUTION_ID':
      return {
        ...state,
        auth: {
          ...state.auth,
          selectedInstitutionId: action.selectedInstitutionId,
        } as Auth & { selectedId: string },
      }

    case 'ADD_ESTABLISHMENT':
      return {
        ...state,
        addEstablishment: action.isAddingEstablishment,
      }
    default:
      return state
  }
}

function RoutingByRole({ userRole, refreshUser }: { userRole: string; refreshUser: () => void }) {
  const { auth } = useContext(AuthContext)
  const habitatType = useMemo(
    () =>
      auth?.institution?.find(inst => inst.id === auth?.selectedInstitutionId)?.habitatType ||
      'medical',
    [auth],
  )

  switch (userRole) {
    case 'doctor':
      return (
        <Switch>
          <Route path="/:formName/etape/:step">
            <DoctorForm />
          </Route>
          <Redirect from="*" to="/medical/etape/1" />
        </Switch>
      )
    case 'user_institution':
      return (
        <Switch>
          <Route path="/mon-compte">
            <ProfileInstitution />
          </Route>
          <Route path="/candidatures/:candidacyId">
            <AdmissionRecordDetails showCandidacyActions />
          </Route>
          <Route path="/candidatures">
            <Candidacy />
          </Route>
          <Route path="/admission/:candidacyId/details">
            <AdmissionRecordDetails />
          </Route>
          <Route path="/admission/:candidacyId">
            <AdmissionDetails />
          </Route>
          <Route path="/admission">
            <AdmissionInstitution />
          </Route>
          <Route path="/mon-etablissement/edition/:subStep?">
            <InstitutionEdit />
          </Route>
          <Route path="/mon-etablissement/trousseaux/edition/:kitId?">
            <KitForm />
          </Route>
          <Route path="/mon-etablissement/trousseaux">
            <KitList />
          </Route>
          <Route path="/mon-etablissement/resident/:admissionRecordId/:formName/etape/:step">
            <AdmissionRecordForm />
          </Route>
          <Route path="/mon-etablissement">
            <Institution />
          </Route>
          <Route path="/mon-lieu/edition/:subStep?">
            <InclusiveInstitutionEdit />
          </Route>
          <Redirect
            from="*"
            to={habitatType === 'medical' ? 'candidatures' : '/mon-lieu/edition'}
          />
        </Switch>
      )
    default:
      return (
        <Switch>
          <Route path="/mon-compte">
            <Profile />
          </Route>
          <Route path="/admission/:section/etape/:step">
            <Admission />
          </Route>
          <Route path="/:formName/etape/:step">
            <Form refreshUser={refreshUser} />
          </Route>
          <Route>
            <Home refreshUser={refreshUser} />
          </Route>
          <Redirect from="*" to="/mon-compte" />
        </Switch>
      )
  }
}

function App() {
  const [
    { auth, loading, welcomeTour, showCreateAdmission, showHelp, addEstablishment },
    dispatch,
  ] = useReducer<Reducer<State, Actions>>(reducer, {
    loading: true,
    showCreateAdmission: false,
    showHelp: false,
  })

  const { boot, shutdown } = useIntercom()

  useEffect(() => {
    if (auth?.institution && !auth.selectedInstitutionId) {
      dispatch({
        name: 'SET_SELECTED_INSTITUTION_ID',
        selectedInstitutionId: auth.institution[0].id,
      })
    }

    if (auth?.user?.email && auth.isConfirmed) {
      const name =
        (auth?.institution && auth.institution[0].info.institutionName) ||
        `${auth?.user?.firstName} ${auth?.user?.lastName}`

      boot({
        email: auth.user.email,
        name: name,
        createdAt: `${now()}`,
      })
    }
  }, [auth])

  const userRole = get(auth, 'user.role') as User['role']
  const refreshUser = useCallback(
    () =>
      checkIfConnected()
        .then(({ data }) => {
          dispatch({ name: 'SIGN_IN', auth: data })
        })
        .catch(() => {
          dispatch({ name: 'NOT_CONNECTED' })
        }),
    [dispatch],
  )

  useEffect(() => {
    refreshUser()
  }, [auth?.selectedId])

  return (
    <ThemeProvider theme={theme}>
      <PageNameProvider>
        <ToastsProvider maxToast={5}>
          <AuthContext.Provider
            value={{
              auth: auth || {},
              updateInstitution: (
                data: Partial<InstitutionType>,
                selectedInstitutionId?: string,
              ) => {
                dispatch({ name: 'UPDATE_INSTITUTION', data, selectedInstitutionId })
              },
              updateUser: (data: Partial<Auth['user']>) => dispatch({ name: 'UPDATE_USER', data }),
              updateInstitutionNotification: (
                data: Partial<NotificationType>,
                selectedInstitutionId: string | undefined,
              ) =>
                dispatch({
                  name: 'UPDATE_INSTITUTION_NOTIFICATION',
                  data,
                  selectedInstitutionId,
                }),
              addEstablishment: addEstablishment,
              updateInstitutionId: (id: string) =>
                dispatch({ name: 'SET_SELECTED_INSTITUTION_ID', selectedInstitutionId: id }),
              updateAddEstablishment: (isAddingEstablishment: boolean) =>
                dispatch({ name: 'ADD_ESTABLISHMENT', isAddingEstablishment }),
            }}
          >
            <>
              {welcomeTour && (
                <WelcomeTour
                  name={get(auth, 'user.firstName', '')}
                  onFinish={() => dispatch({ name: 'FINISH_TOUR' })}
                />
              )}
              {showHelp && (
                <HelpPanel onClose={() => dispatch({ name: 'TOGGLE_HELP', open: false })} />
              )}
              {loading ? (
                <Box ai="c" jc="c">
                  <Loader />
                </Box>
              ) : auth?.isConfirmed ? (
                <Router>
                  {addEstablishment ? (
                    <Switch>
                      <Route path="/institution/inscription">
                        <SignUpInstitution />
                      </Route>
                    </Switch>
                  ) : (
                    <Layout
                      onDisconnect={() => {
                        dispatch({ name: 'DISCONNECT' })
                        shutdown()
                      }}
                      openCreateAdmissionModal={() =>
                        dispatch({ name: 'TOGGLE_CREATE_ADMISSION', open: true })
                      }
                      onSwitch={(id: string, admissionRecords?: Auth['admissionRecords']) => {
                        dispatch({ name: 'CHANGE_PROFILE', id, admissionRecords })
                        window.localStorage['profileId'] = id
                      }}
                      toggleHelp={(open: boolean) => dispatch({ name: 'TOGGLE_HELP', open })}
                    >
                      <RoutingByRole userRole={userRole} refreshUser={refreshUser} />
                    </Layout>
                  )}
                </Router>
              ) : (
                <Router>
                  <Switch>
                    <Route path="/inscription">
                      <SignUp />
                    </Route>
                    <Route path="/connexion">
                      <SignIn
                        onConnected={(auth: Auth) => {
                          dispatch({ name: 'SIGN_IN', auth })
                        }}
                        state="signin"
                      />
                    </Route>
                    <Route path="/medecin/inscription">
                      <SignUpDoctor
                        onConnected={(auth: Auth) => dispatch({ name: 'SIGN_UP', auth })}
                      />
                    </Route>
                    <Route path="/institution/preinscription">
                      <PresInscription />
                    </Route>
                    <Route path="/institution/inscription">
                      <SignUpInstitution />
                    </Route>
                    <Route path="/mot-de-passe-oublie">
                      <SignIn state="ask-reset" />
                    </Route>
                    <Route path="/nouveau-mot-de-passe">
                      <SignIn state="reset" />
                    </Route>
                    <Route path="/email-confirmation">
                      <SignIn state="emailNotConfirmed" />
                    </Route>
                    <Redirect from="*" to="/connexion" />
                  </Switch>
                </Router>
              )}
              <CreateAdmissionRecord
                opened={showCreateAdmission}
                onSuccess={(id, admissionRecords) => {
                  dispatch({ name: 'CHANGE_PROFILE', id, admissionRecords })
                  window.localStorage['profileId'] = id
                }}
                onClose={() => dispatch({ name: 'TOGGLE_CREATE_ADMISSION', open: false })}
              />
            </>
          </AuthContext.Provider>
        </ToastsProvider>
      </PageNameProvider>
    </ThemeProvider>
  )
}

export default App
