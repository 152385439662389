import React, { useContext, useMemo, useEffect, useRef, useCallback } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { useForm, FormProvider, useFormContext } from 'react-hook-form'
import styled, { css as cssBlock } from 'styled-components'
import { forEach, get, map, camelCase, kebabCase, includes, identity, reduce } from 'lodash'
import { useToasts } from 'hooks/useToaster'

import Box, { BoxWithHeader } from 'ui/box'
import Button from 'ui/button'
import Icon from 'ui/icon'
import Text from 'ui/typography/text'
import Title from 'ui/typography/title'
import Loader from 'ui/loader'
import Group from 'ui/form/group'
import Input from 'ui/form/input'
import Label from 'ui/form/label'
import CardInfo from 'ui/card/info'
import ProgressBar from 'ui/progressBar/vertical'
import useResource, { FetchState } from 'hooks/useResource'
import { AdmissionRecordProgress } from 'types/admissionRecord'
import { AuthContext } from 'App'
import { isMissingAnswer, renderInput } from 'components/pages/helper'

import { CardProps } from 'ui/progressBar/vertical/card'
import User, { Auth } from 'types/user'
import { Form as TForm, InputInfos } from 'types/form'
import { ProgressState, ProgressVariant } from 'types/enums'
import { getOrder } from 'constants/progressBar'

const StepBox = styled(Box)<{ active: boolean }>`
  cursor: pointer;
  border: 1px solid ${props => (props.active ? props.theme.black : props.theme.greyLightN2)};
  border-radius: 4px;
  padding: 8px 16px;
  margin-bottom: 8px;
`

const dotVariants = {
  todo: cssBlock`
    border: 2px solid ${props => props.theme.greyLightN2};
    background-color: ${props => props.theme.white};
  `,
  default: cssBlock`
  border: 2px solid ${props => props.theme.black};
  background-color: ${props => props.theme.black};
  `,
  completed: cssBlock`
    border: 2px solid ${props => props.theme.green};
    background-color: ${props => props.theme.green};
  `,
  waitChange: cssBlock`
    border: 2px solid ${props => props.theme.pink};
    background-color: ${props => props.theme.pink};
  `,
  pending: cssBlock`
    border: 2px solid ${props => props.theme.pink};
    background-color: ${props => props.theme.white};
  `,
}

const Dot = styled.div<{ variant: ProgressVariant }>`
  width: 6px;
  height: 6px;
  ${props =>
    props.variant in dotVariants
      ? dotVariants[props.variant as 'todo' | 'completed' | 'waitChange' | 'pending']
      : dotVariants['default']}
  border-radius: 8px;
`

const Progress = styled.div`
  width: 100px;
  height: 8px;
  border-radius: 4px;
  background-color: ${props => props.theme.greyDark};
  margin-right: 8px;

  div {
    height: 8px;
    background-color: ${props => props.theme.green};
    border-radius: 4px;
  }
`

const ErrorMessage = styled(Text).attrs({ fs: 16, color: 'pink' })`
  flex: 1;
`

const StyledLabel = styled(Label)<{ missing: boolean }>`
  color: ${props => (props.missing ? props.theme.pink : props.theme.black)};
  font-weight: ${props => (props.missing ? 'bold' : 'regular')};
`

export function Controls({
  prevButton,
  nextButton,
  onSave,
}: {
  prevButton?: {
    label?: string
    callback: () => void
    disabled?: boolean
  }
  nextButton?: {
    label?: string
    callback: () => void
    disabled?: boolean
  }
  onSave?: () => Promise<any>
}) {
  return (
    <Box
      fd="r"
      ai="c"
      jc={prevButton ? 'sb' : 'fe'}
      f="initial"
      padding="32px 32px 32px 32px"
      bg="greenLight2"
      style={{
        borderBottomLeftRadius: '4px',
        borderBottomRightRadius: '4px',
      }}
    >
      {!!prevButton && (
        <Button
          variant="outline"
          onClick={() => (onSave ? onSave().then(prevButton.callback) : prevButton.callback())}
          disabled={prevButton?.disabled}
        >
          <Icon name="arrow" size={20} rotate={180} containerStyle={{ marginRight: '8px' }} />
          {prevButton.label ? prevButton.label : 'Étape précédente'}
        </Button>
      )}
      {!!nextButton && (
        <Button
          variant="primary"
          onClick={() => (onSave ? onSave().then(nextButton.callback) : nextButton.callback())}
          disabled={nextButton?.disabled}
        >
          {nextButton.label ? nextButton.label : 'Étape suivante'}
          <Icon name="arrow" size={20} containerStyle={{ marginLeft: '8px' }} />
        </Button>
      )}
    </Box>
  )
}

function CustomControls({ children }: { children: JSX.Element | JSX.Element[] }) {
  return (
    <Box
      fd="r"
      ai="c"
      jc="sb"
      f="initial"
      padding="32px 32px 32px 32px"
      bg="greenLight2"
      style={{
        borderBottomLeftRadius: '4px',
        borderBottomRightRadius: '4px',
      }}
    >
      {children}
    </Box>
  )
}

export type Step<T> = {
  title: string
  Component: ({
    resource,
    onNext,
    refreshResource,
  }: {
    resource: T
    onNext: () => void
    refreshResource: () => void
  }) => JSX.Element
  save?: (data: any) => Promise<any>
  customControls?: JSX.Element | JSX.Element[]
}

export const renderPicturesSubTitle = () => (
  <Text color="grey" style={{ width: '70%', marginBottom: '20px' }}>
    Importez les photos de votre établissement afin de les afficher sur la page publique Sahanest.
    Maximum 4 photos. Formats acceptés :<span style={{ fontStyle: 'italic' }}>jpg, png</span>
  </Text>
)

export const renderEducationalProjectSubTitle = () => (
  <Text color="grey" italic>
    Détaillez ici votre projet pédagogique en détail pour l’afficher sur la page publique Sahanest.
  </Text>
)

export const renderTitle = (title: string, index: number, type: string, name: string) => {
  return (
    <>
      <Text color="green" fs={20} bold style={{ margin: '40px 0 20px 0' }}>
        {title}
      </Text>
      {name === 'educationalProject' && renderEducationalProjectSubTitle()}
      {name === 'picture' && renderPicturesSubTitle()}
    </>
  )
}

export function GenericStep<T extends { [key: string]: any }>({
  fields,
  subForm,
  subTitle,
  resource,
}: {
  fields: TForm
  subForm: string
  subTitle?: string
  resource: T
}) {
  return (
    <Box fd="c" style={{ position: 'relative' }}>
      {subTitle && (
        <Title fs={22} bold spacingBottom="32px">
          {subTitle}
        </Title>
      )}
      <FormBody<T> fields={fields} subForm={subForm} resource={resource} />
    </Box>
  )
}

export function FormBody<T extends { [key: string]: any }>({
  fields,
  subForm,
  resource,
  buttons,
  hasId = true,
}: {
  fields: TForm
  subForm: string
  resource?: T
  buttons?: {
    onCancel: () => void
    onValidating: () => void
  }
  hasId?: boolean
}) {
  const { addGenericErrorToast } = useToasts()
  const { register, control, setValue, errors, setError, watch } = useFormContext()
  const defaultValues = get(resource, subForm, {}) as any

  const initializeFields = useCallback(
    values => {
      Object.entries(values).forEach(([fieldName, fieldValue]) => {
        setValue(`${subForm}.${fieldName}`, fieldValue)
      })
    },
    [setValue, subForm],
  )

  const transformedValues = (defaultValues: any) =>
    Object.entries(defaultValues).reduce(
      (acc, [k, v]) => ({
        ...acc,
        [k]: typeof v === 'boolean' || typeof v === 'number' ? `${v}` : v,
      }),
      {},
    )

  useEffect(() => {
    const flatFields = fields.flat().filter(f => !!f) as InputInfos[]
    try {
      initializeFields({
        ...transformedValues(defaultValues),
        ...(reduce(
          flatFields.filter(f => f.type === 'file').map(f => f.name),
          (acc, v) => ({ ...acc, [v]: '' }),
          {},
        ) || {}),
      })

      forEach(
        get(defaultValues, 'errorReport', {}),
        (value, key) =>
          value &&
          setError(`${subForm}.${key}`, {
            type: 'comment',
            message: value,
          }),
      )
    } catch (error) {
      addGenericErrorToast()
    }
  }, [resource, initializeFields])

  return (
    <>
      {hasId && <Input id="id" name={`${subForm}.id`} type="hidden" ref={register()} />}
      {map(fields, (row, index) => {
        return (
          <div key={`${subForm}-row-${index}`}>
            {row[0]?.title && <>{renderTitle(row[0]?.title, index, row[0]?.type, row[0]?.name)}</>}

            <Box gutter="negative" f="initial">
              {map(row, (inputInfos, i) => {
                if (!inputInfos) {
                  return (
                    <Group key={`${subForm}-empty-row-${i}`} style={{ padding: '15px' }}></Group>
                  )
                }

                if (inputInfos.type === 'info') {
                  const { description, name } = inputInfos

                  return (
                    <Group key={`${subForm}-input-${name}`} style={{ padding: '15px' }}>
                      <CardInfo name={name} description={description} />
                    </Group>
                  )
                }

                const { name, label, required } = inputInfos

                return (
                  <Group
                    key={`${subForm}-input-${name}`}
                    style={{
                      padding: '15px',
                      display: 'block',
                      maxWidth: inputInfos.type === 'picture' ? '180px' : 'auto',
                    }}
                  >
                    <Box
                      {...(inputInfos.type === 'ranking'
                        ? { fd: 'r', ai: 'c', jc: 'sb' }
                        : { fd: 'c', jc: 'sb' })}
                    >
                      {label && inputInfos.type !== 'selectMultiple' && (
                        <StyledLabel
                          htmlFor={name}
                          required={required}
                          missing={isMissingAnswer(
                            defaultValues,
                            resource,
                            subForm,
                            name,
                            required,
                          )}
                        >
                          {label}
                        </StyledLabel>
                      )}

                      {renderInput(
                        subForm,
                        { ...inputInfos, hasError: !!get(errors, `${subForm}.${name}`, false) },
                        control,

                        register,
                        watch,
                        defaultValues,
                      )}
                    </Box>
                    <ErrorMessage>{get(errors, `${subForm}.${name}.message`)}</ErrorMessage>
                  </Group>
                )
              })}
            </Box>
          </div>
        )
      })}
      {buttons && (
        <Box jc="fe" style={{ marginTop: '15px' }}>
          <Button
            variant="outline"
            size="small"
            style={{ marginRight: '8px' }}
            onClick={buttons.onCancel}
          >
            Annuler
          </Button>
          <Button size="small" onClick={buttons.onValidating}>
            Valider
          </Button>
        </Box>
      )}
    </>
  )
}

export function GenericForm<T>({
  steps,
  path,
  url,
  resourceLoader,
  admissionRecordId,
  globalStep,
  title,
  afterSave,
}: {
  steps: (auth: Partial<Auth>) => Step<T>[]
  resourceLoader: (auth: Partial<Auth>) => Promise<T>
  title: string
  globalStep: number
  path: string
  url?: string
  admissionRecordId?: string
  afterSave?: () => void
}) {
  const methods = useFormContext()
  const { auth } = useContext(AuthContext)
  const { addToast, addGenericErrorToast } = useToasts()
  const { step } = useParams<{ step: string }>()
  const history = useHistory()

  const wrappedSteps = useMemo(() => steps(auth), [auth, admissionRecordId])

  const parsedStep = Number(step)
  const {
    title: subTitle,
    Component,
    save,
    customControls,
  } = wrappedSteps[parsedStep - 1] || wrappedSteps[0] // In case of unknown step/removed go to beginning
  const hasSaveControls = !!save
  const [{ resource, loading }, dispatch] = useResource<T, {}>(
    undefined,
    () => resourceLoader(auth),
    [auth?.selectedId, admissionRecordId],
  )

  function onSubmit(data?: any): Promise<any> {
    return save
      ? save(data)
          .then(() =>
            addToast({
              title: 'Succès !',
              type: 'success',
              message: 'Formulaire sauvegardé avec succès !',
            }),
          )
          .then(() => dispatch({ name: 'REFRESH_DATA' }))
          .then(afterSave || identity)
          .catch(e => {
            const errorData = e?.response?.data
            const errors = Object.keys(errorData)
            const firstError = errors[0]
            const value = errorData[firstError]
            if (value) {
              addToast({
                title: 'Erreur !',
                type: 'error',
                message: value?.[0] ?? value,
              })
            } else addGenericErrorToast()

            return Promise.reject(e)
          })
      : Promise.resolve()
  }

  const formRef = useRef<any>()

  useEffect(() => {
    if (formRef.current) {
      formRef.current.scrollTo(0, 0)
    }
  }, [step, formRef])

  return (
    <BoxWithHeader f="2" br={4} style={{ margin: '0 15px', padding: '0' }} as="form">
      <Box jc="sb">
        <Box ai="c">
          <Box
            bg="black"
            br={4}
            width="24px"
            height="24px"
            jc="c"
            ai="c"
            style={{ marginRight: '16px' }}
          >
            <Text color="white" bold>
              {globalStep}
            </Text>
          </Box>
          <Box fd="c">
            <Title fs={24} bold>
              {title}
            </Title>
            <Text>{`${globalStep}.${step} ${subTitle}`}</Text>
          </Box>
        </Box>

        {hasSaveControls && (
          <Button variant="outline" onClick={() => onSubmit(methods.getValues())}>
            <Icon name="save" size={20} rotate={180} containerStyle={{ marginRight: '8px' }} />
            Sauvegardez
          </Button>
        )}
      </Box>

      <Box fd="c" style={{ overflowY: 'scroll' }}>
        <Box
          ref={formRef}
          padding="32px 32px 0 32px"
          style={{
            overflowY: 'scroll',
            marginBottom: hasSaveControls || customControls ? '0' : '32px',
            display: 'block',
          }}
        >
          <Box
            fd="c"
            ai={loading ? 'c' : undefined}
            jc={loading ? 'c' : undefined}
            style={{ minHeight: '100%' }}
          >
            {loading || !resource ? (
              <Loader />
            ) : (
              <Component
                resource={resource}
                onNext={() =>
                  history.push(`${url || ''}/${kebabCase(path)}/etape/${parsedStep + 1}`)
                }
                refreshResource={() => dispatch({ name: 'REFRESH_DATA' })}
              />
            )}
          </Box>
        </Box>
        {hasSaveControls && (
          <Controls
            nextButton={
              wrappedSteps.length !== parsedStep
                ? {
                    callback: () =>
                      history.push(`${url || ''}/${kebabCase(path)}/etape/${parsedStep + 1}`),
                  }
                : undefined
            }
            prevButton={
              parsedStep !== 1
                ? {
                    callback: () =>
                      history.push(`${url || ''}/${kebabCase(path)}/etape/${parsedStep - 1}`),
                  }
                : undefined
            }
            onSave={() => onSubmit(methods.getValues())}
          />
        )}
        {customControls && <CustomControls>{customControls}</CustomControls>}
      </Box>
    </BoxWithHeader>
  )
}

export function FormLayout({
  children,
  resourceState,
  progressBar,
  path,
}: {
  children: JSX.Element | JSX.Element[]
  resourceState: FetchState<AdmissionRecordProgress, {}>
  progressBar: { title: string; items: Array<CardProps & { children: React.ReactNode }> }
  path?: string
}) {
  const methods = useForm()
  const { pathname } = useLocation()
  const history = useHistory()

  useEffect(() => {
    if (!resourceState.resource || includes(pathname, 'support-validation')) {
      return
    }

    if (get(resourceState.resource, `${camelCase(path)}.statusProgress`) === 'disabled') {
      history.push('/')
    }
  }, [resourceState.resource])

  // css calc : 100vh - header - padding - footer
  return (
    <Box gutter="negative" style={{ height: 'calc(100vh - 85px - 64px - 70px)' }}>
      <BoxWithHeader f="1" br={4} style={{ margin: '0 15px' }}>
        <Box>
          <Title fs={24} bold>
            {progressBar.title}
          </Title>
        </Box>

        <Box padding="32px" style={{ overflowY: 'scroll', display: 'block' }}>
          {resourceState.loading ? (
            <Box ai="c" jc="c" height="100%">
              <Loader />
            </Box>
          ) : (
            <ProgressBar items={progressBar.items} />
          )}
        </Box>
      </BoxWithHeader>

      <FormProvider {...methods}>{children}</FormProvider>
    </Box>
  )
}

export function GetItemsAdmissionRecord(
  resourceState: FetchState<AdmissionRecordProgress, {}>,
  history: ReturnType<typeof useHistory>,
  pathname: string,
  userRole?: User['role'],
  url?: string,
) {
  if (resourceState.loading) return []

  return map(getOrder(userRole), ({ path, label, subSteps, childrenLabel }, i) => {
    const state = get(resourceState.resource, `${path}.statusProgress`, 'todo') as ProgressState

    return {
      title: label,
      onClick: () => !subSteps && history.push(`${url || ''}/${kebabCase(path)}/etape/1`),
      variant: camelCase(state) as ProgressVariant,
      defaultOpen: pathname.includes(`${url || ''}/${kebabCase(path)}/`),
      collapseChildren: subSteps && (
        <Box fd="c">
          {subSteps.map(([subPath, label], j) => {
            const introVariant =
              pathname === `${url || ''}/${kebabCase(path)}/etape/1`
                ? 'current'
                : (subSteps || []).some(
                      ([subpath]) =>
                        get(resourceState.resource, `${path}.${subpath}Status`, 'todo') !== 'todo',
                    )
                  ? 'completed'
                  : 'todo'

            return (
              <StepBox
                ai="c"
                fd="r"
                key={`${path}.${subPath}`}
                active={pathname === `${url || ''}/${kebabCase(path)}/etape/${j + 1}`}
                onClick={() => history.push(`${url || ''}/${kebabCase(path)}/etape/${j + 1}`)}
              >
                <Text style={{ flex: 1 }}>
                  <b>{`${i + 1}.${j + 1}`}</b> {label}
                </Text>
                <Dot
                  variant={
                    camelCase(
                      subPath === 'intro'
                        ? introVariant
                        : get(resourceState.resource, `${path}.${subPath}Status`, 'todo'),
                    ) as ProgressVariant
                  }
                />
              </StepBox>
            )
          })}
        </Box>
      ),
      children: (
        <Box fd="r" ai="c">
          {state === 'disabled' && <Icon name="lock" size={14} />}
          <Text
            fs={14}
            as="div"
            color={state === 'todo' || state === 'disabled' ? 'black' : 'green'}
            style={{ paddingLeft: state === 'disabled' ? '6px' : 0 }}
          >
            {get(childrenLabel, camelCase(state) as ProgressVariant) || (
              <Box ai="c">
                <Progress>
                  <div
                    style={{
                      width: `${get(
                        resourceState.resource,
                        `${path}.percentProgress`,
                        state === 'completed' ? 100 : 0,
                      )}%`,
                    }}
                  ></div>
                </Progress>
                {get(
                  resourceState.resource,
                  `${path}.percentProgress`,
                  state === 'completed' ? 100 : 0,
                )}
                %
              </Box>
            )}
          </Text>
        </Box>
      ),
    }
  }) as Array<CardProps & { children: React.ReactNode }>
}
