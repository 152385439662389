import React from 'react'

import Layout from '../../edit/blocks/layout'
import { Form } from 'types/form'
import {
  AROUND_HABITAT_OPTIONS,
  BUILDING_PARC_TYPE_OPTIONS,
  HABITAT_FEATURES_OPTIONS,
  HABITAT_FORMAT_OPTIONS,
  INCLUSIVE_WELCOME_OPTIONS,
  INHABITANT_AUTONOMY_OPTIONS,
  LOAN_DETAIL_OPTIONS,
  LOAN_LEVEL_OPTIONS,
  TRANSPORTATION_ACCESSIBILITY_OPTIONS,
  VULNERABILITY_TYPE_OPTIONS,
} from 'constants/form'

const CARACTERISTICS_FORM: Form = [
  [
    {
      title: 'Photos du lieu de vie',
      name: 'pictures[0]',
      type: 'picture',
      label: '',
      index: 0,
      required: false,
    },
    {
      title: 'Photos de l’établissement',
      name: 'pictures[1]',
      type: 'picture',
      label: '',
      index: 1,
      required: false,
    },
    {
      title: 'Photos de l’établissement',
      name: 'pictures[2]',
      type: 'picture',
      label: '',
      index: 2,
      required: false,
    },
    {
      title: 'Photos de l’établissement',
      name: 'pictures[3]',
      type: 'picture',
      label: '',
      index: 3,
      required: false,
    },
  ],
  [
    {
      name: 'habitatFormat',
      type: 'select',
      label: "Format d'habitat",
      placeholder: "Saisissez le format d'habitat",
      required: true,
      multi: true,
      options: HABITAT_FORMAT_OPTIONS,
    },
  ],
  [
    {
      name: 'welcomeFormat',
      type: 'select',
      label: "Format d'accueil",
      placeholder: "Saisissez le format  d'accueil",
      required: true,
      multi: false,
      options: INCLUSIVE_WELCOME_OPTIONS,
    },
    {
      name: 'fragility',
      type: 'select',
      label: 'Type de fragilité accueillie',
      placeholder: 'Sélectionnez le type de fragilité',
      required: true,
      multi: true,
      options: VULNERABILITY_TYPE_OPTIONS,
    },
  ],
  [
    {
      name: 'autonomy',
      type: 'select',
      label: "Niveau d'autonomie des habitants",
      placeholder: 'Sélectionnez le niveau d’autonomie',
      required: true,
      multi: false,
      options: INHABITANT_AUTONOMY_OPTIONS,
    },
  ],
  [
    {
      name: 'surface',
      type: 'number',
      label: 'Surface de l’habitat (m²)',
      placeholder: 'Saisissez le nombre de m²',
      required: false,
      disabled: false,
      rules: { min: { value: 0, message: 'Veuillez entrer une valeur positive' } },
    },
    {
      name: 'totalSlot',
      type: 'number',
      label: 'Nombre de places totales',
      placeholder: 'Saisissez le nombre de places totales',
      required: false,
      disabled: false,
      rules: { min: { value: 0, message: 'Veuillez entrer une valeur positive' } },
    },
  ],
  [
    {
      name: 'availableSlot',
      type: 'number',
      label: 'Nombre de places disponibles',
      placeholder: 'Saisissez le nombre de places disponibles',
      required: false,
      disabled: false,
      rules: { min: { value: 0, message: 'Veuillez entrer une valeur positive' } },
    },
  ],
  [
    {
      title: 'Accès au lieu de vie',
      name: 'accessTransportation',
      type: 'checkbox',
      label: 'Accessibilité transports',
      options: TRANSPORTATION_ACCESSIBILITY_OPTIONS,
      required: true,
      multiColumn: true,
      disabled: false,
    },
  ],
  [
    {
      name: 'accessInfos',
      type: 'textarea',
      label: 'Distances',
      placeholder: "Indiquez toutes les informations d'accès que vous estimerez nécessaires",
      required: false,
      disabled: false,
    },
  ],
  [
    {
      title: 'Alentours du lieu de vie',
      name: 'around',
      type: 'checkbox',
      label: 'Aux alentours',
      options: AROUND_HABITAT_OPTIONS,
      required: false,
      multiColumn: true,
      disabled: false,
    },
  ],
  [
    {
      name: 'aroundInfos',
      type: 'textarea',
      label: 'Distances',
      placeholder: "Indiquez toutes les informations d'accès que vous estimerez nécessaires",
      required: false,
      disabled: false,
    },
  ],
  [
    {
      title: 'Équipements du lieu de vie',
      name: 'infrastructure',
      type: 'checkbox',
      label: 'Commodités de l’habitat',
      options: HABITAT_FEATURES_OPTIONS,
      required: false,
      multiColumn: true,
      disabled: false,
    },
  ],
  [
    {
      title: 'Type de loyer',
      name: 'estateType',
      type: 'select',
      label: 'Type de parc immobilier',
      placeholder: 'Sélectionnez le type de parc',
      required: false,
      multi: false,
      options: BUILDING_PARC_TYPE_OPTIONS,
    },
    {
      title: 'Type de loyer',
      name: 'rentType', //TODO - update
      type: 'select',
      label: 'Niveau de loyer',
      placeholder: 'Sélectionnez le loyer ',
      required: false,
      multi: false,
      options: LOAN_LEVEL_OPTIONS,
    },
  ],
  [
    {
      name: 'rentDetail',
      type: 'select',
      label: 'Détail de loyer',
      placeholder: 'Sélectionnez le type de loyer',
      required: false,
      multi: false,
      options: LOAN_DETAIL_OPTIONS,
    },
  ],
]

export default function InclusiveCaracteristics() {
  return (
    <Layout
      title="Caractéristiques du lieu de vie"
      subTitle="Renseignez les caractéristiques du lieu de vie, ses infrastructures environnantes..."
      ctaText="Enregistrer et quitter"
      hideCTAIcon
      subText=""
      help={{
        icon: 'specifications',
        title: 'A quoi serviront ces informations ?',
        text: "Apportez les indications techniques autour de votre habitat afin que les personnes qui identifient votre solution d'accueil comprennent le mieux possible l'organisation et les infrastructures environnantes.",
      }}
      fields={CARACTERISTICS_FORM}
      subForm="inclusiveCaracteristic"
    />
  )
}
