import React, { useReducer, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { flatMap, get } from 'lodash'
import { useToasts } from 'hooks/useToaster'
import { useWebflowSlug } from 'hooks/useParamId'

import Box from 'ui/box'
import Title from 'ui/typography/title'
import Text from 'ui/typography/text'
import Navbar from 'ui/navbar'
import Stepper, { Step } from 'ui/stepper'
import Button from 'ui/button'
import Icon from 'ui/icon'
import { Footer } from 'components/pages/layout'

import StepConcernedInfos from './blocks/stepConcernedInfos'
import StepEstablishment from './blocks/stepEstablishment'
import StepUserInfos from './blocks/stepUserInfos'
import StepUserType from './blocks/stepUserType'
import StepSuggestedEstablishment from './blocks/stepSuggestedEstablishment'

import { resendEmailConfirmation, signUp } from 'api/user'
import { similarWebflowInstitutions } from 'api/institution'

import { UserType, EstablishmentType, UrgencyLevel } from 'types/enums'
import User from 'types/user'

import Logo from 'components/logo'
import { ReactComponent as Success } from 'assets/illustrations/success.svg'

type SignUpErrorReply = {
  response: {
    data?: { [key: string]: string[] }
  }
}
export type ConcernedInfos = {
  concernedAge: string
  urgencyLevel: UrgencyLevel
  alreadyInStructure: string
}
export type UserInfos = {
  email: string
  password: string
  firstName: string
  lastName: string
}
export type State = {
  completedSteps: {
    [key in 'userType' | 'concernedInfos' | 'establishment' | 'userInfos']: boolean
  }
  currentStep: number
  success: boolean
  errorMessages?: string[]
  user?: User
  data: {
    userInfos?: UserInfos
    concernedInfos?: ConcernedInfos
    userType?: UserType
    establishmentSearch?: EstablishmentType[]
    otherSuggestedInstitutionId?: string[]
    webflowSlug?: string
  }
  suggestedEstablishments: []
}

const initialState: State = {
  currentStep: 0,
  completedSteps: {} as State['completedSteps'],
  success: false,
  data: {},
  suggestedEstablishments: [],
}

const reducer = (state: State, action: { type: string; payload?: any }) => {
  const { type, payload } = action

  switch (type) {
    case 'NEXT_STEP':
      return {
        ...state,
        currentStep: state.currentStep < 3 ? state.currentStep + 1 : state.currentStep,
        completedSteps: { ...state.completedSteps, [payload.stepName]: true },
        data: {
          ...state.data,
          ...payload.data,
        },
      }
    case 'SET_STATE':
      return {
        ...state,
        success: payload.success,
        errorMessages: payload.errorMessages,
        user: payload.user,
      }
    case 'PREVIOUS_STEP':
      return {
        ...state,
        currentStep: state.currentStep - 1,
      }
    case 'SET_SUGGESTED_ESTABLISHMENTS':
      return {
        ...state,
        data: {
          ...state.data,
          establishmentSearch: payload.data[0].infoInstitutionType,
        },
        suggestedEstablishments: payload.data,
      }

    case 'UPDATE_OTHER_SUGGESTED_ESTABLISHMENTS':
      return {
        ...state,
        data: {
          ...state.data,
          otherSuggestedInstitutionId: payload.data,
        },
      }

    default:
      return state
  }
}

export default function SignUp() {
  const history = useHistory()
  const [state, dispatch] = useReducer(reducer, initialState)
  const { addToast } = useToasts()

  const webflowSlug = useWebflowSlug()

  useEffect(() => {
    if (webflowSlug) {
      similarWebflowInstitutions(webflowSlug).then(response => {
        dispatch({
          type: 'SET_SUGGESTED_ESTABLISHMENTS',
          payload: { data: response },
        })
      })
    }
  }, [])

  const hasSuggestedEstablishment = useMemo(
    () => state.suggestedEstablishments.length > 0,
    [state.suggestedEstablishments],
  )

  const renderContent = () => {
    switch (state.currentStep) {
      case 0:
        return (
          <StepUserType
            onNext={(data: UserType) =>
              dispatch({
                type: 'NEXT_STEP',
                payload: { stepName: 'userType', data: { userType: data } },
              })
            }
          />
        )
      case 1:
        return (
          <StepConcernedInfos
            onNext={(data: State['data']['concernedInfos']) =>
              dispatch({
                type: 'NEXT_STEP',
                payload: { stepName: 'concernedInfos', data: { concernedInfos: data } },
              })
            }
            onPrevious={() => dispatch({ type: 'PREVIOUS_STEP' })}
            defaultValues={state.data.concernedInfos}
          />
        )
      case 2:
        return !hasSuggestedEstablishment ? (
          <StepEstablishment
            onNext={(data: EstablishmentType[]) =>
              dispatch({
                type: 'NEXT_STEP',
                payload: { stepName: 'establishment', data: { establishmentSearch: data } },
              })
            }
            onPrevious={() => dispatch({ type: 'PREVIOUS_STEP' })}
            defaultValue={state.data.establishmentSearch}
          />
        ) : (
          <StepSuggestedEstablishment
            selectedEstablishment={state.suggestedEstablishments[0]}
            suggestedEstablishments={state.suggestedEstablishments.slice(1)}
            otherSuggestedEstablishmentId={state.data.otherSuggestedInstitutionId}
            onChange={otherSuggestedIds => {
              dispatch({
                type: 'UPDATE_OTHER_SUGGESTED_ESTABLISHMENTS',
                payload: {
                  data: otherSuggestedIds,
                },
              })
            }}
            onPrevious={() => dispatch({ type: 'PREVIOUS_STEP' })}
            onNext={() =>
              dispatch({
                type: 'NEXT_STEP',
                payload: { stepName: 'establishment' },
              })
            }
          />
        )
      case 3:
        return (
          <StepUserInfos
            onNext={(data: State['data']['userInfos']) => {
              signUp({ ...state.data, userInfos: data, webflowSlug })
                .then(reply =>
                  dispatch({
                    type: 'SET_STATE',
                    payload: { success: true, user: reply },
                  }),
                )
                .catch(({ response: { data } }: SignUpErrorReply) => {
                  dispatch({
                    type: 'SET_STATE',
                    payload: {
                      errorMessages: data
                        ? flatMap(data)
                        : ['Une erreur est survenue, veuillez réessayer.'],
                      success: false,
                    },
                  })
                })
              dispatch({
                type: 'NEXT_STEP',
                payload: { stepName: 'userInfos', data: { userInfos: data } },
              })
            }}
            onPrevious={() => dispatch({ type: 'PREVIOUS_STEP' })}
            defaultValues={state.data.userInfos}
            errorMessages={state.errorMessages}
          />
        )
    }
  }

  return (
    <Box fd="r" style={{ width: '100%', minHeight: '100vh' }}>
      <Box
        style={{
          flex: '2',
        }}
        bg="green"
      >
        <Box padding="12%" fd="c">
          <Logo paddingBottom="30%" url="https://www.sahanest.fr/" />
          {state.currentStep === 0 ? (
            <Box fd="c">
              <Title bold fs={28} color="white">
                Bienvenue sur Sahanest, inscrivez-vous en quelques clics.
              </Title>
              <Text fs={16} color="white" style={{ paddingTop: '24px' }}>
                Créez votre compte en moins de 3 minutes. <br />
                Une aide et un gain de temps précieux.
              </Text>
            </Box>
          ) : (
            <Stepper>
              <Step
                index="1"
                variant={
                  state.completedSteps['userType']
                    ? 'completed'
                    : state.currentStep === 0
                      ? 'pending'
                      : 'todo'
                }
              >
                Lien avec la personne concernée
              </Step>
              <Step
                index="2"
                variant={
                  state.completedSteps['concernedInfos']
                    ? 'completed'
                    : state.currentStep === 1
                      ? 'pending'
                      : 'todo'
                }
              >
                Protégé / Personne concernée
              </Step>
              <Step
                index="3"
                variant={
                  state.completedSteps['establishment']
                    ? 'completed'
                    : state.currentStep === 2
                      ? 'pending'
                      : 'todo'
                }
              >
                Lieux recherchés
              </Step>
              <Step
                index="4"
                variant={
                  state.completedSteps['userInfos']
                    ? 'completed'
                    : state.currentStep === 3
                      ? 'pending'
                      : 'todo'
                }
              >
                Informations du compte
              </Step>
            </Stepper>
          )}
        </Box>
      </Box>
      <Box style={{ flex: '4' }} bg="greyLight3">
        <Box fd="c">
          <Navbar
            yellowButtonLabel="Déjà un compte ?"
            href={'/connexion'}
            onClick={state.success ? undefined : () => history.push('/connexion')}
          />
          {!state.success ? (
            renderContent()
          ) : (
            <Box padding="15% 10% 12% 10%" fd="c" ai="c">
              <Success style={{ marginBottom: '8%' }} />
              <Title fs={28} bold spacingBottom="8px">
                {get(state.data.userInfos, 'firstName')} votre inscription est presque prête !
              </Title>
              <Text opacity={0.6} spacingBottom="4%">
                Confirmez votre adresse e-mail pour accéder à votre espace.
              </Text>
              <Button
                onClick={() => {
                  resendEmailConfirmation(state?.data.userInfos?.email).then(() =>
                    addToast({
                      title: 'Succès !',
                      type: 'success',
                      message: `L'email vient d'être renvoyé !`,
                    }),
                  )
                }}
              >
                Envoyer à nouveau
                <Icon name="arrow" size={20} containerStyle={{ marginLeft: '8px' }} />
              </Button>
            </Box>
          )}
          <Footer />
        </Box>
      </Box>
    </Box>
  )
}
