import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import Box from 'ui/box'
import Intro from 'components/pages/intro'

import Layout from '../layout'
import Step1 from './step1'
import Conclusion from '../conclusion'

export default function TakenCareStep() {
  const history = useHistory()
  const { section, step } = useParams<{ section: string; step: string }>()
  const parsedStep = Number(step)

  return (
    <Layout
      title="Accord de prise en charge"
      globalStep={4}
      steps={() => [
        {
          title: 'Introduction',
          Component: () => (
            <Box padding="32px">
              <Intro
                onNext={() => history.push(`/admission/${section}/etape/${parsedStep + 1}`)}
                sections={[
                  {
                    title: 'Le lieu de vie confirme définitivement votre prise en charge :',
                    text: 'le lieu de vie va valider administrativement votre prise en charge et vous proposer une date d’intégration dans les locaux',
                  },
                  {
                    title: 'Le lieu de vie va vous transmettre le trousseau d’accueil :',
                    text: 'Le trousseau d’accueil décrit tous les essentiels à prévoir avec vous le jour de votre installation pour être certain d’avoir tous les bons effets sur place. Il vous sera accessible en détail.',
                  },
                ]}
              />
            </Box>
          ),
        },
        {
          title: 'Établissement prêt à accueillir',
          Component: () => <Step1 />,
        },
        {
          title: 'Complet',
          Component: () => (
            <Conclusion
              title="Vous avez choisi votre établissement !"
              text=""
              buttonTitle="Aller à l'étape de demande de financement"
              onNext={() => history.push('/admission/financement/etape/1')}
            />
          ),
        },
      ]}
    />
  )
}
