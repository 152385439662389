import React, { useContext, useEffect } from 'react'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import { camelCase, get, map } from 'lodash'
import styled from 'styled-components'

import Box, { BoxWithHeader } from 'ui/box'
import Title from 'ui/typography/title'
import Text from 'ui/typography/text'
import ProgressBar from 'ui/progressBar/vertical'
import Loader from 'ui/loader'
import Icon from 'ui/icon'
import { CardProps } from 'ui/progressBar/vertical/card'
import Intro from 'components/pages/intro'

import InstitutionStep from './blocks/institution'
import InstitutionChoiceStep from './blocks/institutionChoice'
import MDPHStep from './blocks/mdph'
import FinancialStep from './blocks/financial'
import KitStep from './blocks/kit'
import TakenCareStep from './blocks/engagement'
import Layout from './blocks/layout'

import { getAdmissionStatus } from 'api/admission'
import { AdmissionStatus } from 'types/admission'
import { ProgressVariant } from 'types/enums'
import { computeAdmissionProgressVariant, ORDER_ADMISSION } from 'constants/progressBar'
import { usePageName } from 'hooks/usePageName'
import { AuthContext } from 'App'
import useResource from 'hooks/useResource'

import { alert } from 'assets/illustrations'

const Progress = styled.div`
  width: 100px;
  height: 8px;
  border-radius: 4px;
  background-color: ${props => props.theme.greyDark};
  margin-right: 8px;

  div {
    height: 8px;
    background-color: ${props => props.theme.green};
    border-radius: 4px;
  }
`

export default function Admission() {
  const { setPageName } = usePageName()
  const { auth } = useContext(AuthContext)
  const history = useHistory()
  const { section, step } = useParams<{ section: string; step: string }>()
  const [resourceState] = useResource<{ admissionStatus: AdmissionStatus }, {}>(
    undefined,
    () => getAdmissionStatus(auth.selectedId || '0'),
    [step],
  )

  useEffect(() => {
    setPageName('Admission')
  }, [])

  useEffect(() => {
    resourceState.resource?.admissionStatus === 'disabled' && history.push('/')
  }, [resourceState.resource])

  function mapResource(status?: AdmissionStatus) {
    const variants = computeAdmissionProgressVariant(status)

    return map(ORDER_ADMISSION, ({ label, childrenLabel, path }, i) => {
      const state = variants[i]

      return {
        title: label,
        onClick: () => history.push(`/admission/${path}/etape/1`),
        variant: state,
        defaultOpen: false,
        children: (
          <Box fd="r" ai="c">
            {state === 'disabled' && <Icon name="lock" size={14} />}
            <Text
              fs={14}
              as="div"
              color={state === 'pending' || state === 'disabled' ? 'black' : 'green'}
              style={{ paddingLeft: state === 'disabled' ? '6px' : 0 }}
            >
              {get(childrenLabel, camelCase(state) as ProgressVariant) || (
                <Box ai="c">
                  <Progress>
                    <div style={{ width: '100%' }}></div>
                  </Progress>
                  100%
                </Box>
              )}
            </Text>
          </Box>
        ),
      }
    }) as Array<CardProps & { children: React.ReactNode }>
  }

  const renderContent = () => {
    switch (section) {
      case 'lieux-de-vie':
        return <InstitutionStep />
      case 'choix':
        return <InstitutionChoiceStep />
      case 'mdph':
        return <MDPHStep />
      case 'accord':
        return <TakenCareStep />
      case 'financement':
        return <FinancialStep />
      case 'trousseau':
        return <KitStep />
      default:
        return <Redirect from="*" to="/admission/lieux-de-vie/etape/1" />
    }
  }

  // css calc : 100vh - header - padding - footer
  return (
    <Box gutter="negative" style={{ height: 'calc(100vh - 85px - 64px - 70px)' }}>
      <BoxWithHeader f="1" br={4} style={{ margin: '0 15px' }}>
        <Box>
          <Title fs={24} bold>
            Suivi de l’admission
          </Title>
        </Box>

        <Box padding="32px" style={{ overflowY: 'scroll', display: 'block' }}>
          {resourceState.loading ? (
            <Box ai="c" jc="c" height="100%">
              <Loader />
            </Box>
          ) : (
            <ProgressBar items={mapResource(get(resourceState.resource, 'admissionStatus'))} />
          )}
        </Box>
      </BoxWithHeader>
      {resourceState.loading ? (
        <Box ai="c" jc="c" height="100%">
          <Loader />
        </Box>
      ) : get(resourceState.resource, 'admissionStatus') ? (
        renderContent()
      ) : (
        <Layout
          title="Admission vérrouillée"
          steps={() => [
            {
              title: 'Admission vérrouillée',
              Component: () => (
                <Box padding="32px">
                  <Intro
                    title="Sections à compléter avant d’accéder à l’admission"
                    image={alert}
                    sections={[
                      {
                        title: 'Renseignements administratifs et familiaux',
                        text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet unde earum ratione, sapiente pariatur, totam quam neque velit, officia dolore culpa sequi nemo? Quidem porro eveniet tenetur dolorum error perferendis!',
                      },
                      {
                        title: 'Renseignements sociaux',
                        text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet unde earum ratione, sapiente pariatur, totam quam neque velit, officia dolore culpa sequi nemo? Quidem porro eveniet tenetur dolorum error perferendis!',
                      },
                      {
                        title: 'Renseignements éducatifs',
                        text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet unde earum ratione, sapiente pariatur, totam quam neque velit, officia dolore culpa sequi nemo? Quidem porro eveniet tenetur dolorum error perferendis!',
                      },
                    ]}
                  />
                </Box>
              ),
            },
          ]}
        />
      )}
    </Box>
  )
}
