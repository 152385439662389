import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import { get } from 'lodash'

import Group from 'ui/form/group'
import Input from 'ui/form/input'
import Label from 'ui/form/label'
import Title from 'ui/typography/title'
import Text from 'ui/typography/text'
import Button, { LinkButton } from 'ui/button'
import { resetPassword } from 'api/user'
import { ResetPasswordPayload } from 'types/user'

const ErrorMessage = styled(Text).attrs({ fs: 16, color: 'pink' })<{
  hasError: boolean
}>`
  visibility: ${props => (props.hasError ? 'visible' : 'hidden')};
`

export default function ResetPassword() {
  const [{ error, success }, setState] = useState({
    loading: false,
    error: false,
    success: false,
  })
  const token = new URLSearchParams(useLocation().search).get('reset_password_token')
  const { register, handleSubmit, errors, watch } = useForm<ResetPasswordPayload>({
    defaultValues: {
      resetPasswordToken: token || 'no-token',
    },
  })

  function onSubmit(data: ResetPasswordPayload) {
    setState({ loading: true, error: false, success: false })

    resetPassword(data)
      .then(() => setState({ loading: false, error: false, success: true }))
      .catch(() => setState({ loading: false, error: true, success: false }))
  }

  return (
    <>
      <Title bold fs={28} style={{ paddingBottom: '8%' }}>
        Réinitialiser votre mot de passe
      </Title>
      {!success ? (
        <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <Input id="resetPasswordToken" name="resetPasswordToken" type="hidden" ref={register()} />
          <Group style={{ paddingBottom: '5%' }}>
            <Label htmlFor="password" required style={{ paddingBottom: '16px' }}>
              Nouveau mot de passe
            </Label>
            <Input
              id="password"
              name="password"
              type="password"
              placeholder="Saisissez votre mot de passe"
              ref={register({
                required: 'Ce champ est requis',
                minLength: {
                  value: 8,
                  message: 'Le mot de passe doit contenir au moins 8 caractères',
                },
              })}
            />
            <ErrorMessage hasError={!!get(errors, 'password.message')}>
              {get(errors, 'password.message')}
            </ErrorMessage>
          </Group>

          <Group style={{ paddingBottom: '5%' }}>
            <Label htmlFor="password-confirm" required style={{ paddingBottom: '16px' }}>
              Confirmation du mot de passe
            </Label>
            <Input
              id="passwordConfirmation"
              name="passwordConfirmation"
              type="password"
              placeholder="Saisissez à nouveau votre mot de passe"
              ref={register({
                required: 'Ce champ est requis',
                validate: value =>
                  value === watch('password') || 'Les mots de passe ne correspondent pas',
              })}
            />
            <ErrorMessage hasError={!!get(errors, 'passwordConfirmation.message')}>
              {get(errors, 'passwordConfirmation.message')}
            </ErrorMessage>
          </Group>

          <ErrorMessage hasError={error}>Une erreur est survenue, veuillez réessayer.</ErrorMessage>

          <Button type="submit" style={{ width: '100%', margin: '12% 0 10% 0' }}>
            Réinitialiser le mot de passe
          </Button>
        </form>
      ) : (
        <>
          <Text fs={16} style={{ display: 'flex', padding: '0 0 16px 0' }}>
            Votre mot de passe a été changé avec succés !
          </Text>
          <LinkButton
            className="primary"
            style={{ width: '100%', margin: '12% 0 10% 0' }}
            href="/connexion"
          >
            Se connecter
          </LinkButton>
        </>
      )}
    </>
  )
}
