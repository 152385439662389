import React, { useContext } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { get, map } from 'lodash'

import Box from 'ui/box'
import Title from 'ui/typography/title'
import Text from 'ui/typography/text'
import { LinkButton } from 'ui/button'
import Loader from 'ui/loader'
import Icon from 'ui/icon'
import Group from 'ui/form/group'
import Label from 'ui/form/label'
import Dropdown from 'ui/form/dropdown'
import { Controls } from 'components/pages/form'

import { AuthContext } from 'App'
import useResource from 'hooks/useResource'
import { useToasts } from 'hooks/useToaster'
import { getAdmission, selectInstitutionsForMDPH } from 'api/admission'

export default function Step1() {
  const { auth } = useContext(AuthContext)
  const history = useHistory()
  const { addToast, addGenericErrorToast } = useToasts()
  const { control, errors, handleSubmit } = useForm<{ candidaciesIds: string[] }>()
  const [resourceState] = useResource<{ value: string; label: string }[], {}>(
    undefined,
    () =>
      getAdmission(get(auth, 'selectedId', '0'), { current: true }).then(({ candidacies }) =>
        map(candidacies, candidacy => ({ label: candidacy.name, value: candidacy.candidacyId })),
      ),
    [],
  )
  const { section, step } = useParams<{ section: string; step: string }>()
  const parsedStep = Number(step)

  const onSubmit = (data: { candidaciesIds: string[] }) => {
    selectInstitutionsForMDPH(get(auth, 'selectedId', '0'), data.candidaciesIds)
      .then(() =>
        addToast({
          type: 'success',
          title: 'Succès !',
          message: 'Les établissements sélectionnés ont bien été envoyés ! !',
        }),
      )
      .then(() => history.push(`/admission/${section}/etape/${parsedStep + 1}`))
      .catch(addGenericErrorToast)
  }

  return (
    <Box
      fd="c"
      style={{
        minHeight: '100%',
      }}
    >
      <Box
        fd="c"
        padding="32px"
        style={{
          overflowY: 'scroll',
        }}
      >
        <Box fd="c" f="initial">
          <Box
            jc="sb"
            ai="c"
            bg="greyLightN"
            padding="24px"
            br={4}
            style={{ marginBottom: '32px' }}
          >
            <Box>
              <Icon
                name="message"
                size={22}
                color="green"
                containerStyle={{ marginRight: '8px' }}
              />
              <Text fs={16}>Aucun établissement peut vous accueillir, contactez-nous</Text>
            </Box>
            <LinkButton
              className="small outline"
              style={{ marginLeft: '32px', flexShrink: 0 }}
              href="tel:+33367840322"
            >
              <Icon name="phone" size={12} containerStyle={{ marginRight: '8px' }} />
              <Text fs={14} bold>
                07 44 31 13 10
              </Text>
            </LinkButton>
          </Box>
          <Title fs={22} bold spacingBottom="8px">
            Saisissez le ou les établissements qui ont retenu votre dossier{' '}
          </Title>
          <Text fs={16} opacity={0.6} spacingBottom="32px">
            Renseignez ci dessous le ou les établissements présents sur la notification MDPH. Ces
            derniers peuvent accueillir l’usager.
          </Text>
          {resourceState.loading ? (
            <Box ai="c" jc="c">
              <Loader />
            </Box>
          ) : (
            <form>
              <Group>
                <Label htmlFor="candidaciesIds" required style={{ paddingBottom: '16px' }}>
                  Établissement(s) présent(s) sur la notification
                </Label>
                <Controller
                  as={Dropdown}
                  id="candidaciesIds"
                  name="candidaciesIds"
                  placeholder="Sélectionnez le ou les établissements présent(s) sur la notification"
                  options={resourceState.resource || []}
                  isMulti
                  control={control}
                  rules={{
                    required: 'Ce champ est requis',
                  }}
                  hasError={!!errors?.candidaciesIds}
                />
                <Text
                  fs={16}
                  color="pink"
                  style={{
                    visibility: errors?.candidaciesIds ? 'visible' : 'hidden',
                    marginTop: '8px',
                  }}
                >
                  Ce champ est requis
                </Text>
              </Group>
            </form>
          )}
        </Box>
      </Box>
      <Controls
        prevButton={{
          callback: () => history.push(`/admission/${section}/etape/${parsedStep - 1}`),
          label: 'Retour',
        }}
        nextButton={{
          callback: handleSubmit(onSubmit),
          label: 'Continuer',
        }}
      />
    </Box>
  )
}
