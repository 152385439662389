import React from 'react'
import { useForm } from 'react-hook-form'

import Box from 'ui/box'
import Button from 'ui/button'
import Icon from 'ui/icon'
import Group from 'ui/form/group'
import Input from 'ui/form/input'
import Label from 'ui/form/label'
import Text from 'ui/typography/text'

import StepLayout from './stepLayout'
import { UserInfos } from '..'
import { get, map } from 'lodash'
import Checkbox from 'ui/form/checkbox'
import Link from 'ui/typography/link'

const StepUserInfos: React.FC<{
  onNext: (data: UserInfos) => void
  onPrevious: () => void
  defaultValues?: UserInfos
  errorMessages?: string[]
}> = ({ onNext, onPrevious, defaultValues, errorMessages }) => {
  const { register, handleSubmit, errors, watch } = useForm<UserInfos>({ defaultValues })

  return (
    <StepLayout
      title="Créez votre compte dès maintenant !"
      subText="Fournissez ces informations et accédez à votre espace personnel."
    >
      <Box as="form" onSubmit={handleSubmit(onNext)} autoComplete="off" fd="c">
        <Box ai="s" f="0 1 auto">
          <Group style={{ paddingBottom: '2%' }}>
            <Label htmlFor="lastName" required style={{ paddingBottom: '16px' }}>
              Nom
            </Label>
            <Input
              id="lastName"
              name="lastName"
              placeholder="Saisissez votre nom"
              ref={register({ required: true })}
            />
            <Text
              fs={16}
              color="pink"
              style={{ visibility: errors.lastName ? 'visible' : 'hidden' }}
            >
              Ce champ est requis
            </Text>
          </Group>
          <Group style={{ padding: '0 0 2% 5%' }}>
            <Label htmlFor="firstName" required style={{ paddingBottom: '16px' }}>
              Prénom
            </Label>
            <Input
              id="firstName"
              name="firstName"
              placeholder="Saisissez votre prénom"
              ref={register({ required: true })}
            />
            <Text
              fs={16}
              color="pink"
              style={{ visibility: errors.firstName ? 'visible' : 'hidden' }}
            >
              Ce champ est requis
            </Text>
          </Group>
        </Box>
        <Box ai="s">
          <Group style={{ paddingBottom: '2%' }}>
            <Label htmlFor="email" required style={{ paddingBottom: '16px' }}>
              E-mail
            </Label>
            <Input
              id="email"
              name="email"
              type="email"
              placeholder="Saisissez votre e-mail"
              ref={register({ required: true })}
            />
            <Text fs={16} color="pink" style={{ visibility: errors.email ? 'visible' : 'hidden' }}>
              Ce champ est requis
            </Text>
          </Group>
          <Group style={{ padding: '0 0 2% 5%' }}>
            <Label htmlFor="password" required style={{ paddingBottom: '16px' }}>
              Mot de passe
            </Label>
            <Input
              id="password"
              name="password"
              type="password"
              placeholder="Saisissez votre mot de passe"
              ref={register({
                required: 'Ce champ est requis',
                minLength: {
                  value: 8,
                  message: 'Le mot de passe doit contenir au moins 8 caractères',
                },
              })}
            />
            <Text
              fs={16}
              color="pink"
              style={{ visibility: errors.password ? 'visible' : 'hidden' }}
            >
              {get(errors, 'password.message')}
            </Text>
          </Group>
        </Box>
        <Box ai="s" jc="fs">
          <Checkbox
            id="cgu"
            name="cgu"
            value="true"
            containerStyle={{ marginBottom: '18px' }}
            forwardRef={register({ required: 'Ce champ est requis' })}
          >
            J’accepte les{' '}
            <Link
              color="green"
              underline
              href="https://sahanest.fr/cgu"
              target="_blank"
              style={{ display: 'inline' }}
            >
              conditions générales d’utilisation
            </Link>{' '}
            et déclare avoir pris connaissance de la{' '}
            <Link
              color="green"
              underline
              href="https://sahanest.fr/politique-donnees"
              target="_blank"
              style={{ display: 'inline' }}
            >
              politique de protection des données
            </Link>
          </Checkbox>
        </Box>
        <Box fd="c" f="0 1 auto">
          {errorMessages &&
            map(errorMessages, (message, i) => (
              <Text
                key={`error-message-${i}`}
                fs={16}
                color="pink"
                style={{ paddingBottom: '16px' }}
              >
                {message}
              </Text>
            ))}
          <Box jc="sb">
            <Button variant="outline" style={{ backgroundColor: 'white' }} onClick={onPrevious}>
              <Icon name="arrow" size={20} rotate={180} containerStyle={{ marginRight: '8px' }} />
              Étape précédente
            </Button>
            <Button type="submit" disabled={!watch('cgu')}>
              Créer mon compte
              <Icon name="arrow" size={20} containerStyle={{ marginLeft: '8px' }} />
            </Button>
          </Box>
        </Box>
      </Box>
    </StepLayout>
  )
}

export default StepUserInfos
