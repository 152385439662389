import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { get, meanBy } from 'lodash'

import Box from 'ui/box'
import Button from 'ui/button'
import Title from 'ui/typography/title'
import Text from 'ui/typography/text'

import concerned from 'components/pages/patient/administrative/forms/concerned'
import legalGuardian from 'components/pages/patient/administrative/forms/legalGuardian'
import medicalTeam from 'components/pages/patient/administrative/forms/medicalTeam'
import socialCoverage from 'components/pages/patient/social/forms/socialCoverage'
import socialCoverageOther from 'components/pages/patient/social/forms/socialCoverageOther'
import { abilityForm } from 'components/pages/patient/educative/forms/ability'
import aggressiveness from 'components/pages/patient/educative/forms/aggressiveness'
import sexualBehavior from 'components/pages/patient/educative/forms/sexualBehavior'
import food from 'components/pages/patient/educative/forms/food'
import runaway from 'components/pages/patient/educative/forms/runaway'
import addiction from 'components/pages/patient/educative/forms/addiction'
import benefit from 'components/pages/patient/financial/forms/benefit'
import pocketMoney from 'components/pages/patient/financial/forms/pocketMoney'
import patient from 'components/pages/doctor/medical/forms/patient'
import ald from 'components/pages/doctor/medical/forms/ald'
import diagnostic from 'components/pages/doctor/medical/forms/diagnostic'
import handicap from 'components/pages/doctor/medical/forms/handicap'
import deficiency from 'components/pages/doctor/medical/forms/deficiency'
import lifeStyle from 'components/pages/doctor/medical/forms/lifeStyle'
import history from 'components/pages/doctor/medical/forms/history'
import author from 'components/pages/doctor/medical/forms/author'
import {
  Summary,
  SummaryMultiple,
  FamilySummary,
  SituationSummary,
  TreatmentSummary,
  VaccinationSummary,
  DocumentSummary,
} from 'components/pages/summaryForm'

import TSummary from 'types/summary'
import AdmissionRecord from 'types/admissionRecord'
import { Candidacy } from 'types/candidacy'

const Panel = styled(Box).attrs({ fd: 'c', jc: 'fs' })`
  background-color: white;
  padding: 24px;
  border-radius: 2px;
  border: 1px solid ${props => props.theme.greyLight};
  margin-bottom: 24px;
`

const PanelTitle = styled(Box).attrs({ jc: 'sb', ai: 'c', fd: 'r' })`
  ${Title} {
    flex: 1;
  }
`

export default function AdmissionRecordSummary({
  candidacy,
  admissionRecord,
}: {
  candidacy: Candidacy
  admissionRecord: AdmissionRecord
}) {
  const [panels, setPanelState] = useState<{ [key: string]: boolean }>({})
  const SUMMARY = useMemo(
    () =>
      [
        {
          name: 'administrative',
          label: 'Renseignements administratifs & familiaux',
          sections: [
            {
              type: 'single',
              form: 'concerned',
              label: 'Personne concernée',
              formFields: concerned,
            },
            {
              type: 'single',
              form: 'legalGuardian',
              label: 'Responsable légal',
              formFields: legalGuardian,
            },
            {
              type: 'custom',
              form: 'family',
              label: 'Famille',
              component: FamilySummary,
            },
            {
              type: 'single',
              form: 'medicalTeam',
              label: 'Équipe médicale',
              formFields: medicalTeam,
            },
          ],
        },
        {
          name: 'social',
          label: 'Renseignements sociaux',
          sections: [
            {
              type: 'multiple',
              forms: ['coverage', 'otherCoverage'],
              label: 'Couverture sociale',
              formFields: [socialCoverage, socialCoverageOther],
            },
          ],
        },
        {
          name: 'educative',
          label: 'Renseignements éducatifs',
          sections: [
            {
              type: 'custom',
              form: 'situation',
              label: 'Situation actuelle',
              component: SituationSummary,
            },
            {
              type: 'single',
              form: 'ability',
              label: 'Évaluation des capacités',
              formFields: abilityForm.map(a => a.questions),
            },
            {
              type: 'multiple',
              forms: ['aggressiveness', 'sexualityBehavior', 'foodLine', 'runaway', 'addiction'],
              label: 'Comportement',
              formFields: [aggressiveness, sexualBehavior, food, runaway, addiction],
            },
          ],
        },
        {
          name: 'financial',
          label: 'Renseignements financiers',
          sections: [
            {
              type: 'multiple',
              forms: ['benefit', 'pocketMoney'],
              label: 'Renseignements financiers',
              formFields: [benefit, pocketMoney],
            },
          ],
        },
        {
          name: 'document',
          label: 'Documents à joindre',
          sections: [
            {
              type: 'custom',
              form: 'owner',
              label: 'Liste des documents administratifs',
              component: DocumentSummary,
            },
            {
              type: 'custom',
              form: 'medical',
              label: 'Liste des documents médicaux',
              component: DocumentSummary,
            },
            {
              type: 'custom',
              form: 'educative',
              label: 'Liste des documents socio-éducatifs',
              component: DocumentSummary,
            },
          ],
        },
        {
          name: 'medical',
          label: 'Renseignements médicaux',
          sections: [
            {
              type: 'single',
              form: 'patient',
              label: 'Patient',
              formFields: patient,
            },
            {
              type: 'single',
              form: 'ald',
              label: 'Informations ALD',
              formFields: ald,
            },
            {
              type: 'single',
              form: 'diagnostic',
              label: 'Diagnostic principal',
              formFields: diagnostic,
            },
            {
              type: 'single',
              form: 'handicap',
              label: 'Origine du handicap',
              formFields: handicap,
            },
            {
              type: 'custom',
              form: 'treatment',
              label: 'Traitement actuel',
              component: TreatmentSummary,
            },
            {
              type: 'single',
              form: 'deficiency',
              label: 'Déficiences',
              formFields: deficiency,
            },
            {
              type: 'single',
              form: 'lifeStyle',
              label: 'Mode de vie',
              formFields: lifeStyle,
            },
            {
              type: 'custom',
              form: 'vaccination',
              label: 'Vaccinations',
              component: VaccinationSummary,
            },
            {
              type: 'single',
              form: 'history',
              label: 'Antécédents',
              formFields: history,
            },
            {
              type: 'single',
              form: 'author',
              label: 'Auteur des données',
              formFields: author,
            },
          ],
        },
      ] as TSummary[],
    [],
  )

  return (
    <Box fd="c">
      <Box padding="24px" bg="greyLightN" style={{ marginBottom: '24px' }}>
        <Box fd="c">
          <Text fs={14} opacity={0.7} style={{ textTransform: 'uppercase' }}>
            HANDICAP
          </Text>
          {/* TODO : get handicap when it will be available */}
          {/* <Text fs={16}>{candidacy.handicap}</Text> */}
        </Box>
        <Box fd="c">
          <Text fs={14} opacity={0.7} style={{ textTransform: 'uppercase' }}>
            DOSSIER ENVOYÉ LE
          </Text>
          <Text fs={16}>{new Date(candidacy.askOwnerInstitution).toLocaleDateString()}</Text>
        </Box>
      </Box>
      {SUMMARY.map(({ name, label, sections }, fIndex) => (
        <Box fd="c" key={name} style={{ marginTop: '12px' }}>
          <Title fs={20} bold spacingBottom="12px">
            {label}
          </Title>

          {sections.map((s, sIndex) => {
            const panelName = `${name}.${get(s, 'form', s.label)}`
            const displayed = panels[panelName]
            let toRender
            let progress

            switch (s.type) {
              case 'single':
                toRender = displayed && (
                  <Summary
                    fields={s.formFields}
                    formState={admissionRecord}
                    searchKeys={[s.form].map(f => `${name}.${f}`)}
                  />
                )
                progress = get(admissionRecord, `${name}.${s.form}.progress`)
                break
              case 'multiple':
                toRender = displayed && (
                  <SummaryMultiple
                    listOfFields={s.formFields}
                    formState={admissionRecord}
                    searchKeys={s.forms.map(f => `${name}.${f}`)}
                  />
                )
                progress = meanBy(s.forms, o => get(admissionRecord, `${name}.${o}.progress`))
                break
              case 'custom': {
                const Component = s.component

                toRender = displayed && (
                  <Component formState={admissionRecord} searchKey={`${name}.${s.form}`} />
                )
                progress = get(admissionRecord, `${name}.${s.form}.progress`)
                break
              }
            }

            return (
              <Panel key={panelName}>
                <PanelTitle>
                  <Box fd="c">
                    <Text fs={18} bold>
                      {`${fIndex + 1}.${sIndex + 1} ${s.label}`}
                    </Text>
                    <Text fs={16} color="green">
                      {progress || 0}% de complété
                    </Text>
                  </Box>
                  <Button
                    variant="outline"
                    onClick={() => setPanelState({ ...panels, [panelName]: !displayed })}
                    size="small"
                  >
                    {displayed ? 'Masquer' : 'Déplier'}
                  </Button>
                </PanelTitle>
                {toRender}
              </Panel>
            )
          })}
        </Box>
      ))}
    </Box>
  )
}
