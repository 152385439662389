import React from 'react'
import App from './App'
import { createRoot } from 'react-dom/client'
import { IntercomProvider } from 'react-use-intercom'

const root = createRoot(document.getElementById('root')!)

root.render(
  <IntercomProvider appId={process.env.REACT_APP_INTERCOM_ID as string}>
    <App />
  </IntercomProvider>,
)
