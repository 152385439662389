import React from 'react'

import Layout from '../../edit/blocks/layout'
import { Form } from 'types/form'
import { ANIMATION_SCHEDULE_OPTIONS, NIGHTWATCH_TYPE_OPTIONS } from 'constants/form'

const ANIMATIONS_FORM: Form = [
  [
    {
      title: 'Présences et animations',
      name: 'animator',
      type: 'checkbox',
      label: "Présence d'un animateur / coordinateur de maison",
      vertical: true,
      required: false,
      options: ANIMATION_SCHEDULE_OPTIONS,
    },
  ],
  [
    {
      name: 'night',
      type: 'select',
      label: 'La nuit',
      required: false,
      options: NIGHTWATCH_TYPE_OPTIONS,
    },
  ],
  [
    {
      title: 'Services et Partenaires',
      name: 'helpService',
      type: 'textarea',
      label: 'Services d’aide et de soins partenaires',
      placeholder: 'Inscrivez le nom des acteurs partenaires et rôle de chacun...',
      required: false,
    },
  ],
  [
    {
      name: 'partner',
      type: 'textarea',
      label: 'Partenariats avec des services de santé locaux',
      placeholder: 'Inscrivez le nom des acteurs partenaires et rôle de chacun...',
      required: false,
    },
  ],
]

export default function InclusiveAnimation() {
  return (
    <Layout
      title="Animation et organisation de la maison"
      subTitle="Renseignez les animations et l’organisation de votre lieu de vie"
      ctaText="Enregistrer et quitter"
      hideCTAIcon
      subText=""
      help={{
        icon: 'animation',
        title: "Décrivez l'organisation de votre lieu de vie",
        text: "Décrivez les grandes lignes de l'organisation de votre maison afin de permettre aux candidats de mieux comprendre comment s'organise le projet de vie sociale et partagé de votre Habitat.",
      }}
      fields={ANIMATIONS_FORM}
      subForm="inclusiveOrganization"
    />
  )
}
