import React, { useState, useContext, useMemo } from 'react'
import styled from 'styled-components'
import { get } from 'lodash'
import { useHistory } from 'react-router-dom'

import { AuthContext } from 'App'
import { getProgress } from 'api/form'
import Box from 'ui/box'
import Button from 'ui/button'
import Icon from 'ui/icon'
import Loader from 'ui/loader'
import Title from 'ui/typography/title'
import Text from 'ui/typography/text'
import useResource, { Fetched } from 'hooks/useResource'
import Administrative from 'types/administrative'
import Educative from 'types/educative'
import Social from 'types/social'

import concerned from 'components/pages/patient/administrative/forms/concerned'
import legalGuardian from 'components/pages/patient/administrative/forms/legalGuardian'
import medicalTeam from 'components/pages/patient/administrative/forms/medicalTeam'
import socialCoverage from 'components/pages/patient/social/forms/socialCoverage'
import socialCoverageOther from 'components/pages/patient/social/forms/socialCoverageOther'
import { abilityForm } from 'components/pages/patient/educative/forms/ability'
import aggressiveness from 'components/pages/patient/educative/forms/aggressiveness'
import sexualBehavior from 'components/pages/patient/educative/forms/sexualBehavior'
import food from 'components/pages/patient/educative/forms/food'
import runaway from 'components/pages/patient/educative/forms/runaway'
import addiction from 'components/pages/patient/educative/forms/addiction'
import {
  Summary,
  SummaryMultiple,
  FamilySummary,
  SituationSummary,
} from 'components/pages/summaryForm'

import TSummary from 'types/summary'
import AdmissionRecord from 'types/admissionRecord'

const Panel = styled(Box).attrs({ fd: 'c', ai: 's', jc: 'fs' })`
  background-color: white;
  padding: 24px;
  border-radius: 4px;
  border: 1px solid ${props => props.theme.black};
  margin-bottom: 24px;
`

const PanelTitle = styled(Box).attrs({ jc: 'sb', ai: 'c', fd: 'r' })`
  ${Title} {
    flex: 1;
  }
`

export default function Validation() {
  const { auth } = useContext(AuthContext)
  const history = useHistory()
  const [resourceState] = useResource<Partial<AdmissionRecord>, {}>(undefined, () => {
    const id = get(auth, 'selectedId', '0')

    return Promise.all([
      getProgress<Administrative>(id, 'administrative'),
      getProgress<Social>(id, 'social'),
      getProgress<Educative>(id, 'educative'),
    ]).then(([a, b, c]) => ({ administrative: a, social: b, educative: c }))
  }, [])
  const [panels, setPanelState] = useState<{ [key: string]: boolean }>({})
  const SUMMARY = useMemo(
    () =>
      [
        {
          name: 'administrative',
          label: 'Renseignements administratifs & familiaux',
          sections: [
            {
              type: 'single',
              form: 'concerned',
              label: 'Personne concernée',
              formFields: concerned,
              path: '/administrative/etape/2',
            },
            {
              type: 'single',
              form: 'legalGuardian',
              label: 'Responsable légal',
              formFields: legalGuardian,
              path: '/administrative/etape/3',
            },
            {
              type: 'custom',
              form: 'family',
              label: 'Famille',
              component: FamilySummary,
              path: '/administrative/etape/4',
            },
            {
              type: 'single',
              form: 'medicalTeam',
              label: 'Équipe médicale',
              formFields: medicalTeam,
              path: '/administrative/etape/5',
            },
          ],
        },
        {
          name: 'social',
          label: 'Renseignements sociaux',
          sections: [
            {
              type: 'multiple',
              forms: ['coverage', 'otherCoverage'],
              label: 'Couverture sociale',
              formFields: [socialCoverage, socialCoverageOther],
              path: '/social/etape/2',
            },
          ],
        },
        {
          name: 'educative',
          label: 'Renseignements éducatifs',
          sections: [
            {
              type: 'custom',
              form: 'situation',
              label: 'Situation actuelle',
              component: SituationSummary,
              path: '/educative/etape/2',
            },
            {
              type: 'single',
              form: 'ability',
              label: 'Évaluation des capacités',
              formFields: abilityForm.map(a => a.questions),
              path: '/educative/etape/3',
            },
            {
              type: 'multiple',
              forms: ['aggressiveness', 'sexualityBehavior', 'foodLine', 'runaway', 'addiction'],
              label: 'Comportement',
              formFields: [aggressiveness, sexualBehavior, food, runaway, addiction],
              path: '/educative/etape/4',
            },
          ],
        },
      ] as TSummary[],
    [],
  )

  if (resourceState.loading || !!resourceState.error) {
    return (
      <Box ai="c" jc="c">
        <Loader />
      </Box>
    )
  }

  const { resource: formState } = resourceState as Fetched<Partial<AdmissionRecord>>

  return (
    <Box fd="c">
      {SUMMARY.map(({ name, label, sections }, fIndex) => (
        <Box fd="c" padding="12px" key={name}>
          <Title bold fs={24} spacingBottom="12px">
            {label}
          </Title>

          {sections.map((s, sIndex) => {
            const panelName = `${name}.${get(s, 'form', s.label)}`
            const displayed = panels[panelName]
            let toRender

            if (displayed) {
              switch (s.type) {
                case 'single':
                  toRender = (
                    <Summary
                      fields={s.formFields}
                      formState={formState}
                      searchKeys={[s.form].map(f => `${name}.${f}`)}
                    />
                  )
                  break
                case 'multiple':
                  toRender = (
                    <SummaryMultiple
                      listOfFields={s.formFields}
                      formState={formState}
                      searchKeys={s.forms.map(f => `${name}.${f}`)}
                    />
                  )
                  break
                case 'custom': {
                  const Component = s.component

                  toRender = <Component formState={formState} searchKey={`${name}.${s.form}`} />
                  break
                }
              }
            }

            return (
              <Panel key={panelName}>
                <PanelTitle>
                  <Icon size={16} name="check" color="white" br={20} bg="green" cp="4px" />
                  <Text fs={18} bold as="div" style={{ flex: 1, padding: '0 24px' }}>{`${
                    fIndex + 1
                  }.${sIndex + 1} ${s.label}`}</Text>
                  {s.path && (
                    <Button
                      variant="outline"
                      onClick={() => history.push(s.path as string)}
                      style={{ marginRight: '24px' }}
                      size="small"
                    >
                      Éditer
                    </Button>
                  )}
                  <Button
                    variant="outline"
                    onClick={() => setPanelState({ ...panels, [panelName]: !displayed })}
                    size="small"
                  >
                    {displayed ? 'Masquer' : 'Déplier'}
                  </Button>
                </PanelTitle>
                {toRender}
              </Panel>
            )
          })}
        </Box>
      ))}
    </Box>
  )
}
