import React from 'react'
import { useHistory } from 'react-router-dom'

import Box from 'ui/box'
import Intro from 'components/pages/intro'

import Layout from '../layout'
import Conclusion from '../conclusion'
import Step1 from './step1'
import Step2 from './step2'

export default function MDPHStep() {
  const history = useHistory()

  return (
    <Layout
      title="Dossier MDPH et Notification MPDH"
      globalStep={3}
      steps={() => [
        {
          title: 'Introduction',
          Component: () => (
            <Box padding="32px">
              <Intro
                onNext={() => history.push('/admission/mdph/etape/2')}
                sections={[
                  {
                    title: 'Uploadez votre notification d’orientation MDPH :',
                    text: 'ce document est indispensable pour justifier de l’orientation vers le lieu de vie identifié. D’autre part ce document doit bien faire apparaître le type de lieu de vie vers lequel vous candidatez : foyer de vie, maison d’accueil spécialisé etc...',
                  },
                  {
                    title: 'Prenez contact avec l’équipe si vous avez des questions :',
                    text: 'Vous pouvez joindre notre équipe sur contact@sahanest.fr si vous avez la moindre question sur ce document et si vous avez besoin d’aide sur le sujet.',
                  },
                ]}
              />
            </Box>
          ),
        },
        {
          title: 'Envoyer la notification MPDH',
          Component: () => <Step1 />,
        },
        {
          title: 'Envoyer la notification MPDH',
          Component: () => <Step2 />,
        },
        {
          title: 'Complet',
          Component: () => (
            <Conclusion
              title="Notification MDPH transmise."
              text="Vous devez attendre que l’établissement  donne son accord de prise en charge. Vous recevrez un e-mail lorsqu’un établissement aura donné son accord de prise en charge. (Cela peut prendre 1 semaine )"
              buttonTitle="Aller à l’accord de prise en charge"
              onNext={() => history.push('/admission/accord/etape/1')}
            />
          ),
        },
      ]}
    />
  )
}
