import React, { useContext, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { get, some, map, xorBy, remove, filter } from 'lodash'

import Box from 'ui/box'
import Title from 'ui/typography/title'
import Text from 'ui/typography/text'
import Loader from 'ui/loader'
import Button, { LinkButton } from 'ui/button'
import { Controls } from 'components/pages/form'

import { acceptInstitutionsDecisions, getAdmission } from 'api/admission'
import { InstitutionCandidacy } from 'types/candidacy'
import { AuthContext } from 'App'
import useResource from 'hooks/useResource'

import Icon from 'ui/icon'
import { useToasts } from 'hooks/useToaster'

export default function Step1() {
  const { auth } = useContext(AuthContext)
  const { addToast, addGenericErrorToast } = useToasts()
  const history = useHistory()
  const [resourceState] = useResource<
    { institutionAccept: InstitutionCandidacy[]; institutionRefusal: InstitutionCandidacy[] },
    {}
  >(
    undefined,
    () =>
      getAdmission(get(auth, 'selectedId', '0'), { current: true }).then(({ candidacies }) => ({
        institutionAccept: filter(candidacies, d => d.candidacyStatus === 'institution_accept'),
        institutionRefusal: filter(candidacies, d => d.candidacyStatus === 'institution_refusal'),
      })),
    [],
  )
  const { section, step } = useParams<{ section: string; step: string }>()
  const parsedStep = Number(step)
  const [state, setState] = useState<{
    accepted: InstitutionCandidacy[]
    refused: InstitutionCandidacy[]
  }>({ accepted: [], refused: [] })

  function mapInstitution(resource: InstitutionCandidacy[], withButtons: boolean) {
    return map(resource, (data, i) => (
      <Box
        key={`institution-${withButtons ? 'accept' : 'refusal'}-${i}`}
        fd="c"
        withBorder
        br={4}
        style={{ marginBottom: '16px' }}
      >
        <Box padding="24px" jc="sb" ai="c">
          {withButtons ? (
            <Icon
              name="check"
              size={18}
              color="white"
              bg="green"
              br={50}
              cp="3px"
              containerStyle={{ marginRight: '24px' }}
            />
          ) : (
            <Icon
              name="close"
              size={18}
              color="white"
              bg="pink"
              br={50}
              cp="3px"
              containerStyle={{ marginRight: '24px' }}
            />
          )}
          <Box fd="c">
            <Text fs={18} color="black" bold>
              {data.name}
            </Text>
            <Text fs={16} color="black">
              {data.address}
            </Text>
          </Box>
          {withButtons && (
            <Box jc="fe">
              <Button
                variant={some(state?.refused, data) ? 'danger' : 'outline-danger'}
                size="small"
                style={{ marginRight: '8px' }}
                onClick={() =>
                  setState({
                    refused: xorBy(state.refused, [data], 'candidacyId'),
                    accepted: remove(state.accepted, ica => ica.candidacyId !== data.candidacyId),
                  })
                }
              >
                Refuser
              </Button>
              <Button
                variant={some(state?.accepted, data) ? 'success' : 'outline'}
                size="small"
                onClick={() =>
                  setState({
                    accepted: xorBy(state.accepted, [data], 'candidacyId'),
                    refused: remove(state.refused, icr => icr.candidacyId !== data.candidacyId),
                  })
                }
              >
                Accepter
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    ))
  }

  return (
    <Box
      fd="c"
      style={{
        minHeight: '100%',
      }}
    >
      <Box
        fd="c"
        padding="32px"
        style={{
          overflowY: 'scroll',
        }}
      >
        {resourceState.loading ? (
          <Box ai="c" jc="c">
            <Loader />
          </Box>
        ) : (
          <Box fd="c" f="initial">
            {get(resourceState.resource, 'institutionAccept', []).length > 0 ? (
              <Box fd="c" style={{ marginBottom: '32px' }}>
                <Title fs={22} bold spacingBottom="32px">
                  Ces lieux de vie ont accepté votre dossier.
                </Title>
                <Box fd="c" f="initial">
                  {mapInstitution(resourceState.resource?.institutionAccept || [], true)}
                </Box>
              </Box>
            ) : (
              <Box
                jc="sb"
                ai="c"
                bg="greyLightN"
                padding="24px"
                br={4}
                style={{ marginBottom: '32px' }}
              >
                <Box>
                  <Icon
                    name="message"
                    size={22}
                    color="green"
                    containerStyle={{ marginRight: '8px' }}
                  />
                  <Text fs={16}>Aucun établissement peut vous accueillir, contactez-nous</Text>
                </Box>
                <LinkButton
                  className="small outline"
                  style={{ marginLeft: '32px', flexShrink: 0 }}
                  href="tel:+33367840322"
                >
                  <Icon name="phone" size={12} containerStyle={{ marginRight: '8px' }} />
                  <Text fs={14} bold>
                    07 44 31 13 10
                  </Text>
                </LinkButton>
              </Box>
            )}
            {get(resourceState.resource, 'institutionRefusal', []).length > 0 && (
              <Box fd="c">
                <Title fs={22} bold spacingBottom="32px">
                  Ces lieux de vie ne peuvent pas accepter votre dossier.
                </Title>
                <Box fd="c" f="initial">
                  {mapInstitution(resourceState.resource?.institutionRefusal || [], false)}
                </Box>
              </Box>
            )}
          </Box>
        )}
      </Box>
      <Controls
        prevButton={{
          callback: () => history.push(`/admission/${section}/etape/${parsedStep - 1}`),
          label: 'Retour',
        }}
        nextButton={{
          callback: () => {
            acceptInstitutionsDecisions(
              get(auth, 'selectedId', '0'),
              map(state.accepted, ic => ic.candidacyId),
            )
              .then(() =>
                addToast({
                  type: 'success',
                  title: 'Succès !',
                  message: 'Votre décision a bien été prise ne compte !',
                }),
              )
              .then(() => history.push(`/admission/${section}/etape/${parsedStep + 1}`))
              .catch(addGenericErrorToast)
          },
          disabled:
            state.accepted.length + state.refused.length !==
              resourceState.resource?.institutionAccept.length ||
            resourceState.resource?.institutionAccept.length === 0,
          label: 'Continuer',
        }}
      />
    </Box>
  )
}
