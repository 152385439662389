import { Form } from 'types/form'
import { TUTOR_STATUS } from 'types/enums'
import { sharedFields } from '../helper'

export const OPTIONS_TUTOR_STATUS = [
  { value: TUTOR_STATUS.KIN_TUTOR, label: 'Tutelle familiale' },
  { value: TUTOR_STATUS.PRO_TUTOR, label: 'Tutelle professionnelle' },
  { value: TUTOR_STATUS.KIN_CUR, label: 'Curatelle familiale' },
  { value: TUTOR_STATUS.PRO_CUR, label: 'Curatelle professionnelle' },
  { value: TUTOR_STATUS.KIN_HAB, label: 'Habilitation familiale' },
]

export const LEGAL_GUARDIAN_FORM: Form = [
  [...sharedFields.nameFields],
  [
    {
      name: 'status',
      type: 'select',
      label: 'Mesure de protection juridique',
      placeholder: 'Choisissez la mesure de protection juridique',
      required: true,
      options: OPTIONS_TUTOR_STATUS,
    },
    {
      name: 'kinship',
      type: 'text',
      label: 'Si mesure de protection familiale, lien de parenté',
      placeholder: 'Saisissez votre lien de parenté',
      required: false,
    },
  ],
  [...sharedFields.addressFields],
  [...sharedFields.locationFields],
  [...sharedFields.countryField],
  [...sharedFields.contactFields],
]

export default LEGAL_GUARDIAN_FORM
