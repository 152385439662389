import React from 'react'
import { get } from 'lodash'

import Intro from 'components/pages/intro'
import FormDoc from './form'
import { GenericForm } from '../../form'
import { Auth } from 'types/user'
import TDocument from 'types/document'
import { getProgress, submit } from 'api/form'
import Conclusion from '../conclusion'
import FreeDocumentForm from './form/free'

export const OWNER_FORM = [
  { label: 'Notification MDPH', name: 'mdph' },
  { label: "Notification d'aide sociale", name: 'notificationSocialHelp' },
  {
    label: 'Copie de la carte Européenne délivrée par la sécurité sociale',
    name: 'europeanSocialSecurityCard',
  },
  {
    label: 'Carte vitale (recto/verso)',
    name: 'socialSecurityCard',
    required: true,
  },
  {
    label: 'Carte de mutuelle ou attestation en cours de validité',
    name: 'mutual',
  },
  {
    label: 'Attestation de sécurité sociale (mentionnant ALD)',
    name: 'socialAttestation',
  },
  {
    label: 'Accord du département pour la prise en charge des frais de séjour',
    name: 'departementalPickUpExpense',
  },
  {
    label: 'Allocation Adulte handicapé (AAH)',
    name: 'aah',
  },
  {
    label: 'Jugement de tutelle',
    name: 'guardianshipJudgment',
  },
]

export const MEDICAL_FORM = [
  {
    label: 'Bilan sanguin récent',
    name: 'bloodResult',
  },
  {
    label: 'Bilan dentaire < 12mois',
    name: 'dentalResult',
  },
  {
    label: 'Bilan médical < 12mois',
    name: 'medicalResult',
  },
  {
    label: 'Prescription médicale',
    name: 'medicalPrescription',
  },
  {
    label: 'Sections du carnet de santé',
    name: 'healthBook',
  },
]

export const EDUCATIVE_FORM = [
  {
    type: 'radio',
    label: 'La personne concernée est elle à domicile sans prise en charge externalisée ?',
    name: 'homeWithoutHelp',
    options: [
      { value: 'true', label: 'Oui' },
      { value: 'false', label: 'Non' },
    ],
    required: true,
  },
  {
    label: 'Rapport de comportement récent fait par les professionels engagés dans le suivi ',
    name: 'report',
  },
]

export default function Document({ refreshProgress }: { refreshProgress: () => void }) {
  return (
    <GenericForm<TDocument>
      key="document-form"
      title="Documents à joindre"
      path="document"
      resourceLoader={(auth: Partial<Auth>) =>
        getProgress(get(auth, 'selectedId', '0'), 'document')
      }
      globalStep={6}
      afterSave={refreshProgress}
      steps={(auth: Partial<Auth>) => [
        {
          title: 'Introduction',
          Component: ({ onNext }) => (
            <Intro
              onNext={onNext}
              sections={[
                {
                  title: 'Documents administratifs',
                  text: 'Nous avons besoin de quelques documents pour valider votre situation.',
                },
                {
                  title: 'Documents médicaux',
                  text: "Dans cette section, vous pourrez ajouter des documents qui permettront aux établissements d'évaluer leur capacité de soins au regard des besoins de l'usager.",
                },
                {
                  title: 'Documents socio-éducatifs',
                  text: 'Un rapport de comportement récent vous sera demandé.',
                },
              ]}
              buttonLabel="Joindre mes documents"
            />
          ),
        },
        {
          title: 'Liste des documents',
          Component: ({ resource }) => (
            <FormDoc
              resource={resource['owner']}
              formStatus={resource.ownerStatus}
              formPath="owner"
              title="Documents relatifs aux données administratives"
              form={OWNER_FORM}
            />
          ),
          save: (data: any) => {
            return submit<TDocument['owner'], { owner: TDocument['owner'] }>(
              get(auth, 'selectedId', '0'),
              'document',
              'owner',
              data,
            )
          },
        },
        {
          title: 'Liste des documents',
          Component: ({ resource }) => (
            <FormDoc
              resource={resource['medical']}
              formStatus={resource.medicalStatus}
              formPath="medical"
              title="Documents relatifs aux données médicales"
              form={MEDICAL_FORM}
            />
          ),
          save: (data: any) => {
            return submit<TDocument['medical'], { medical: TDocument['medical'] }>(
              get(auth, 'selectedId', '0'),
              'document',
              'medical',
              data,
            )
          },
        },
        {
          title: 'Liste des documents',
          Component: ({ resource }) => (
            <FormDoc
              resource={resource['educative']}
              formStatus={resource.educativeStatus}
              formPath="educative"
              title="Documents relatifs aux données socio-éducatives"
              form={EDUCATIVE_FORM}
            />
          ),
          save: (data: any) => {
            return submit<TDocument['educative'], { educative: TDocument['educative'] }>(
              get(auth, 'selectedId', '0'),
              'document',
              'educative',
              data,
            )
          },
        },
        {
          title: 'Documents libres',
          Component: ({ resource, refreshResource }) => (
            <FreeDocumentForm
              resource={resource}
              refreshResource={refreshResource}
              admissionRecordId={get(auth, 'selectedId', '0')}
            />
          ),
          save: (data: any) => {
            return submit<TDocument['free'], { frees: TDocument['free'] }>(
              get(auth, 'selectedId', '0'),
              'document',
              'free',
              data,
            )
          },
        },
        {
          title: 'Complet',
          Component: ({ resource }) => (
            <Conclusion
              title="documents à joindre"
              text="Une fois que le médecin aura complété les renseignements médicaux vous pourrez envoyer
              votre dossier !"
              nextStepCta="Accédez aux renseignements médicaux"
              nextStep="/medical/etape/1"
              firstStep="/document/etape/2"
              isCompleted={['validating', 'completed'].includes(resource.statusProgress)}
            />
          ),
        },
      ]}
    />
  )
}
