import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import Box from 'ui/box'
import Icon from 'ui/icon'
import Group from 'ui/form/group'
import Input from 'ui/form/input'
import Label from 'ui/form/label'
import Title from 'ui/typography/title'
import Text from 'ui/typography/text'
import Link from 'ui/typography/link'
import Button from 'ui/button'
import { signIn } from 'api/user'

import { Auth } from 'types/user'

type SignInSuccessReply = {}
type SignInErrorReply = {}

export default function SignInBlock({ onConnected }: { onConnected: (auth: Auth) => void }) {
  const [{ error }, setState] = useState({ loading: false, error: false })
  const history = useHistory()
  function handleSubmit(e: any) {
    e.preventDefault()

    setState({ loading: true, error: false })

    signIn(new FormData(e.target))
      .then(reply => {
        setState({ loading: false, error: false })

        onConnected(reply as unknown as Auth)

        history.push('/')
      })
      .catch(err => {
        if (err.response.data.error === 'email not confirmed') {
          history.push('/email-confirmation', { email: err.response.data.email })
        } else {
          setState({ loading: false, error: true })
        }
      })

    return false
  }

  return (
    <>
      <Box fd="r" ai="b" style={{ flex: 'initial', paddingBottom: '8%' }}>
        <Title bold fs={28} style={{ paddingRight: '30px' }}>
          Connexion
        </Title>
      </Box>
      <form method="POST" autoComplete="off" onSubmit={handleSubmit}>
        <Input type="hidden" name="grant_type" defaultValue="password" />
        <Group style={{ paddingBottom: '5%' }}>
          <Label htmlFor="login" required style={{ paddingBottom: '16px' }}>
            E-mail
          </Label>
          <Input id="login" name="username" required placeholder="Saisissez votre e-mail" />
        </Group>
        <Group style={{ paddingBottom: '5%' }}>
          <Label htmlFor="password" required style={{ paddingBottom: '16px' }}>
            Mot de passe
          </Label>
          <Input
            id="password"
            name="password"
            type="password"
            placeholder="Saisissez votre mot de passe"
          />
        </Group>
        {error && (
          <Text
            fs={16}
            color="pink"
            style={{ paddingBottom: '16px', visibility: error ? 'visible' : 'hidden' }}
          >
            Votre e-mail ou mot de passe sont invalides.
          </Text>
        )}
        <Link underline style={{ textDecorationColor: '#2F7067' }} href="/mot-de-passe-oublie">
          <Text color="green" bold>
            Mot de passe oublié ?
          </Text>
        </Link>
        <Button type="submit" style={{ width: '100%', margin: '10% 0 0 0' }}>
          Se connecter
        </Button>
        <Box jc="c" ai="c">
          <Link underline style={{ textDecorationColor: '#2F7067' }} href="/inscription">
            <Text color="green" bold>
              Créer un compte usager
            </Text>
          </Link>
          <Text style={{ margin: '0 8px 0 8px' }}>ou</Text>
          <Link
            underline
            style={{ textDecorationColor: '#2F7067' }}
            href="/institution/preinscription"
          >
            <Text color="green" bold>
              établissement
            </Text>
          </Link>
        </Box>
      </form>
      <Box fd="c" jc="c">
        <Text
          fs={16}
          as="div"
          style={{ display: 'flex', alignItems: 'center', padding: '0 0 16px 0' }}
        >
          <Icon
            name="check"
            size={20}
            color="green"
            cp="2px"
            containerStyle={{ marginRight: '16px' }}
          />
          Vision claire sur l’avancement du dossier
        </Text>
        <Text
          fs={16}
          as="div"
          style={{ display: 'flex', alignItems: 'center', padding: '0 0 16px 0' }}
        >
          <Icon
            name="check"
            size={20}
            color="green"
            cp="2px"
            containerStyle={{ marginRight: '16px' }}
          />
          Suivi centralisé, complet et régulier
        </Text>
        <Text
          fs={16}
          as="div"
          style={{ display: 'flex', alignItems: 'center', padding: '0 0 16px 0' }}
        >
          <Icon
            name="check"
            size={20}
            color="green"
            cp="2px"
            containerStyle={{ marginRight: '16px' }}
          />
          Une aide personnalisée par téléphone ou en présentiel
        </Text>
      </Box>
    </>
  )
}
